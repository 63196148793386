/* eslint-disable react/jsx-props-no-spreading */
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import React from "react";
import styled from "@emotion/styled";
import PropTypes from "prop-types";
import classNames from "classnames";
import Card, { cardClasses } from "@mui/material/Card";
import CircularProgress from "./CircularProgress";

import Typography from "./Typography";
import mq from "../Util/mediaQueries";

const classes = {
  loading: "loading",
  progress: "loading-container",
  ...cardClasses,
};

const StyledCard = styled(Card)(({ theme }) =>
  mq({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    borderRadius: 10,
    border: `1px solid ${theme.palette.colors.gray100}`,
    [`&.${classes.loading}`]: {
      minHeight: [310, null, 358],
      justifyContent: "center",
    },
    [`.${classes.progress}`]: {
      textAlign: "center",
      display: "flex",
      flexDirection: "column",
    },
  })
);

const ProgressText = styled(Typography)(({ theme }) =>
  mq({
    color: theme.palette.colors.gray600,
    marginTop: theme.spacing(2),
  })
);

const TSCard = React.forwardRef(
  ({ children, className, loading, loadingTitle, ...props }, ref) => (
    <StyledCard
      ref={ref}
      elevation={0}
      className={classNames(className, { [classes.loading]: loading })}
      {...props}
    >
      {loading && loadingTitle}
      {loading && (
        <div className={classes.progress}>
          <CircularProgress size={78} thickness={2} />
          <ProgressText variant="body">Loading</ProgressText>
        </div>
      )}
      {!loading && children}
    </StyledCard>
  )
);

TSCard.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  loading: PropTypes.bool,
  loadingTitle: PropTypes.node,
};

TSCard.defaultProps = {
  children: null,
  className: null,
  loading: false,
  loadingTitle: null,
};

export default TSCard;
export { classes as cardClasses };

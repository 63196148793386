function getMonthName(monthNumber, short = false) {
  const date = new Date();

  // Set the "day" to 1 so that the month doesn't change when we set the month
  date.setDate(1);
  date.setMonth(monthNumber - 1);

  return date.toLocaleString("en-US", { month: short ? "short" : "long" });
}

export default getMonthName;

// eslint-disable-next-line no-unused-vars
import React, { useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";

import getMonthName from "../Util/dateUtils";

import mq from "../Util/mediaQueries";
import IconButton from "./IconButton";
import TextField from "./TextField";

import clearIcon from "../assets/clear-icon.svg";
import noSearchResultSplash from "../assets/no-search-result-splash.svg";
import exit from "../assets/exit.svg";
import search from "../assets/search.svg";

const AllPlansFlyout = styled("div")(({ theme }) =>
  mq({
    display: {xs: "none", lg: "block"},
    [theme.breakpoints.down("lg")]: {
      marginTop: "-40px",
    },
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
    transition: "right .5s",
    position: "fixed",
    zIndex: "1199", // 1199 is just under the appbar drawer
    background: "white",
    marginTop: `-${theme.spacing(5)}`,
    marginRight: `-${theme.spacing(5)}`,
    boxShadow: "0px 0px 24px #E6ECF1",
    width: "392px",
    flex: "1 0 auto",
    borderLeft: `1px solid ${theme.colors.gray100}`,
    ".all-plans-header": {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      padding: "20px 26px",
      fontSize: "20px",
      img: {
        cursor: "pointer",
      },
      IconButton: {
        padding: 0,
      },
    },
  })
);

const AllPlansSearch = styled(TextField)(({ theme }) =>
  mq({
    width: "100%",
    margin: "0",
    padding: "0 24px",
    ".MuiOutlinedInput-root": {
      borderRadius: "30px",
      height: "40px",
      border: `1px solid ${theme.colors.gray100}`,
      fieldset: {
        border: "none !important",
      },
      "&.Mui-focused": {
        borderColor: theme.colors.tsBlack,
      },
      "&:hover": {
        border: `1px solid ${theme.colors.tsBlack}`,
      },
    },
    ".MuiOutlinedInput-input": {
      padding: "10px 14px",
    },
  })
);

const SearchIcon = styled("img")(() =>
  mq({
    width: "24px",
    height: "24px",
  })
);

const PlansList = styled("div")(({ theme }) =>
  mq({
    width: "100%",
    padding: "0 24px",
    marginTop: "24px",
    height: "calc(100vh - 350px)",
    overflow: "auto",
    ".plan-item": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "12px 16px",
      border: `1px solid ${theme.colors.gray200}`,
      borderRadius: "10px",
      marginBottom: "10px",
      color: theme.colors.gray600,
      "&.active": {
        border: `3px solid ${theme.colors.accessibilityGreen}`,
        color: theme.colors.accessibilityGreenDark,
        backgroundColor: theme.colors.accessibilityGreen50,
        padding: "10px 14px",
      },
      "&:hover": {
        cursor: "pointer",
        backgroundColor: theme.colors.gray50,
        color: theme.colors.accessibilityGreenDark,
      },
      ".plan-item-title": {
        fontSize: "16px",
        fontWeight: 600,
      },
      ".pill": {
        fontSize: "12px",
        fontWeight: "400",
        padding: "1px 8px",
        background: theme.colors.gray100,
        borderRadius: "30px",
        maxWidth: "max-content",
        color: theme.colors.gray800,
      },
      "&.active .pill, &:hover .pill": {
        backgroundColor: theme.colors.accessibilityGreen100,
        color: theme.colors.accessibilityGreenDark,
      },
    },
  })
);

const PlanItem = styled("div")(() => mq({}));

const NoPlansSplash = styled("div")(({ theme }) =>
  mq({
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    fontWeight: 400,
    padding: "24px 44px",
    ".no-plans-title": {
      marginTop: "8px",
      fontSize: "20px",
      color: theme.colors.black100,
    },
    ".no-plans-description": {
      fontSize: "14px",
      color: theme.colors.gray800,
    },
  })
);

const CloseAsideButton = styled(IconButton)(() =>
  mq({
    position: "relative",
    top: "-8px",
    right: "-8px",
  })
);

const ClearSearchButton = styled(IconButton)(() =>
  mq({
    img: {
      filter:
        "invert(50%) sepia(15%) saturate(441%) hue-rotate(191deg) brightness(91%) contrast(84%)",
    },
  })
);

function AllPlansAside({
  activePlan,
  handlePlanSelection,
  showAllPlans,
  setShowAllPlans,
  plans,
}) {
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredPlans, setFilteredPlans] = useState(plans);

  useEffect(() => {
    if (searchTerm) {
      const searchTerms = searchTerm.toLowerCase().split(" ");
      const filtered = plans
        .map((plan) => {
          // Assign relevent score to each plan
          let points = 0;
          searchTerms.forEach((term) => {
            if (!term.length) {
              // Ignore empty search terms
              return;
            }
            const month = getMonthName(plan.month);
            if (month.toLowerCase().includes(term)) {
              points += 1;
            }
            if (plan.year.toString().includes(term)) {
              points += 1;
            }
            if (plan.themeTitle.toLowerCase().includes(term)) {
              points += 1;
            }
            if (plan.themeTitle.toLowerCase().startsWith(term)) {
              points += 1;
            }
            if (month.toLowerCase().startsWith(term)) {
              points += 1;
            }
          });
          return { ...plan, points };
        })
        .filter((plan) => plan.points > 0) // Filter out plans with 0 points
        .sort((a, b) => {
          // Sort by points then by date
          if (a.points === b.points) {
            return (
              new Date(`${getMonthName(b.month)} ${b.year}`) -
              new Date(`${getMonthName(a.month)} ${a.year}`)
            );
          }
          return b.points - a.points;
        })
        .map((plan) => {
          // Remove points from plan object
          const { points, ...rest } = plan;
          return rest;
        });
      setFilteredPlans(filtered);
    } else {
      setFilteredPlans(plans);
    }
  }, [plans, searchTerm]);

  const handleShowAllPlans = useCallback(() => {
    setShowAllPlans(!showAllPlans);
  }, [setShowAllPlans, showAllPlans]);

  const handlePlanClick = (plan) => {
    handlePlanSelection(plan);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleKeyPress = useCallback(
    (e) => {
      if ((e.key === "Esc" || e.key === "Escape") && showAllPlans) {
        handleShowAllPlans();
      }
      if (
        e.key === "Enter" &&
        showAllPlans &&
        e.target.className.includes("plan-item")
      ) {
        e.target.click();
      }
    },
    [handleShowAllPlans, showAllPlans]
  );

  useEffect(() => {
    document.addEventListener("keydown", handleKeyPress);
  }, [handleKeyPress]);

  return (
    <AllPlansFlyout sx={{ right: showAllPlans ? "33px" : "-450px" }}>
      <div>
        <div className="all-plans-header">
          <span>All plans</span>
          <CloseAsideButton onClick={handleShowAllPlans}>
            <img src={exit} alt="Close All Plans" />
          </CloseAsideButton>
        </div>
        <AllPlansSearch
          placeholder="Search a month, year, or topic"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          InputProps={{
            startAdornment: <SearchIcon src={search} alt="Search" />,
            endAdornment: searchTerm ? (
              <ClearSearchButton
                position="end"
                onClick={() => setSearchTerm("")}
              >
                <img src={clearIcon} alt="X" />
              </ClearSearchButton>
            ) : (
              ""
            ),
            tabIndex: "0",
          }}
          variant="outlined"
        />
      </div>
      <PlansList>
        {filteredPlans.length ? (
          filteredPlans.map((plan) => (
            <PlanItem
              tabIndex="0"
              className={
                activePlan.themeTitle === plan.themeTitle
                  ? "active plan-item"
                  : "plan-item"
              }
              key={plan.themeTitle}
              onClick={() => handlePlanClick(plan)}
            >
              <div className="plan-item-title">{`${getMonthName(plan.month)} ${
                plan.year
              }`}</div>
              <div className="pill">{plan.themeTitle}</div>
            </PlanItem>
          ))
        ) : (
          <NoPlansSplash>
            <img src={noSearchResultSplash} alt="No search result" />
            <div className="no-plans-title">
              No results for &quot;{searchTerm}&quot;
            </div>
            <div className="no-plans-description">
              Check for typos or search for a specifc month or year
            </div>
          </NoPlansSplash>
        )}
      </PlansList>
    </AllPlansFlyout>
  );
}

AllPlansAside.propTypes = {
  showAllPlans: PropTypes.bool.isRequired,
  plans: PropTypes.arrayOf(
    PropTypes.shape({
      themeTitle: PropTypes.string.isRequired,
      month: PropTypes.number.isRequired,
      year: PropTypes.number.isRequired,
    })
  ).isRequired,
  setShowAllPlans: PropTypes.func.isRequired,
  activePlan: PropTypes.shape({
    themeTitle: PropTypes.string.isRequired,
    month: PropTypes.number.isRequired,
    year: PropTypes.number.isRequired,
  }).isRequired,
  handlePlanSelection: PropTypes.func.isRequired,
};

export default AllPlansAside;

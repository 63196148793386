/* eslint-disable react/jsx-props-no-spreading */
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import styled from "@emotion/styled";
// eslint-disable-next-line no-unused-vars
import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import LinkOutlinedIcon from "@mui/icons-material/LinkOutlined";

import DecoratedIconButton from "./DecoratedIconButton";
import Snackbar from "./Snackbar";
import { snackbarContentClasses } from "./SnackbarContent";
import mq from "../Util/mediaQueries";
import copyToClipboard from "../Util/copyToClipboard";

const StyledIcon = styled(LinkOutlinedIcon)(() =>
  mq({
    transform: "rotate(-45deg)",
  })
);

const StyledSnackbar = styled(Snackbar)(() =>
  mq({
    [`.${snackbarContentClasses.root}`]: {
      minWidth: 0,
      fontWeight: 700,
    },
  })
);

function CopyLinkButton({
  className,
  component,
  componentProps,
  onClick,
  children,
  link,
}) {
  const [showCopyConfirmation, setShowCopyConfirmation] = useState(false);
  const copyHandler = (text) => (e) => {
    setShowCopyConfirmation(true);
    e.stopPropagation();
    setShowCopyConfirmation(copyToClipboard(text));
    if (onClick) onClick(e);
  };

  const Component = component;
  return (
    <>
      <Component
        className={classNames(className)}
        onClick={copyHandler(link)}
        onMouseDown={(e) => {
          e.stopPropagation();
        }}
        {...componentProps}
      >
        <StyledIcon fontSize="small" />
        {children}
      </Component>
      <StyledSnackbar
        open={showCopyConfirmation}
        message="Link copied to clipboard"
        onClose={() => setShowCopyConfirmation(false)}
      />
    </>
  );
}

CopyLinkButton.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  // eslint-disable-next-line react/forbid-prop-types
  componentProps: PropTypes.object,
  link: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.any,
};

CopyLinkButton.defaultProps = {
  className: null,
  onClick: null,
  component: DecoratedIconButton,
  componentProps: null,
  children: null,
};

export default CopyLinkButton;

/* eslint-disable react/jsx-props-no-spreading */
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import Avatar from "@mui/material/Avatar";

function TSAvatar({ ...props }) {
  return <Avatar {...props} />;
}

export default TSAvatar;

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import styled from "@emotion/styled";
import PropTypes from "prop-types";
// eslint-disable-next-line no-unused-vars
import React from "react";
import LogoutIcon from "@mui/icons-material/Logout";
import Button from "./Button";
import Dialog, {
  dialogClasses,
  dialogActionsClasses,
  dialogContentClasses,
} from "./Dialog";
import mq from "../Util/mediaQueries";

function LogOutDialog({ open, loading, onConfirm, onClose }) {
  const StyledDialog = styled(Dialog)(({ theme }) =>
    mq({
      [`.${dialogClasses.paper}`]: {
        width: 343,
        paddingTop: theme.spacing(4),
      },
      [`.${dialogContentClasses.root}`]: {
        padding: `${theme.spacing(2)} ${theme.spacing(3)} ${theme.spacing(0)}`,
      },
      [`.${dialogActionsClasses.root}`]: {
        padding: `${theme.spacing(3)} ${theme.spacing(3)} ${theme.spacing(
          3
        )} ${theme.spacing(4)}`,
      },
      ".button": {
        width: 134,
        marginTop: 0,
      },
      ".icon": {
        width: 140,
        alignSelf: "center",
      },
    })
  );

  return (
    <StyledDialog
      open={open}
      onClose={onClose}
      icon={<LogoutIcon className="icon" />}
      text="Do you want to log out?"
      actions={
        <>
          <Button
            className="button"
            disabled={loading}
            variant="outlined"
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button className="button" loading={loading} onClick={onConfirm}>
            Log Out
          </Button>
        </>
      }
    />
  );
}

LogOutDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default LogOutDialog;

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import styled from "@emotion/styled";
import dayjs from "dayjs";

import mq from "../Util/mediaQueries";
import LoggedInLayout, { Header, Body } from "../Components/LoggedInLayout";
import Card from "../Components/Card";
import ClassCard, {
  classes as classCardClasses,
} from "../Components/ClassCard";
import Grid from "../Components/Grid";
import PageHeader from "../Components/PageHeader";
import useClasses from "../hooks/useClasses";

const StyledContainer = styled(Grid)(({ theme }) =>
  mq({
    ".card": {
      minHeight: [166, null, 196],
      [`.${classCardClasses.hostImg}`]: {
        width: [102, null, 128],
      },
      [`.${classCardClasses.content}`]: {
        margin: [theme.spacing(2), null, theme.spacing(3)],
      },
    },
    ".no-classes": {
      marginTop: 22,
      height: 100,
      display: "flex",
      justifyContent: "center",
      h4: {
        alignSelf: "center",
      },
    },
  })
);

function ClassesPage() {
  const { data, loading } = useClasses();
  const loadedClasses = (data && data.classes) || [];
  const classes = !loading
    ? loadedClasses.filter((c) => dayjs().isBefore(dayjs(c.startTime)))
    : Array.from(Array(10).keys()).map((index) => ({
        title: `placeholder ${index}`,
      }));

  let content = null;

  if (!loading && (!classes || !classes.length)) {
    content = (
      <Card className="no-classes">
        <h4>No upcoming classes</h4>
      </Card>
    );
  } else {
    content = (
      <Grid container className="grid" spacing={{ xs: 2, lg: 5 }}>
        {classes.map((liveClass) => (
          <Grid item xs={12} key={`${liveClass.title} ${liveClass.startTime}`}>
            <ClassCard
              className="card"
              classInfo={liveClass}
              loading={loading}
              showCategories
              showIcons
              showDescription
            />
          </Grid>
        ))}
      </Grid>
    );
  }

  return (
    <LoggedInLayout>
      <Header>
        <PageHeader
          title="Class Schedule"
          subtitle="Browse our list of therapist-led classes, then send them to your people."
        />
      </Header>
      <Body>
        <StyledContainer container>
          <Grid item xs={12}>
            {content}
          </Grid>
        </StyledContainer>
      </Body>
    </LoggedInLayout>
  );
}

export default ClassesPage;

/* eslint-disable react/jsx-props-no-spreading */
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import Divider, { dividerClasses } from "@mui/material/Divider";

function TSDivider({ ...props }) {
  return <Divider {...props} />;
}

export default TSDivider;
export { dividerClasses };

/* eslint-disable react/jsx-props-no-spreading */
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import styled from "@emotion/styled";
import classNames from "classnames";
// eslint-disable-next-line no-unused-vars
import React from "react";
import PropTypes from "prop-types";
import DownloadIcon from "@mui/icons-material/Download";

import Card, { cardClasses } from "./Card";
import CardMedia from "./CardMedia";
import CardContent, { cardContentClasses } from "./CardContent";
import ResourceCategoryChip from "./ResourceCategoryChip";
import Button from "./Button";
import Typography from "./Typography";
import CopyLinkButton from "./CopyLinkButton";
import Skeleton, { skeletonClasses } from "./Skeleton";
import withAccessibleOnClick from "./withAccessibleOnClick";
import withClickTracking from "./withClickTracking";
import mq from "../Util/mediaQueries";

const AccessiblyClickableCard = withAccessibleOnClick(Card);

const StyledCard = styled(AccessiblyClickableCard)(({ theme }) =>
  mq({
    "&.resource-card": {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      maxWidth: 832,
      border: "none",
      transition: "background-color .3s ease-out",
      "&:not(.loading)": {
        cursor: "pointer",
        "&:hover": {
          backgroundColor: theme.palette.colors.gray50,
        },
      },
      [`&.${cardClasses.loading}`]: {
        minHeight: "auto",
      },
      [`.${skeletonClasses.root}`]: {
        height: 21,
        borderRadius: 8,
        marginTop: 9,
        width: "100%",
        "&:nth-of-type(1)": {
          width: 360,
        },
        "&:nth-of-type(2), &:nth-of-type(3)": {
          height: 12,
        },
        "&:nth-of-type(4), &:nth-of-type(5)": {
          height: 26,
          display: "inline-block",
          width: 128,
          marginRight: theme.spacing(1),
        },
      },
      ".media": {
        position: "relative",
        borderRadius: 10,
        backgroundColor: theme.palette.colors.indigo,
        width: [120, null, null, 160],
        height: [236, null, null, 160],
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        overflow: "hidden",
        flexShrink: 0,
        padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
        ".wrapper": {
          display: "block",
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          width: "100%",
          height: "100%",
          imageRendering: "-moz-crisp-edges",
        },
      },
      [`.${cardContentClasses.root}`]: {
        flex: 1,
        alignSelf: "center",
        padding: [`0 ${theme.spacing(2)} 0 ${theme.spacing(4)}`],
        header: {
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
        },
      },
      ".title": {
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "-webkit-box",
        WebkitLineClamp: "1",
        lineClamp: "1",
        WebkitBoxOrient: "vertical",
        alignSelf: "center",
      },
      ".categories": {
        flexShrink: 0,
        marginLeft: theme.spacing(2),
      },
      ".description": {
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "-webkit-box",
        WebkitLineClamp: "2",
        lineClamp: "2",
        WebkitBoxOrient: "vertical",
        color: theme.palette.colors.gray800,
        lineHeight: "20px",
        marginTop: theme.spacing(1),
      },
      ".link-icon": {
        transform: "rotate(-45deg)",
      },
      ".button": {
        marginTop: theme.spacing(1),
        ".text": {
          paddingLeft: theme.spacing(1),
        },
        "&.download": {
          marginLeft: theme.spacing(1),
        },
      },
    },
  })
);

const TrackedDownloadButton = withClickTracking(Button);
const TrackedCopyLinkButton = withClickTracking(CopyLinkButton);

function ResourceCard({ resource, loading, className, onClick }) {
  const { id, thumbnailImageUrl, title, fileUrl, description } = resource;

  let content = null;

  if (loading) {
    content = (
      <>
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </>
    );
  } else {
    content = (
      <>
        <header>
          <Typography variant="heading" className="title">
            {title}
          </Typography>
          <div className="categories">
            <ResourceCategoryChip name="pdf" />
          </div>
        </header>
        {description && (
          <Typography variant="body" className="description">
            {description}
          </Typography>
        )}
        {fileUrl && (
          <div className="actions">
            <TrackedCopyLinkButton
              className="button"
              component={Button}
              showHoverTooltip={false}
              link={fileUrl}
              componentProps={{ variant: "text", className: "button" }}
              eventName="Resource Text Copied"
              eventProps={{
                Name: title,
                "Resource type": "PDF",
                "Resource id": id,
              }}
            >
              <span className="text">Copy Link</span>
            </TrackedCopyLinkButton>
            <TrackedDownloadButton
              className="button download"
              variant="text"
              component="a"
              download
              target="_blank"
              href={fileUrl}
              startIcon={<DownloadIcon fontSize="small" />}
              onClick={(e) => {
                e.stopPropagation();
              }}
              eventName="Resource Opened"
              eventProps={{
                Name: title,
                "Resource type": "PDF",
                "Resource id": id,
              }}
            >
              Download PDF
            </TrackedDownloadButton>
          </div>
        )}
      </>
    );
  }

  return (
    <StyledCard
      className={classNames("resource-card", className, { loading })}
      disabled={loading}
      onClick={() => {
        if (!loading) onClick(resource);
      }}
      tabIndex={loading ? -1 : 0}
    >
      <CardMedia className="media" component="div">
        {!loading && thumbnailImageUrl && (
          <div
            className="wrapper"
            style={{
              backgroundImage: `url(${thumbnailImageUrl})`,
            }}
          />
        )}
      </CardMedia>
      <CardContent className="content">{content}</CardContent>
    </StyledCard>
  );
}

ResourceCard.propTypes = {
  className: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  resource: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    description: PropTypes.string,
    // type: PropTypes.oneOf(["pdf"]).isRequired,
    thumbnailImageUrl: PropTypes.string,
    fileUrl: PropTypes.string,
  }),
};

ResourceCard.defaultProps = {
  className: null,
  resource: {
    id: null,
    title: null,
    description: null,
    thumbnailImageUrl: null,
    fileUrl: null,
  },
};

export default ResourceCard;

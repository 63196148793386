/* eslint-disable react/jsx-props-no-spreading */
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import Chip, { chipClasses } from "@mui/material/Chip";

function TSChip({ ...props }) {
  return <Chip {...props} />;
}

export default TSChip;
export { chipClasses };

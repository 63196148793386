/* eslint-disable react/jsx-props-no-spreading */
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import { useEffect } from "react";
import tracking from "../services/tracking";

const withTrackedPageView = (name, Component) => {
  function TrackedPageView({ ...props }) {
    useEffect(() => {
      tracking.track("Page Viewed", { page: name });
      tracking.page(name);
    }, []);

    return <Component {...props} />;
  }

  TrackedPageView.displayName = "TrackedPageView";

  return TrackedPageView;
};

export default withTrackedPageView;

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import styled from "@emotion/styled";
import { useContext, useState, useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import PropTypes from "prop-types";
import useMediaQuery from "@mui/material/useMediaQuery";
import LockIcon from "@mui/icons-material/Lock";

import mq from "../Util/mediaQueries";
import Box from "./Box";
import Dialog, { dialogClasses } from "./Dialog";
import FormTextField from "./FormTextField";
import TextField from "./TextField";
import Tooltip from "./ClickableTooltip";
import Typography from "./Typography";
import Button from "./Button";
import InputAdornment from "./InputAdornment";
import Snackbar from "./Snackbar";
import Alert from "./Alert";
import { AuthContext } from "../providers/AuthProvider";

const StyledDialog = styled(Dialog)(() =>
  mq({
    [`.${dialogClasses.paper}`]: {
      maxWidth: ["100%", null, null, 518],
      width: "100%",
      height: ["100%", null, null, "auto"],
      minHeight: ["calc(100% - 64px)", null, 0],
      maxHeight: ["100%", null, null, null, "none"],
    },
  })
);

const StyledContainer = styled(Box)(({ theme }) =>
  mq({
    form: {
      display: "flex",
      flexDirection: "column",
    },
    ".card": {
      maxWidth: 682,
      width: "100%",
      padding: [
        `${theme.spacing(5)} ${theme.spacing(3)}`,
        null,
        `${theme.spacing(8)} ${theme.spacing(3)}`,
        `${theme.spacing(8)} 0`,
      ],
    },
    ".text-field": {
      marginTop: 26,
    },
    ".buttons": {
      display: "flex",
      justifyContent: "center",
      flexGrow: [1, null, null, 0],
      marginTop: 50,
      ".button": {
        flex: 1,
        "&.cancel": {
          marginRight: [theme.spacing(3), null, theme.spacing(4)],
        },
      },
    },
    ".alert": {
      width: "100%",
    },
  })
);

function ProfileDialog({ open, onClose }) {
  const auth = useContext(AuthContext);
  const { user } = auth;

  const { firstName, lastName, email } = user?.profile || {};
  const [updateProfile, { loading, error }] = auth.updateProfile;

  const [complete, setComplete] = useState(false);
  const [submissionError, setSubmissionError] = useState(false);

  const lessThanLg = useMediaQuery((theme) => theme.breakpoints.down("lg"));

  const methods = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: {
      firstName,
      lastName,
    },
  });

  const {
    handleSubmit,
    reset,
    getValues,
    formState: { isDirty, isSubmitSuccessful },
  } = methods;

  const OnCancel = (data) => {
    reset({ firstName: data.firstName, lastName: data.lastName });
  };

  const onSubmit = (data) => {
    setSubmissionError(false);
    return updateProfile({
      variables: { firstName: data.firstName, lastName: data.lastName },
    });
  };

  const onCompleteClear = () => {
    setComplete(false);
  };

  const onErrorClose = () => {
    setSubmissionError(false);
  };

  useEffect(() => {
    const data = getValues();
    if (isSubmitSuccessful) {
      reset({ firstName: data.firstName, lastName: data.lastName });
      setComplete(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitSuccessful]);

  useEffect(() => {
    if (error) {
      setSubmissionError(true);
    }
  }, [error]);

  return (
    <StyledDialog
      open={open}
      showClose
      onClose={onClose}
      fullScreen={lessThanLg}
      content={
        <StyledContainer container spacing={{ xs: 2, lg: 4 }}>
          <FormProvider methods={methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Typography variant="small" align="left">
                Edit Profile
              </Typography>
              <FormTextField
                className="text-field"
                name="firstName"
                label="First Name"
                disabled={complete}
                rules={{ required: true }}
              />
              <FormTextField
                className="text-field"
                name="lastName"
                label="Last Name"
                disabled={complete}
                rules={{ required: true }}
              />
              <TextField
                className="text-field"
                value={email}
                label="Email"
                error={false}
                disabled
                endAdornment={
                  <InputAdornment>
                    <Tooltip
                      body={
                        <span>
                          For security reasons, this email cannot be changed
                          here. If you&apos;d like us to update it, please{" "}
                          <a
                            href="mailto:lmh@getlasting.zendesk.com"
                            target="_blank"
                            rel="noreferrer"
                          >
                            contact us
                          </a>
                          .
                        </span>
                      }
                    >
                      <LockIcon />
                    </Tooltip>
                  </InputAdornment>
                }
              />
              {user && (
                <div className="buttons">
                  <Button
                    className="button cancel"
                    disabled={!isDirty}
                    onClick={OnCancel}
                    variant="outlined"
                  >
                    Cancel
                  </Button>
                  <Button
                    className="button"
                    type="submit"
                    disabled={!isDirty}
                    loading={loading}
                    complete={complete}
                    onCompleteClear={onCompleteClear}
                  >
                    Save
                  </Button>
                </div>
              )}
            </form>
          </FormProvider>
          <Snackbar
            className="snackbar"
            open={submissionError}
            onClose={onErrorClose}
          >
            <Alert className="alert" onClose={onErrorClose} severity="error">
              There was a problem updating your profile. Please try again.
            </Alert>
          </Snackbar>
        </StyledContainer>
      }
    />
  );
}

ProfileDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ProfileDialog;

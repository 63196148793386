/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";

import Footer from "./Footer";
import Box from "./Box";
import ScreenshotProvider from "../providers/ScreenshotProvider";
import mq from "../Util/mediaQueries";
import { DRAWER_WIDTH } from "../constants";
import DrawerNavigation from "./DrawerNavigation";

const StyledContainer = styled("main")(({ theme }) =>
  mq({
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    width: "100%",
    backgroundColor: theme.palette.background.light,
  })
);

const StyledBox = styled(Box)(({ theme }) =>
  mq({
    alignSelf: "center",
    width: ["100%", null, null, null, `calc(100% - ${DRAWER_WIDTH}px)`],
    marginTop: [56, null, null, null, 0], // 56px is the height of the header
    paddingTop: [theme.spacing(2), null, theme.spacing(5)],
    paddingBottom: 0,
    flexGrow: 1,
    minHeight: "77.5vh",
  })
);

const StyledHeader = styled("header")(({ theme }) =>
  mq({
    width: "100%",
    paddingLeft: [theme.spacing(2), null, theme.spacing(5)],
    paddingRight: [theme.spacing(2), null, theme.spacing(5)],
    paddingBottom: [20, null, theme.spacing(5)],
    borderBottom: `1px solid ${theme.palette.colors.gray100}`,
  })
);

const StyledMain = styled(Box)(({ theme }) =>
  mq({
    alignSelf: "center",
    width: "100%",
    padding: [theme.spacing(2), null, theme.spacing(5)],
  })
);

const StyledFooter = styled(Footer)(({ theme }) =>
  mq({
    alignSelf: "center",
    width: ["100%", null, null, null, `calc(100% - ${DRAWER_WIDTH}px)`],
    borderTop: `1px solid ${theme.palette.colors.gray100}`,
    flexGrow: 0,
    flexShrink: 0,
  })
);

export function Header() {
  return null;
}

export function Body() {
  return null;
}

function LoggedInLayout({ children, ...props }) {
  const header =
    children && children.find
      ? children.find((child) => child.type === Header)
      : null;
  const body =
    children && children.find
      ? children.find((child) => child.type === Body)
      : null;

  return (
    <ScreenshotProvider>
      <StyledContainer>
        <DrawerNavigation />
        <StyledBox
          sx={{
            ml: { lg: `${DRAWER_WIDTH}px` },
            ...props.sx,
          }}
        >
          <StyledHeader sx={header.props.sx}>
            {header ? header.props.children : null}
          </StyledHeader>
          <StyledMain sx={body.props.sx}>
            {body ? body.props.children : null}
          </StyledMain>
        </StyledBox>
        <StyledFooter
          sx={{
            ml: { lg: `${DRAWER_WIDTH}px` },
          }}
        />
      </StyledContainer>
    </ScreenshotProvider>
  );
}

LoggedInLayout.propTypes = {
  children: PropTypes.node,
  sx: PropTypes.shape({}), // The sx prop is used to extend the styles applied to the component.
};

LoggedInLayout.defaultProps = {
  children: null,
  sx: {},
};

export default LoggedInLayout;

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import styled from "@emotion/styled";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import TSCard from "../Components/Card";
import useAuth from "../hooks/useAuth";
import mq from "../Util/mediaQueries";
import { PLANS_PAGE_SLUG } from "../constants";
import tracking from "../services/tracking";

// import { AUTH_TOKEN } from './constants';

function SigninWithMagicToken() {
  const navigate = useNavigate();
  const auth = useAuth();

  const { search } = useLocation();
  const token = new URLSearchParams(search).get("token");

  const [signIn, { called }] = auth.signIn;

  const StyledCard = styled(TSCard)(({ theme }) =>
    mq({
      marginTop: [36, null, theme.spacing(8)],
      maxWidth: 565,
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3),
      width: "100%",
      alignSelf: "center",
    })
  );

  useEffect(() => {
    if (!called) {
      signIn({ variables: { token } })
        .then(() => {
          tracking.track("Login");
          navigate(PLANS_PAGE_SLUG);
        })
        .catch(() => navigate("/login"));
    }
  }, [called, navigate, signIn, token]);

  return <StyledCard loading />;
}

export default SigninWithMagicToken;

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import styled from "@emotion/styled";
import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { useSwipeable } from "react-swipeable";
import classNames from "classnames";
import mq from "../Util/mediaQueries";

const PREV = "previous";
const NEXT = "next";
const classes = {
  container: "carousel-container",
  slot: "carousel-slot",
  sliding: "sliding",
  previous: "previous",
  next: "next",
};

const animationDurationMs = 1000;

const itemWidth = 236;
const itemMargin = 32;

const StyledContainer = styled.div(() =>
  mq({
    "&.resource-carousel": {
      ".wrapper": {
        width: "100%",
        overflow: "hidden",
      },
      [`.${classes.container}`]: {
        display: "flex",
        "&:not(.fixed)": {
          transition: `transform ${animationDurationMs}ms ease`,
          [`&.${classes.sliding}`]: {
            transition: "none",
          },
          [`&:not(.${classes.sliding})`]: {
            transform: `translateX(calc(-${itemWidth + itemMargin}px))`,
          },
          [`&.${classes.sliding}.${classes.previous}`]: {
            transform: `translateX(calc(2 * (-${itemWidth + itemMargin}px)))`,
          },
          [`&.${classes.sliding}.${classes.next}`]: {
            transform: "translateX(0%)",
          },
        },
      },
      [`.${classes.slot}`]: {
        "&.hidden": {
          visibility: "hidden",
        },
      },
    },
  })
);
function ResourceCarousel({ children, disabled }) {
  const numItems = React.Children.count(children);
  const [firstVisibleItem, setFirstVisibleItem] = useState(0);
  const [sliding, setSliding] = useState(false);
  const [slidingTimeout, setSlidingTimeout] = useState(null);
  const [direction, setDirection] = useState(NEXT);
  const [hasOverflow, setHasOverflow] = useState(false);

  const clearSliding = () => {
    if (slidingTimeout) {
      clearTimeout(slidingTimeout);
    }
    setSlidingTimeout(
      setTimeout(() => {
        setSliding(false);
      }, 50)
    );
  };

  const slideNext = () => {
    if (!hasOverflow || firstVisibleItem === numItems - 1) {
      return;
    }
    setDirection(NEXT);
    setSliding(true);
    setFirstVisibleItem(Math.abs((firstVisibleItem + 1) % numItems));
    clearSliding();
  };

  const slidePrevious = () => {
    if (!hasOverflow || firstVisibleItem === 0) {
      return;
    }
    setDirection(PREV);
    setSliding(true);
    let nextIndex = firstVisibleItem - 1;
    if (nextIndex === -1) {
      nextIndex = numItems - 1;
    }
    setFirstVisibleItem(nextIndex);
    clearSliding();
  };

  const handlers = useSwipeable({
    onSwipedLeft: slideNext,
    onSwipedRight: slidePrevious,
    swipeDuration: 500,
    preventScrollOnSwipe: true,
    trackMouse: true,
  });

  const containerRef = useRef(null);
  const wrapperRef = useRef(null);

  const handleWindowSizeChange = () => {
    setHasOverflow(
      (itemWidth + itemMargin) * numItems - itemMargin >
        wrapperRef.current.offsetWidth
    );
  };

  useEffect(() => {
    handleWindowSizeChange();
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
      if (slidingTimeout) clearTimeout(slidingTimeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getOrder = (index) => {
    if (index >= firstVisibleItem) return index - firstVisibleItem + 1;
    return (Math.abs(numItems - firstVisibleItem + index) % numItems) + 1;
  };

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <StyledContainer className="resource-carousel" {...handlers}>
      <div className="wrapper" ref={wrapperRef}>
        <div
          ref={containerRef}
          className={classNames(classes.container, {
            fixed: !hasOverflow,
            [classes.sliding]: sliding,
            [classes.previous]: direction !== NEXT,
            [classes.next]: direction === NEXT,
          })}
        >
          {React.Children.map(children, (child, index) => {
            const order = hasOverflow ? getOrder(index) : index + 1;

            const hidden = order > index + 1;

            if (!disabled && hasOverflow && order === numItems) {
              return (
                <>
                  <div
                    className={classNames(classes.slot, { hidden })}
                    style={{ order }}
                    data-index={index}
                  >
                    {child}
                  </div>
                  <div
                    className={classNames(classes.slot)}
                    style={{ order: 0 }}
                    data-index={index}
                  >
                    {child}
                  </div>
                </>
              );
            }
            if (!disabled && hasOverflow && order === 1) {
              return (
                <>
                  <div
                    className={classNames(classes.slot)}
                    style={{ order }}
                    data-index={index}
                  >
                    {child}
                  </div>

                  <div
                    className={classNames(classes.slot, "hidden")}
                    style={{ order: numItems + 1 }}
                    data-index={index}
                  >
                    {child}
                  </div>
                </>
              );
            }

            return (
              <div
                className={classNames(classes.slot, { hidden })}
                style={{ order }}
              >
                {child}
              </div>
            );
          })}
        </div>
      </div>
    </StyledContainer>
  );
}

ResourceCarousel.propTypes = {
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool.isRequired,
};

ResourceCarousel.defaultProps = {};

export default ResourceCarousel;
export { classes as resourceCarouselClasses };

/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-props-no-spreading */
// eslint-disable-next-line no-unused-vars
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { styled, useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import Skeleton from "@mui/material/Skeleton";

import { variables } from "../themes";
import mq from "../Util/mediaQueries";
import Avatar from "./Avatar";
import RecordDetails from "./PlanAccordionItem";
import TSTypography from "./Typography";

import calendar from "../assets/calendar.svg";
import checkCircle from "../assets/check-circle.svg";
import user from "../assets/user-60.svg";
import pdfAvatar from "../assets/pdf-avatar.svg";
import forward from "../assets/forward.svg";
import smallForward from "../assets/small-forward.svg";
import liveClass from "../assets/live-class.svg";
import onDemandClass from "../assets/on-demand-class.svg";
import quickTips from "../assets/quick-tips.svg";
import worksheet from "../assets/worksheet.svg";

const AccordionContainer = styled("div")(() =>
  mq({
    marginTop: "32px",
  })
);

const Accordion = styled(MuiAccordion)(({ theme }) =>
  mq({
    transition: "all 1s ease",
    borderTop: `1px solid ${theme.colors.gray100}`,
    borderBottom: `1px solid ${theme.colors.gray100}`,
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:not(:first-of-type)": {
      borderTop: 0,
    },
    "&.Mui-expanded:not(:first-of-type)": {
      borderTop: `1px solid ${theme.colors.gray100}`,
    },
    "&.Mui-disabled": {
      backgroundColor: theme.colors.white,
    },
  })
);

const AccordionSummary = styled(MuiAccordionSummary)(({ theme }) =>
  mq({
    alignItems: "flex-start",
    transition: "all 1s ease",
    color: theme.colors.black100,
    backgroundColor: `${theme.colors.white} !important`,
    minHeight: "115px",
    flexDirection: "row-reverse",
    [theme.breakpoints.down("sm")]: {
      // display: "flex",
      flexDirection: "column",
    },
    "& .MuiAccordionSummary-expandIconWrapper": {
      marginTop: "30px",
      [theme.breakpoints.down("sm")]: {
        marginTop: "0px",
      },
    },
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
      transform: "rotate(0deg)", // prevent icon wrapper from rotating
    },
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded .open-icon": {
      transform: "rotate(90deg)", // rotate image only when expanded
    },
    "&.Mui-disabled": {
      opacity: "inherit",
    },
    "&:hover": {
      backgroundColor: `${theme.colors.gray50} !important`,
    },
    "& .MuiAccordionSummary-content": {
      justifyContent: "space-between",
    },
    ".open-icon": {
      transition: "all .25s ease",
      minWidth: "12px",
      minHeight: "12px",
      filter:
        "invert(24%) sepia(98%) saturate(686%) hue-rotate(145deg) brightness(94%) contrast(103%)",
      [theme.breakpoints.down("sm")]: {
        minWidth: "9px",
        minHeight: "9px",
      },
    },
    ".past": {
      filter:
        "invert(94%) sepia(4%) saturate(644%) hue-rotate(186deg) brightness(95%) contrast(85%)",
    },
    ".offering-overview": {
      display: "flex",
      flex: "1 1 auto",
    },
    ".offering-profile": {
      margin: "0 16px",
      width: "60px",
      height: "60px",
      borderRadius: "50%",
    },
    ".offering-title": {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      fontWeight: "600",
      fontSize: "16px",
      gap: ".25vw",
      "span:first-of-type": {
        marginTop: "12px",
      },
      [theme.breakpoints.down("sm")]: {
        display: "flex",
        flexDirection: "column-reverse",
        "span:first-of-type": {
          marginTop: "8px",
        },
      },
    },
    ".offering-subtitle": {
      fontWeight: "400",
      fontSize: "14px",
      color: theme.colors.gray800,
    },
    ".offering-availability": {
      display: "flex",
      fontWeight: "400",
      fontSize: "16px",
      margin: ".5vw 0",
      img: {
        marginRight: ".5vw",
      },
    },
    ".offering-tag": {
      display: "flex",
      fontWeight: "400",
      fontSize: "12px",
      minWidth: "fit-content",
      alignItems: "flex-start",
      img: {
        width: "20px",
        height: "20px",
        marginRight: ".5vw",
      },
    },
  })
);

const AccordionIconWrapper = styled("div")(({theme}) =>
  mq({
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      gap: "8px",
      marginBottom: "15px",
    },
  })
);

const dateInPast = (firstDate, secondDate) =>
  secondDate.setHours(0, 0, 0, 0) <= firstDate.setHours(0, 0, 0, 0);

const getOfferingSubTitle = (record) => {
  switch (record.__typename) {
    case "LiveClass":
    case "OnDemandClass":
      return `with ${record.hostName}`;
    // Would also like to add the author's title here, but it's not available in the API
    case "QuickTipResource":
    case "WorksheetResource":
      return `created by ${record.authorName}`;
    // Would also like to add the author's title here, but it's not available in the API
    default:
      return "";
  }
};

const getOfferingTag = (record) => {
  switch (record.__typename) {
    case "LiveClass":
      return (
        <span
          className="offering-tag"
          style={{ color: variables.colors.cobaltDark }}
        >
          <img src={liveClass} alt="" />
          Live Class
        </span>
      );
    case "OnDemandClass":
      return (
        <span
          className="offering-tag"
          style={{ color: variables.colors.limeDark }}
        >
          <img src={onDemandClass} alt="" />
          On-Demand Class
        </span>
      );
    case "QuickTipResource":
      return (
        <span
          className="offering-tag"
          style={{ color: variables.colors.fuchsiaDark }}
        >
          <img src={quickTips} alt="" />
          Quick Tips
        </span>
      );
    case "WorksheetResource":
      return (
        <span
          className="offering-tag"
          style={{ color: variables.colors.purpleDark }}
        >
          <img src={worksheet} alt="" />
          Therapy Worksheet
        </span>
      );
    default:
      return "";
  }
};

const getOfferingImg = (record) => {
  if (record.authorImageUrl) {
    return record.authorImageUrl;
  }
  if (record.thumbnailUrl) {
    return record.thumbnailUrl;
  }
  if (record.assetPreviewImageUrl) {
    return record.assetPreviewImageUrl;
  }
  if (
    record.__typename === "QuickTipResource" ||
    record.__typename === "WorksheetResource"
  ) {
    return pdfAvatar;
  }
  return user;
};

export default function PlanAccordion({ handlePDFPreview, plan }) {
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <AccordionContainer>
      {plan?.records?.length
        ? plan.records.map((record) => {
            const past =
              record.__typename === "LiveClass"
                ? dateInPast(new Date(), new Date(record.startTime))
                : false;
            return (
              <Accordion
                key={`${record.title}-${Math.random()}`}
                disabled={past}
                disableGutters
              >
                <AccordionSummary
                  expandIcon={
                    <AccordionIconWrapper>
                    <img
                      src={sm ? smallForward : forward}
                      alt="Open"
                      className={`open-icon ${past ? "past" : ""}`}
                    />
                    <TSTypography sx={{color: "#007E73"}} variant='body'>{sm ? 'View Details' : ''}</TSTypography>
                    </AccordionIconWrapper>
                  }
                >
                  <div className="offering-overview">
                    {!sm && (
                      <Avatar
                        src={getOfferingImg(record)}
                        className="offering-profile"
                      />
                    )}
                    <div style={{ flex: 1 }}>
                      <div className="offering-title">
                        <span>{record.title}</span>
                        {getOfferingTag(record)}
                      </div>
                      <div className="offering-subtitle">
                        {getOfferingSubTitle(record)}
                      </div>
                      {record.__typename === "LiveClass" ? (
                        <div
                          className="offering-availability"
                          style={{
                            color: past ? variables.colors.criticalText : "",
                          }}
                        >
                          <img
                            src={calendar}
                            alt="Calendar"
                            style={{
                              filter: past
                                ? "invert(12%) sepia(93%) saturate(4707%) hue-rotate(335deg) brightness(81%) contrast(106%)"
                                : "invert(52%) sepia(12%) saturate(510%) hue-rotate(190deg) brightness(88%) contrast(89%)",
                            }}
                          />
                          {past
                            ? "This event happened in the past"
                            : new Date(record.startTime).toLocaleDateString(
                                "en-us",
                                {
                                  weekday: "short",
                                  month: "short",
                                  day: "numeric",
                                  hour: "numeric",
                                  minute: "numeric",
                                  hour12: true,
                                  timeZoneName: "short",
                                }
                              )}
                        </div>
                      ) : (
                        <div className="offering-availability">
                          <img src={checkCircle} alt="Check Circle" />
                          Available now
                        </div>
                      )}
                    </div>
                  </div>
                </AccordionSummary>
                <RecordDetails
                  record={record}
                  handlePDFPreview={handlePDFPreview}
                />
              </Accordion>
            );
          })
        : Array.from(Array(3)).map(() => (
            <Accordion key={Math.random()}>
              <AccordionSummary>
                <Skeleton
                  sx={{
                    bgcolor: theme.palette.colors.gray200,
                  }}
                  className="offering-profile"
                  variant="circular"
                  height={60}
                  width={60}
                />
                <div className="offering-overview">
                  <span>
                    <Skeleton
                      sx={{
                        bgcolor: theme.palette.colors.gray200,
                      }}
                      className="offering-title"
                      height={20}
                      width={250}
                    />
                    <Skeleton
                      sx={{
                        bgcolor: theme.palette.colors.gray50,
                      }}
                      className="offering-subtitle"
                      height={12}
                      width={225}
                    />
                    <Skeleton
                      sx={{
                        bgcolor: theme.palette.colors.gray50,
                      }}
                      className="offering-availability"
                      height={12}
                      width={225}
                    />
                  </span>
                </div>
                <Skeleton
                  sx={{
                    bgcolor: theme.palette.colors.gray200,
                  }}
                  className="offering-tag"
                  height={20}
                  width={70}
                />
              </AccordionSummary>
            </Accordion>
          ))}
    </AccordionContainer>
  );
}

PlanAccordion.propTypes = {
  handlePDFPreview: PropTypes.func.isRequired,
  plan: PropTypes.shape({
    month: PropTypes.number.isRequired,
    year: PropTypes.number.isRequired,
    themeTitle: PropTypes.string.isRequired,
    themeDescription: PropTypes.string.isRequired,
    records: PropTypes.arrayOf(PropTypes.shape({})),
  }),
};

PlanAccordion.defaultProps = {
  plan: {},
};

export { PlanAccordion };

import { createTheme } from "@mui/material/styles";

export const variables = {
  colors: {
    // Primary Palette
    indigo: "#263159",
    indigo900: "#37446b",
    indigo800: "#49577a",
    accessibilityGreen: "#007E73",
    accessibilityGreen900: "#173F4B",
    accessibilityGreenDark: "#005C65",
    accessibilityGreen600: "#058379",
    accessibilityGreen100: "#E1F0EE",
    accessibilityGreen50: "#F2FAF9",
    // Neutral Palette
    tsBlack: "#22292F",
    black100: "#22292F", // Sames as tsBlack, but 2 different name are used for this color
    gray800: "#4D546B",
    gray600: "#73788C",
    gray400: "#8A98AF",
    gray200: "#D1D6E1",
    gray100: "#E2E5EC",
    gray50: "#F4F6FA",
    white: "#fff",
    // Functional Palette
    criticalBase: "#CD013C",
    criticalText: "#B70134",
    criticalIcon: "#CD013C",
    criticalSurface: "#FFD2DF",
    warningBase: "#FFC453",
    warningText: "#8F5D00",
    warningIcon: "#B87700",
    warningSurface: "#FFEECC",
    successBase: "#008060",
    successText: "#006B50",
    successIcon: "#008060",
    successSurface: "#CCF1E8",
    infoBase: "#2C6ECB",
    infoText: "#2762B4",
    infoIcon: "#2C6ECB",
    infoSurface: "#DCEAFF",
    // Secondary Palette
    purpleBase: "#A140D4",
    purpleDark: "#712D95",
    purpleLight: "#ECD9F6",
    violetBase: "#6159D1",
    violetDark: "#443E93",
    violetLight: "#DFDEF6",
    cobaltBase: "#567FFB",
    cobaltDark: "#3C59B0",
    cobaltLight: "#E7EDFF",
    tsGreen: "#007E73",
    tsGreen700: "#005C65",
    tsGreen100: "#E1F0EE",
    limeBase: "#459B03",
    limeDark: "#306D02",
    limeLight: "#E4F1D9",
    yellowBase: "#C48931",
    yellowDark: "#875B13",
    yellowLight: "#FEF2DE",
    fuchsiaBase: "#FF0075",
    fuchsiaDark: "#8C0040",
    fuchsiaLight: "#FFE6F2",
  },
  fonts: {
    roboto: "'Roboto', sans-serif",
  },
  breakpoints: {
    xs: 0,
    sm: 480,
    md: 744,
    lg: 1024,
    xl: 1440,
  },
};

const { colors, fonts, breakpoints } = variables;

export const emotionTheme = {
  colors: {
    ...colors,
    primary: colors.indigo,
    text: colors.tsBlack,
    error: colors.criticalText,
  },
  fonts: {
    ...fonts,
    primary: fonts.roboto,
  },
};

// Theming specifically for MUI components
const muiVariables = {
  palette: {
    primary: {
      main: colors.accessibilityGreen,
      dark: colors.indigo,
      light: colors.accessibilityGreen50,
    },
    secondary: {
      main: colors.tsBlack,
      light: colors.gray50,
    },
    error: {
      main: colors.criticalText,
    },
    text: {
      primary: colors.tsBlack,
      secondary: colors.tsBlack,
      dark: colors.white,
      light: colors.tsBlack,
    },
    background: {
      primary: colors.white,
      dark: colors.indigo,
      light: colors.white,
    },
    input: {
      disabled: colors.gray200,
      icon: colors.gray800,
    },
    colors: {
      ...colors,
    },
  },
  typography: {
    fontFamily: fonts.roboto,
  },
  breakpoints: {
    values: {
      xs: breakpoints.xs,
      sm: breakpoints.sm,
      md: breakpoints.md,
      lg: breakpoints.lg,
      xl: breakpoints.xl,
    },
  },
};

export const muiTheme = createTheme(muiVariables);

export default colors;

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import styled from "@emotion/styled";
// eslint-disable-next-line no-unused-vars
import React, { useContext, useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import PropTypes from "prop-types";
import classNames from "classnames";
import download from "downloadjs";
import { toPng } from "html-to-image";
import dayjs from "dayjs";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";

import Button from "./Button";
import mq from "../Util/mediaQueries";
import { ScreenshotContext } from "../providers/ScreenshotProvider";
import Dialog from "./Dialog";
import CircularProgress from "./CircularProgress";

const StyledButton = styled(Button)(() =>
  mq({
    "&.taking-screenshot": {
      visibility: "hidden",
    },
    ".download-text": {
      display: ["none", null, null, "block"],
    },
  })
);

const StyledDialog = styled(Dialog)(({ theme }) =>
  mq({
    ".text": {
      color: theme.palette.colors.gray800,
      paddingTop: "15px",
      fontSize: "14px",
    },
  })
);

const takeScreenshot = (elm, onComplete) => {
  const fileName = `tsg_screenshot_${dayjs().format("MMDDYYYY")}`;
  let img;

  if (!elm) {
    onComplete(false);
    return;
  }
  toPng(elm)
    .then((dataUrl) => {
      img = new Image();
      img.src = dataUrl;
      img.id = fileName;
      document.body.appendChild(img);
      return dataUrl;
    })
    .then((dataUrl) => {
      download(dataUrl, fileName);
      document.body.removeChild(img);
      onComplete(true);
    })
    .catch(() => {
      document.body.removeChild(img);
      onComplete(false);
    });
};

function DownloadScreenshotButton({ className, targetElementRef, disabled }) {
  const theme = useTheme();
  const { takingScreenshot, startScreenshot, finishScreenshot } =
    useContext(ScreenshotContext);

  useEffect(() => {
    if (takingScreenshot) {
      takeScreenshot(targetElementRef?.current, () => finishScreenshot());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [takingScreenshot, targetElementRef]);

  return (
    <StyledButton
      className={classNames(className, {
        "taking-screenshot": takingScreenshot,
      })}
      variant="outlined"
      onClick={startScreenshot}
      disabled={disabled}
      slim
      startIcon={<FileDownloadOutlinedIcon />}
    >
      <span className="download-text">Download&nbsp;</span>PNG
      <StyledDialog
        open={takingScreenshot}
        showClose={false}
        BackdropProps={{
          sx: { backgroundColor: theme.palette.colors.gray200 },
        }}
        content={
          <>
            <CircularProgress thickness={2} />
            <div className="text">Generating PNG...</div>
          </>
        }
      />
    </StyledButton>
  );
}

DownloadScreenshotButton.propTypes = {
  className: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  targetElementRef: PropTypes.object,
  disabled: PropTypes.bool,
};

DownloadScreenshotButton.defaultProps = {
  className: null,
  targetElementRef: null,
  disabled: true,
};

export default DownloadScreenshotButton;

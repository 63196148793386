/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import styled from "@emotion/styled";
// eslint-disable-next-line no-unused-vars
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import dayjs from "dayjs";
import download from "downloadjs";
import useMediaQuery from "@mui/material/useMediaQuery";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import SensorsIcon from "@mui/icons-material/Sensors";

import Dialog, { dialogClasses, dialogContentClasses } from "./Dialog";
import Button from "./Button";
import Link from "./Link";
import Typography from "./Typography";
import ClassCategoryChip from "./ClassCategoryChip";
import ConditionalWrapper from "./ConditionalWrapper";
import withClickTracking from "./withClickTracking";
import mq from "../Util/mediaQueries";
import generateIcsBlob from "../Util/generateIcsBlob";
import { getClassTime, generateClassShareLink } from "../Util/liveClasses";
import tracking from "../services/tracking";

const StyledDialog = styled(Dialog)(({ theme }) =>
  mq({
    [`.${dialogClasses.paper}`]: {
      maxWidth: 972,
      width: "100%",
      overflow: "hidden",
      padding: [
        `${theme.spacing(5)} ${theme.spacing(0)} ${theme.spacing(
          5
        )} ${theme.spacing(2)}`,
        null,
        null,
        null,
        `${theme.spacing(5)} ${theme.spacing(0)} ${theme.spacing(
          4
        )} ${theme.spacing(4)}`,
      ],
      height: ["100%", null, null, null, "auto"],
      minHeight: ["calc(100% - 64px)", null, null, 0],
      maxHeight: ["100%", null, null, null, "none"],
    },

    [`.${dialogContentClasses.root}`]: {
      padding: 0,
    },
    ".class-content": {
      display: "flex",
      flexDirection: ["column", null, null, null, "row"],
      width: "100%",
      textAlign: "left",
      alignItems: "flex-start",
      justifyContent: "space-between",
      height: "100%",
      lineHeight: "24px",
      paddingTop: theme.spacing(1),
      paddingRight: [theme.spacing(2), null, null, null, theme.spacing(4)],
    },
    ".left-col": {
      display: ["block", null, null, null, "flex"],
      flexDirection: "column",
      height: "100%",
      overflow: "hidden",
      width: ["100%", null, null, null, "52%"],
    },
    ".overview": {
      height: "auto",
    },
    ".category-chip": {
      marginRight: theme.spacing(1),
      marginTop: theme.spacing(1),
    },
    ".details": {
      color: theme.palette.colors.gray800,
      marginTop: theme.spacing(1),
    },
    ".host-name": {
      fontWeight: 700,
    },
    ".description": {
      flex: "1 1 auto",
      overflowY: "auto",
      minHeight: [100, null, null, null, 0],
      marginTop: [theme.spacing(1), null, null, null, theme.spacing(2)],
      color: theme.palette.colors.gray800,
    },
    ".join-details": {
      marginTop: [
        theme.spacing(2),
        null,
        null,
        theme.spacing(2),
        theme.spacing(0),
      ],
      width: ["100%", null, null, null, "35%"],
      borderTop: ["1px solid #e4e4e4", null, null, null, "none"],
      paddingTop: [theme.spacing(2), null, null, null, theme.spacing(0)],
      alignSelf: "stretch",
      display: "flex",
      flexDirection: ["column", null, null, "row", "column"],
      section: {
        display: "flex",
        width: ["100%", null, null, "50%", "100%"],
      },
      ".button": {
        width: "100%",
        margin: `${theme.spacing(2)} ${theme.spacing(0)} ${theme.spacing(0)}`,
      },
      ".icon": {
        marginRight: 12,
      },
      ".content": {
        flex: 1,
        overflow: "hidden",
      },
    },
    ".start-date": {
      ".time": {
        color: theme.palette.colors.gray800,
      },
    },
    ".live-link": {
      marginTop: [
        theme.spacing(2),
        null,
        null,
        theme.spacing(0),
        theme.spacing(2),
      ],
      borderTop: [
        `1px solid ${theme.palette.colors.gray100}`,
        null,
        null,
        "none",
        `1px solid ${theme.palette.colors.gray100}`,
      ],
      paddingTop: [
        theme.spacing(2),
        null,
        null,
        theme.spacing(0),
        theme.spacing(2),
      ],
      marginLeft: [
        theme.spacing(0),
        null,
        null,
        theme.spacing(2),
        theme.spacing(0),
      ],
      ".link": {
        overflowWrap: "break-word",
        wordWrap: "break-word",
      },
    },
  })
);

const TrackedLink = withClickTracking(Link);
const TrackedButton = withClickTracking(Button);

const downloadIcsFile = ({
  startTime,
  endTime,
  liveLink,
  title,
  description,
}) => {
  const blob = generateIcsBlob({
    uid: "fixtis",
    startTime,
    endTime,
    location: liveLink,
    summary: `Talkspace: Self-guided class: ${title}`,
    description,
  });
  download(blob, `workshop_${startTime}.ics`, "text/calendar");
};

const addLeftColumnWrapper = (children) => (
  <div className="left-col">{children}</div>
);

function ClassDetailDialog({ open, onClose, className, classInfo }) {
  const {
    title,
    description,
    hostName,
    startTime,
    endTime,
    categories,
    liveLink,
    recordingLink,
    workshopId,
  } = classInfo;
  const lessThanLg = useMediaQuery((theme) => theme.breakpoints.down("lg"));
  const startDay = dayjs(startTime);
  const formattedStartDay = startDay.format("dddd, MMMM D YYYY");
  const endDay = dayjs(endTime);
  const duration = endDay.diff(startDay, "minute");
  const timezoneAbbrevation = new Date()
    .toLocaleString("en", { timeZoneName: "short" })
    .split(" ")
    .pop();
  const formattedClassTime = `${getClassTime(
    startDay,
    endDay
  )} ${timezoneAbbrevation}`;

  const shareLink = generateClassShareLink({
    title,
    description,
    startTime,
    endTime,
    liveLink,
    recordingLink,
  });

  const addToCalendar = (e) => {
    e.preventDefault();
    e.stopPropagation();
    downloadIcsFile({
      startTime,
      endTime,
      description,
      title,
      liveLink,
      product: "Talkspace: Self-guided class",
    });
  };

  useEffect(() => {
    if (open)
      tracking.track("Resource Details Viewed", {
        Name: title,
        "Resource type": "workshop",
        "Resource id": workshopId,
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <StyledDialog
      className={classNames("class-detail-dialog", className)}
      open={open}
      onClose={onClose}
      scroll={lessThanLg ? "paper" : "body"}
      fullScreen={lessThanLg}
      content={
        <section className="class-content">
          <ConditionalWrapper
            condition={!lessThanLg}
            wrapper={addLeftColumnWrapper}
          >
            <section className="overview">
              <Typography variant="small">{title}</Typography>
              <Typography variant="caption" component="div" className="details">
                <span className="duration">{duration} minutes • </span>
                <span>
                  Hosted by <span className="host-name">{hostName}</span>
                </span>
              </Typography>
            </section>
            {categories && categories.length && (
              <div className="categories">
                {categories.map((category) => (
                  <ClassCategoryChip
                    key={category.title}
                    className="category-chip"
                    name={category.title}
                  />
                ))}
              </div>
            )}
            <Typography variant="body" className="description">
              {description}
            </Typography>
          </ConditionalWrapper>
          <section className="join-details">
            <section className="start-date">
              <AccessTimeIcon className="icon" />
              <div className="content">
                <Typography variant="heading">{formattedStartDay}</Typography>
                <Typography variant="body" className="time">
                  {formattedClassTime}
                </Typography>
                <Button
                  className="button"
                  variant="outlined"
                  onClick={addToCalendar}
                >
                  Add to calendar
                </Button>
              </div>
            </section>
            <section className="live-link">
              <SensorsIcon className="icon" />
              <div className="content">
                <Typography variant="heading">Live online event</Typography>
                <Typography variant="heading">
                  <TrackedLink
                    className="link"
                    href={liveLink}
                    target="_blank"
                    rel="noreferrer"
                    eventName="Resource Opened"
                    eventProps={{
                      Name: title,
                      "Resource type": "workshop",
                      "Resource id": workshopId,
                    }}
                  >
                    {liveLink}
                  </TrackedLink>
                </Typography>
                <TrackedButton
                  className="button"
                  href={shareLink}
                  target="_blank"
                  rel="noreferrer"
                  eventName="Resource Text Copied"
                  eventProps={{
                    Name: title,
                    "Resource type": "workshop",
                    "Resource id": workshopId,
                  }}
                >
                  Share with employees
                </TrackedButton>
              </div>
            </section>
          </section>
        </section>
      }
    />
  );
}

ClassDetailDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  className: PropTypes.string,
  classInfo: PropTypes.shape({
    title: PropTypes.string,
    startTime: PropTypes.string,
    endTime: PropTypes.string,
    hostName: PropTypes.string,
    categories: PropTypes.arrayOf(PropTypes.shape({ title: PropTypes.string })),
    description: PropTypes.string,
    recordingLink: PropTypes.string,
    liveLink: PropTypes.string,
    workshopId: PropTypes.number,
  }),
};

ClassDetailDialog.defaultProps = {
  className: null,
  classInfo: PropTypes.shape({
    title: null,
    startTime: null,
    endTime: null,
    hostName: null,
    categories: [],
    description: null,
    recordingLink: null,
    liveLink: null,
    workshopId: null,
  }),
};

export default ClassDetailDialog;

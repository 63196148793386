const segment = () =>
  window.analytics || {
    identify: () => {},
    track: () => {},
    group: () => {},
    page: () => {},
  };

const identify = (id, { firstName, lastName, email }) => {
  const traits = {};
  if (firstName) {
    traits.name = `${firstName} ${lastName || ""}`;
  }
  if (email) {
    traits.email = email;
  }
  segment().identify(id, traits);
};

const track = (eventName, props = {}) => {
  segment().track(eventName, props);
};

const group = (groupId, groupName) => {
  const traits = {};
  if (groupName) {
    traits.name = groupName;
  }
  segment().group(groupId, traits);
};

const page = (pageName) => {
  segment().page(pageName);
};

export default { identify, track, group, page };

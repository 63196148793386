/* eslint-disable react/jsx-props-no-spreading */
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import ClickAwayListener from "@mui/base/ClickAwayListener";

function TSClickAwayListener({ ...props }) {
  return <ClickAwayListener {...props} />;
}

export default TSClickAwayListener;

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import styled from "@emotion/styled";
// eslint-disable-next-line no-unused-vars
import React, { useEffect, useState, useContext } from "react";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import dayjs from "dayjs";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
} from "chart.js";
import { Line } from "react-chartjs-2";

import Typography from "./Typography";
import GraphCard from "./GraphCard";
import mq from "../Util/mediaQueries";
import { ScreenshotContext } from "../providers/ScreenshotProvider";

const MINIMUM_Y_AXIS_TICKS = 6;

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip
);

const StyledCard = styled(GraphCard)(({ theme }) =>
  mq({
    "&.graph": {
      padding: [
        `${theme.spacing(3)} ${theme.spacing(2)} ${theme.spacing(3)}`,
        null,
        null,
        `${theme.spacing(4)}`,
      ],
    },
  })
);

const ACTIVE_USERS_LABEL = "Active Users";
const NEW_SIGN_UPS_LABEL = "New Sign Ups";

function UserTrendsGraph({ userTrends }) {
  const context = useContext(ScreenshotContext);
  const theme = useTheme();
  const maxSignUps = Math.max(
    ...userTrends.map((item) => item.signupCount),
    ...userTrends.map((item) => item.appOpenCount)
  );
  const intervals = Math.ceil(maxSignUps / (MINIMUM_Y_AXIS_TICKS - 1));
  const hasPartialWeek = userTrends.length >= 2;

  const fullWeeks = [...userTrends];
  const partialWeeks = [...userTrends.map(() => undefined)];

  if (hasPartialWeek) {
    const partialEndDate = fullWeeks[fullWeeks.length - 1];
    partialWeeks[partialWeeks.length - 1] = partialEndDate;
    // Remove partial week from full weeks
    fullWeeks[fullWeeks.length - 1] = undefined;
    // Add end of last full week as the start of last (partial) week
    partialWeeks[partialWeeks.length - 2] = fullWeeks[fullWeeks.length - 2];
  }

  const StyledContainer = styled("div")(() =>
    mq({
      display: "flex",
      flexDirection: "column",
      width: "100%",
      marginLeft: [27, null, null, 31],
      marginRight: [0, null, null, 11],
      ".chart-container": {
        position: "relative",
        maxWidth: "100%",
        width: "100%",
        overflowX: "auto",
        paddingBottom: theme.spacing(1),
      },
      ".chart-container > canvas": {
        position: "absolute",
        left: 0,
        top: 0,
        pointerEvents: "none",
      },
      ".chart-wrapper": {
        height: 380,
        width: `max(calc(50px * ${userTrends.length}), 100%)`,
      },
      ".legend": {
        alignSelf: "center",
        display: "flex",
        flexDirection: "row",
        marginTop: theme.spacing(3),
        "> :first-of-type": {
          marginRight: theme.spacing(3),
        },
        ".key": {
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        },
        ".icon": {
          display: "inline-block",
          width: 16,
          height: 16,
          borderRadius: 16,
          lineHeight: 16,
          marginRight: 12,
        },
        ".active": {
          ".icon": {
            backgroundColor: theme.palette.colors.cobaltBase,
          },
        },
        ".new": {
          ".icon": {
            backgroundColor: theme.palette.colors.purpleBase,
          },
        },
      },
    })
  );

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    hoverBorderColor: "rgba(112, 112, 112, .3)",
    hoverBorderWidth: 15,
    animation: {
      duration: context.takingScreenshot ? 0 : 1000,
    },
    elements: {
      line: {
        fill: false,
      },
    },
    scales: {
      xAxis: {
        distribution: "linear",
        time: {
          unit: "day",
        },
        grid: {
          display: true,
        },
        ticks: {
          callback(value) {
            return dayjs(userTrends[value].week).format("MMM D");
          },
          font: {
            family: theme.typography.fontFamily,
          },
        },
      },
      yAxis: {
        grid: {
          borderDash: [5, 5],
        },
        beginAtZero: true,
        suggestedMax: intervals * (MINIMUM_Y_AXIS_TICKS - 1),
        ticks: {
          count: MINIMUM_Y_AXIS_TICKS,
          font: {
            family: theme.typography.fontFamily,
          },
          stepSize: intervals,
        },
      },
    },
    plugins: {
      tooltip: {
        filter(tooltipItem) {
          // If this item appears in the partial week and full week list (b/c it's the end of a full week AND the start of a partial week)
          if (
            partialWeeks[tooltipItem.dataIndex] &&
            fullWeeks[tooltipItem.dataIndex]
          )
            return !tooltipItem.dataset.partialWeek;
          return true;
        },
        callbacks: {
          title: (data) => {
            const item = data.length ? data[0] : data;
            const isPartialWeek = item.dataset.partialWeek;
            const label = data.length ? item.label : data.label;
            const endDate = dayjs(label);
            const startDate =
              isPartialWeek && item.dataIndex > 0
                ? dayjs(partialWeeks[item.dataIndex - 1].week)
                : endDate.subtract(7, "day");
            return `${startDate.format("MMM D")} - ${endDate.format(
              "MMM D YYYY"
            )}`.toUpperCase();
          },
          label: (tooltipItem) => {
            const label =
              tooltipItem.dataset.label === ACTIVE_USERS_LABEL
                ? "active user"
                : "new user";
            return `${tooltipItem.formattedValue} ${label}${
              tooltipItem.formattedValue === "1" ? "" : "s"
            }`;
          },
        },
        backgroundColor: "white",
        padding: 16,
        usePointStyle: true,
        cornerRadius: 10,
        caretSize: 0,
        borderColor: theme.palette.colors.gray100,
        borderWidth: 1,
        titleColor: theme.palette.colors.gray600,
        titleAlign: "center",
        titleSpacing: 16,
        titleMarginBottom: 8,
        boxPadding: 8,
        titleFont: {
          family: theme.typography.fontFamily,
          size: 12,
        },
        bodyColor: theme.palette.colors.gray800,
        bodyFont: {
          family: theme.typography.fontFamily,
          size: 14,
        },
      },
    },
  };

  const data = {
    labels: userTrends.map((pt) => pt.week),
    datasets: [
      {
        label: ACTIVE_USERS_LABEL,
        data: fullWeeks.map((pt) => (pt ? pt.appOpenCount : undefined)),
        borderColor: theme.palette.colors.cobaltBase,
        backgroundColor: theme.palette.colors.cobaltBase,
        partialWeek: false,
      },
      {
        label: ACTIVE_USERS_LABEL,
        data: partialWeeks.map((pt) => (pt ? pt.appOpenCount : undefined)),
        borderColor: theme.palette.colors.cobaltBase,
        backgroundColor: theme.palette.colors.cobaltBase,
        borderDash: [8, 5],
        partialWeek: true,
      },
      {
        label: NEW_SIGN_UPS_LABEL,
        data: fullWeeks.map((pt) => (pt ? pt.signupCount : undefined)),
        borderColor: theme.palette.colors.purpleBase,
        backgroundColor: theme.palette.colors.purpleBase,
        partialWeek: false,
      },
      {
        label: NEW_SIGN_UPS_LABEL,
        data: partialWeeks.map((pt) => (pt ? pt.signupCount : undefined)),
        borderColor: theme.palette.colors.purpleBase,
        backgroundColor: theme.palette.colors.purpleBase,
        borderDash: [8, 5],
        partialWeek: true,
      },
    ],
  };

  return (
    <StyledContainer>
      <div className="chart-container">
        <div className="chart-wrapper">
          <Line updateMode="none" options={options} data={data} />
        </div>
      </div>
      <div className="legend">
        <div className="key active">
          <div className="icon" />
          <Typography variant="body">Active Users</Typography>
        </div>
        <div className="key new">
          <div className="icon" />
          <Typography variant="body">New Sign Ups</Typography>
        </div>
      </div>
    </StyledContainer>
  );
}

UserTrendsGraph.propTypes = {
  userTrends: PropTypes.arrayOf(
    PropTypes.shape({
      appOpenCount: PropTypes.number,
      signupCount: PropTypes.number,
      week: PropTypes.string,
    })
  ).isRequired,
};

function UserTrendsCard({ loading = false, userTrends }) {
  return (
    <StyledCard
      loading={loading}
      showPrivacyWarning={!userTrends}
      title="User Trends"
      tooltip="This chart shows the total number of your employees who open the app (active users) and new users each week."
    >
      {userTrends && <UserTrendsGraph userTrends={userTrends} />}
    </StyledCard>
  );
}

UserTrendsCard.propTypes = {
  loading: PropTypes.bool,
  userTrends: PropTypes.arrayOf(
    PropTypes.shape({
      appOpenCount: PropTypes.number,
      signupCount: PropTypes.number,
      week: PropTypes.string,
    })
  ),
};

UserTrendsCard.defaultProps = {
  loading: false,
  userTrends: null,
};

export default UserTrendsCard;

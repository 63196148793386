/** @jsxRuntime classic */
/** @jsx jsx */
import styled from "@emotion/styled";
import { jsx } from "@emotion/react";
// eslint-disable-next-line no-unused-vars
import React from "react";
import PropTypes from "prop-types";

import Typography from "./Typography";
import mq from "../Util/mediaQueries";

const StyledSubtitle = styled(Typography)(({ theme }) =>
  mq({
    marginTop: theme.spacing(1),
    color: theme.palette.colors.gray800,
  })
);

function PageHeader({ title, subtitle }) {
  return (
    <>
      <Typography variant="large">{title}</Typography>
      {subtitle && <StyledSubtitle variant="body">{subtitle}</StyledSubtitle>}
    </>
  );
}

PageHeader.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

PageHeader.defaultProps = {
  subtitle: null,
};

export default PageHeader;

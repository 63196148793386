/* eslint-disable react/jsx-props-no-spreading */
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import PropTypes from "prop-types";
import Link, { linkClasses } from "@mui/material/Link";

export default function TSLink({ children, href, ...props }) {
  return (
    <Link href={href} {...props}>
      {children}
    </Link>
  );
}

TSLink.propTypes = {
  children: PropTypes.node.isRequired,
  href: PropTypes.string,
};

TSLink.defaultProps = {
  href: null,
};

export { linkClasses };

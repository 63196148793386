/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-props-no-spreading */
// eslint-disable-next-line no-unused-vars
import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { styled, useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Skeleton } from "@mui/material";
import DOMPurify from "dompurify";

import useOrganization from "../hooks/useOrganization";
import mq from "../Util/mediaQueries";
import { APPLE_STORE_LINK, GOOGLE_PLAY_LINK } from "../constants";
import TSTypography from "./Typography";
import Button from "./Button";
import Grid from "./Grid";
import Snackbar from "./Snackbar";

import copy from "../assets/copy.svg";
import check from "../assets/check.svg";

const SharePlanSection = styled(Grid)(({ theme }) =>
  mq({
    marginTop: theme.spacing(7.5),
    ".share-plan-subtitle": {
      color: theme.colors.gray800,
      marginTop: theme.spacing(1),
    },
    ".subject-label": {
      marginTop: theme.spacing(2),
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        border: `1px solid ${theme.colors.gray200}`,
        borderRadius: "14px",
      },
    },
    "& .MuiInputBase-input": {
      textOverflow: "ellipsis",
    },
    ".offering-copy": {
      padding: "16px",
      marginTop: "8px",
      border: `1px solid ${theme.colors.gray200}`,
      borderRadius: "10px",
    },
    ".subject": {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: "8px",
      paddingLeft: "16px",
    },
    ".offering-copy-btn": {
      img: {
        marginRight: "8px",
        filter:
          "invert(100%) sepia(100%) saturate(0%) hue-rotate(73deg) brightness(103%) contrast(103%)",
        [theme.breakpoints.down("sm")]: {
          marginRight: "inherit",
        },
      },
      [theme.breakpoints.down("sm")]: {
        padding: "12px",
        minWidth: "inherit",
      },
    },
  })
);

const getHumanClassType = (classType) => {
  switch (classType) {
    case "LiveClass":
      return "Live Class";
    case "OnDemandClass":
      return "On-demand Class";
    case "WorksheetResource":
      return "Therapy Worksheet";
    case "QuickTipResource":
      return "Quick Tips";
    default:
      return "";
  }
};

const getLinkText = (classType) => {
  switch (classType) {
    case "LiveClass":
      return "Class Link";
    case "OnDemandClass":
      return "Watch Now";
    case "WorksheetResource":
      return "View Worksheet";
    case "QuickTipResource":
      return "View Quick Tips";
    default:
      return "";
  }
};

const getHumanDate = (date) =>
  new Date(date).toLocaleDateString("en-us", {
    weekday: "short",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
    timeZoneName: "short",
  });

const getLinkIntro = (record) => {
  switch (record.__typename) {
    case "LiveClass":
      return `To join this class, use the url below on ${getHumanDate(
        record.startTime
      )}.`;
    case "OnDemandClass":
      return "Watch now using the url below.";
    case "WorksheetResource":
      return "View worksheet now using the url below.";
    case "QuickTipResource":
      return "View tips now using the url below.";
    default:
      return "";
  }
};

const generateDownloadAppText = (orgCode) =>
  `As a reminder, don't forget to get Talkspace Self-Guided, the #1 mental wellness app that provides you access to self-directed programs, journaling, therapy workshops, and more at your fingertips. Download from the <a href="${APPLE_STORE_LINK}" target="_blank">Apple App Store</a> or <a href="${GOOGLE_PLAY_LINK}" target="_blank">Google Play</a> and use ${
    orgCode ? `code "${orgCode}"` : "the code provided by your organization"
  } during sign up.`;

const getDefaultBody = (plan, orgCode) => {
  if (plan) {
    let body = `${generateDownloadAppText(orgCode)}<br><br>${
      plan.emailIntroCopy
    }<br><br>`;
    plan.records.forEach((record) => {
      body +=
        `<b>${getHumanClassType(record.__typename)}: ${
          record.title
        }</b><br><br>` +
        `${
          record.__typename === "LiveClass"
            ? getHumanDate(record.startTime)
            : ""
        }\n\n` +
        `${record.description}<br><br>` +
        `${getLinkIntro(record)}<br>` +
        `<a href="${
          record.liveLink || record.recordingLink || record.fileUrl
        }" target="_blank">
          ${getLinkText(record.__typename)}
        </a><br><br>`;
    });
    // The following line is to sanitize the body to prevent XSS attacks
    // ADD_ATTR is used to allow the target attribute on the anchor tag
    // SO links will be opened ina new tab and DOMPurify won't scrub it
    return DOMPurify.sanitize(body, { ADD_ATTR: ["target"] });
  }
  return "";
};

export default function SharePlanWidget({ plan, loading }) {
  const [email, setEmail] = useState({
    subject: plan?.emailSubject || "",
    body: getDefaultBody(plan) || "",
  });
  const [subjectCopySuccess, setSubjectCopySuccess] = useState(false);
  const [bodyCopySuccess, setBodyCopySuccess] = useState(false);
  const [open, setOpen] = useState(false);
  const emailBodyRef = useRef();
  const { orgCode } = useOrganization();

  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    setEmail({
      subject: plan?.emailSubject,
      body: getDefaultBody(plan, orgCode),
    });
  }, [plan, orgCode]);

  const handleCopy = async (text, subject) => {
    await navigator.clipboard.writeText(text);
    if (sm) setOpen(true);
    if (subject) {
      setSubjectCopySuccess(true);
      setTimeout(() => setSubjectCopySuccess(false), 3000);
    } else {
      setBodyCopySuccess(true);
      setTimeout(() => setBodyCopySuccess(false), 3000);
    }
  };
  const highlightAndCopyElement = (elementRef) => {
    if (!elementRef || !elementRef.current) return false;
    if (sm) setOpen(true);
    const selection = window.getSelection();
    const range = document.createRange();
    range.selectNodeContents(elementRef.current);
    selection.removeAllRanges();
    selection.addRange(range);
    document.execCommand("copy");
    selection.removeAllRanges();
    setBodyCopySuccess(true);
    setTimeout(() => setBodyCopySuccess(false), 3000);
    return true;
  };

  return (
    <SharePlanSection item xs={12} lg={8} id="share">
      <TSTypography variant="small">Share this plan</TSTypography>
      <TSTypography variant="body" className="share-plan-subtitle">
        Copy and paste this email text to announce this engagement plan to your
        people.
      </TSTypography>
      <TSTypography variant="heading" className="subject-label">
        Subject line:
      </TSTypography>
      {!loading ? (
        <div className="subject offering-copy">
          {email.subject}
          <Button
            className={`offering-copy-btn ${
              subjectCopySuccess ? "copied" : ""
            }`}
            onClick={() => handleCopy(email.subject, true)}
            variant="contained"
            slim
          >
            <img src={subjectCopySuccess ? check : copy} alt="Copy" />
            {sm ? "" : <p>{subjectCopySuccess ? "Copied!" : "Copy"}</p>}
          </Button>
        </div>
      ) : (
        <Skeleton variant="rounded" height={47} width={300} />
      )}
      <TSTypography variant="heading" className="subject-label">
        Email body:
      </TSTypography>
      {!loading ? (
        <div className="offering-copy">
          <div
            // HTML has been scrubbed by DOMPurify
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: email.body }}
            ref={emailBodyRef}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "row-reverse",
            }}
          >
            <Button
              className={`offering-copy-btn ${bodyCopySuccess ? "copied" : ""}`}
              onClick={() => highlightAndCopyElement(emailBodyRef)}
              variant="contained"
              slim
            >
              <img src={bodyCopySuccess ? check : copy} alt="Copy" />
              {sm ? "" : <p>{bodyCopySuccess ? "Copied!" : "Copy"}</p>}
            </Button>
          </div>
        </div>
      ) : (
        <Skeleton variant="rounded" height={100} width={300} />
      )}
      <Snackbar
        open={open}
        onClose={() => setOpen(false)}
        message="Link copied to clipboard"
        sx={{ width: "max-content", margin: "auto" }}
      />
    </SharePlanSection>
  );
}

SharePlanWidget.propTypes = {
  loading: PropTypes.bool.isRequired,
  plan: PropTypes.shape({
    month: PropTypes.number.isRequired,
    year: PropTypes.number.isRequired,
    themeTitle: PropTypes.string.isRequired,
    themeDescription: PropTypes.string.isRequired,
    emailSubject: PropTypes.string.isRequired,
    records: PropTypes.arrayOf(PropTypes.shape({})),
  }),
};

SharePlanWidget.defaultProps = {
  plan: { records: [] },
};

export { SharePlanWidget };

import { loader } from "graphql.macro";
import { useQuery } from "@apollo/client";

const GetClasses = loader("../gql/classes/queries/getClasses.gql");

function useClasses() {
  const { data, error, loading, refetch } = useQuery(GetClasses, {
    variables: { limit: "30" },
  });

  return {
    data,
    loading,
    error,
    refetch,
  };
}

export default useClasses;

/* eslint-disable react/jsx-props-no-spreading */
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import PropTypes from "prop-types";
import CardContent, { cardContentClasses } from "@mui/material/CardContent";

function TSCardContent({ children, ...props }) {
  return <CardContent {...props}>{children}</CardContent>;
}

TSCardContent.propTypes = {
  children: PropTypes.node.isRequired,
};

export default TSCardContent;
export { cardContentClasses };

import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import { ThemeProvider as EmotionThemeProvider } from "@emotion/react";

import {
  ApolloProvider,
  ApolloClient,
  createHttpLink,
  InMemoryCache,
} from "@apollo/client";
import { BrowserRouter } from "react-router-dom";
import { emotionTheme, muiTheme } from "./themes";
import reportWebVitals from "./reportWebVitals";
import App from "./App";

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_ADMIN_BACKEND_URL,
  credentials: "include",
});

const client = new ApolloClient({
  link: httpLink,
  cache: new InMemoryCache(),
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <MuiThemeProvider theme={muiTheme}>
    <EmotionThemeProvider theme={emotionTheme}>
      <React.StrictMode>
        <BrowserRouter>
          <ApolloProvider client={client}>
            <App />
          </ApolloProvider>
        </BrowserRouter>
      </React.StrictMode>
    </EmotionThemeProvider>
  </MuiThemeProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

/* eslint-disable react/jsx-props-no-spreading */
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import React from "react";
import List, { listClasses } from "@mui/material/List";

const TSList = React.forwardRef(({ children, ...props }, ref) => (
  <List ref={ref} {...props}>
    {children}
  </List>
));

export default TSList;
export { listClasses };

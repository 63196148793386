/* eslint-disable react/jsx-props-no-spreading */
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import Layout from "./LoggedOutLayout";

const withLoggedOutLayout = (Component) => {
  function LoggedOutWithLayout({ ...props }) {
    return (
      <Layout>
        <Component {...props} />
      </Layout>
    );
  }

  LoggedOutWithLayout.displayName = "LoggedOutWithLayout";

  return LoggedOutWithLayout;
};

export default withLoggedOutLayout;

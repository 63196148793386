/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import { createContext, useMemo, useState } from "react";
import PropTypes from "prop-types";

const ScreenshotContext = createContext({
  takingScreenshot: false,
});

export default function ScreenshotProvider({ children }) {
  const [takingScreenshot, setTakingScreenshot] = useState(false);
  const value = useMemo(
    () => ({
      takingScreenshot,
      startScreenshot: () => setTakingScreenshot(true),
      finishScreenshot: () => setTakingScreenshot(false),
    }),
    [takingScreenshot]
  );

  return (
    <ScreenshotContext.Provider value={value}>
      {children}
    </ScreenshotContext.Provider>
  );
}

ScreenshotProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { ScreenshotContext };

/* eslint-disable react/jsx-props-no-spreading */
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";

function TSGrid({ children, ...props }) {
  return <Grid {...props}>{children}</Grid>;
}

TSGrid.propTypes = {
  children: PropTypes.node,
};

TSGrid.defaultProps = {
  children: null,
};

export default TSGrid;

/* eslint-disable react/jsx-props-no-spreading */
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import styled from "@emotion/styled";
import PropTypes from "prop-types";
import classNames from "classnames";

import Button, { buttonClasses } from "./Button";
import Tooltip, { tooltipClasses } from "./Tooltip";
import ConditionalWrapper from "./ConditionalWrapper";

import mq from "../Util/mediaQueries";

const StyledButton = styled(Button)(({ theme }) =>
  mq({
    color: theme.palette.colors.white,
    width: 44,
    minWidth: 44,
    height: 44,
    padding: 0,
  })
);

const StyledTooltip = styled(({ className, children, ...props }) => (
  <Tooltip
    classes={{ popper: className }}
    PopperProps={{
      popperOptions: {
        modifiers: [
          {
            name: "offset",
            options: {
              offset: [0, -8],
            },
          },
        ],
      },
    }}
    {...props}
  >
    {children}
  </Tooltip>
))(({ theme }) =>
  mq({
    [`.${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.colors.accessibilityGreen,
      font: theme.palette.colors.white,
      fontWeight: 700,
      fontSize: 16,
    },
    [`&.${tooltipClasses.popper} .${tooltipClasses.tooltipPlacementBottom}`]: {
      borderRadius: 5,
      display: "inline-block",
    },
  })
);

const tooltipWrapper = (tooltipText) =>
  function TooltipWrapper(children) {
    return (
      <StyledTooltip title={tooltipText} placement="bottom" disableInteractive>
        {children}
      </StyledTooltip>
    );
  };

export default function DecoratedIconButton({
  className,
  children,
  tooltipText,
  ...props
}) {
  if (tooltipText)
    return (
      <ConditionalWrapper
        condition={tooltipText}
        wrapper={tooltipWrapper(tooltipText)}
      >
        <StyledButton
          className={classNames(className)}
          onMouseDown={(e) => {
            e.stopPropagation();
          }}
          {...props}
        >
          {children}
        </StyledButton>
      </ConditionalWrapper>
    );
}

DecoratedIconButton.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  tooltipText: PropTypes.string,
};

DecoratedIconButton.defaultProps = {
  className: null,
  tooltipText: null,
};

export { buttonClasses as decoratedIconButtonClasses };

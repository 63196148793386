/* eslint-disable react/jsx-props-no-spreading */
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import styled from "@emotion/styled";
import Skeleton, { skeletonClasses } from "@mui/material/Skeleton";
import mq from "../Util/mediaQueries";

const StyledSkeleton = styled(Skeleton)(() =>
  mq({
    width: "116px",
    backgroundColor: "#E3E3E3",
    opacity: 0.8,
    borderRadius: 12,
    height: 24,
    marginTop: 18,
  })
);

export default function TSSkeleton({ ...props }) {
  return <StyledSkeleton animation="wave" variant="rectangular" {...props} />;
}

export { skeletonClasses };

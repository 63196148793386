import { loader } from "graphql.macro";
import { useQuery } from "@apollo/client";

const GetResources = loader("../gql/resources/queries/getResources.gql");

function useResources() {
  const { data, error, loading, refetch } = useQuery(GetResources);

  return {
    data,
    loading,
    error,
    refetch,
  };
}

export default useResources;

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import styled from "@emotion/styled";
import PropTypes from "prop-types";

import Typography from "./Typography";
import TSCard from "./Card";
import TSSkeleton from "./Skeleton";
import InfoTooltip from "./InfoTooltip";
import mq from "../Util/mediaQueries";
import withHideOnScreenshot from "./withHideOnScreenshot";

const Tooltip = withHideOnScreenshot(InfoTooltip);

const StyledCard = styled(TSCard)(({ theme }) =>
  mq({
    alignItems: "center",
    display: "flex",
    gap: [theme.spacing(1), null, null, theme.spacing(2)],
    height: ["128px", null, null, "175px"],
    gridColumn: "span 1 / span 1",
    position: "relative",
    backgroundColor: theme.palette.colors.accessibilityGreen50,
    color: theme.palette.colors.accessibilityGreen,
    border: "none",
    justifyContent: "center",
    ".tooltip-container": {
      position: "absolute",
      right: 8,
      top: 8,
      ".icon": {
        color: theme.palette.colors.accessibilityGreen,
      },
    },
    ".skeleton": {
      backgroundColor: theme.palette.colors.accessibilityGreen100,
      borderRadius: 5,
      width: 71,
    },
  })
);

function InsightsDashboardCard({
  className,
  title,
  value,
  isPercentage,
  loading,
  tooltip,
}) {
  return (
    <StyledCard className={className}>
      <div className="tooltip-container">
        <Tooltip
          body={tooltip}
          title={title}
          iconProps={{ className: "icon", fontSize: "small" }}
        />
      </div>
      <Typography variant="heading">{title}</Typography>
      {loading ? (
        <TSSkeleton className="skeleton" />
      ) : (
        <Typography variant="xlarge">
          {value || 0}
          {isPercentage ? "%" : ""}
        </Typography>
      )}
    </StyledCard>
  );
}

InsightsDashboardCard.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  value: PropTypes.number,
  isPercentage: PropTypes.bool,
  loading: PropTypes.bool,
  tooltip: PropTypes.node,
};

InsightsDashboardCard.defaultProps = {
  className: null,
  value: null,
  isPercentage: false,
  loading: false,
  tooltip: null,
};

export default InsightsDashboardCard;

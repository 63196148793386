/* eslint-disable react/jsx-props-no-spreading */
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import styled from "@emotion/styled";
import PropTypes from "prop-types";
import classNames from "classnames";

import Chip from "./Chip";
import Typography from "./Typography";
import mq from "../Util/mediaQueries";

const INDIVIDUALS_CATEGORY = "individuals";
const PARENTING_CATEGORY = "parenting";
const COUPLES_CATEGORY = "couples";

const StyledChip = styled(Chip)(({ theme }) =>
  mq({
    width: "auto",
    height: 20,
    backgroundColor: theme.palette.colors.violetLight,
    color: theme.palette.colors.violetDark,
  })
);

const getCategoryName = (categoryName) => {
  if (categoryName.match(/individual/gi)) return INDIVIDUALS_CATEGORY;
  if (categoryName.match(/parent/gi)) return PARENTING_CATEGORY;
  if (categoryName.match(/couple/gi)) return COUPLES_CATEGORY;
  return null;
};

function ClassCategoryChip({ name, className }) {
  const categoryName = getCategoryName(name);
  if (categoryName)
    return (
      <StyledChip
        className={classNames("class-category-chip", className)}
        size="small"
        label={
          <Typography variant="caption" component="span">
            {categoryName.charAt(0).toUpperCase() + categoryName.slice(1)}
          </Typography>
        }
      />
    );
  return null;
}

ClassCategoryChip.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
};

ClassCategoryChip.defaultProps = {
  className: null,
};

export default ClassCategoryChip;

// eslint-disable-next-line no-unused-vars
import React, { useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";

import { styled, useTheme } from "@mui/material/styles";
import Skeleton from "@mui/material/Skeleton";

import mq from "../Util/mediaQueries";
import getMonthName from "../Util/dateUtils";

import IconButton from "./IconButton";

import back from "../assets/back.svg";
import forward from "../assets/forward.svg";

const CarouselContainer = styled("div")(() =>
  mq({
    overflow: "hidden",
    width: "100%",
    height: "71px",
    ".carousel-container": {
      display: "flex",
      flexDirection: "column",
    },
    ".carousel-wrapper": {
      display: "flex",
      position: "relative",
    },
    ".carousel-content-wrapper": {
      overflow: "hidden",
      flex: "2 3 auto",
      height: "100%",
    },
    ".carousel-content": {
      display: "flex",
      transition: "all 250ms linear",
      justifyContent: "space-between",
      // -ms-overflow-style: "none";  /* hide scrollbar in IE and Edge */
      scrollbarWidth: "none" /* Hide scrollbar in Firefox */,
    },
    /* Hide scrollbar in webkit browser */
    ".carousel-content::-webkit-scrollbar, .carousel-content::-webkit-scrollbar":
      {
        display: "none",
      },
    ".carousel-content > *": {
      width: "100%",
      flexShrink: 0,
      flexGrow: 1,
    },
    ".carousel-content.show-2 > *": {
      width: "50%",
    },
    ".carousel-content.show-3 > *": {
      width: "calc(100% / 3)",
    },
    ".carousel-content.show-4 > *": {
      width: "calc(100% / 4)",
    },
    ".left-arrow, .right-arrow": {
      position: "relative",
      transform: "translateY(15%)",
      width: "55px",
      height: "55px",
      borderRadius: "100%",
      boxShadow: "0px 1px 4px #C9D3DC",
      img: {
        filter:
          "invert(21%) sepia(79%) saturate(1816%) hue-rotate(161deg) brightness(89%) contrast(101%)",
      },
      "&:hover": {
        boxShadow: "0px 2px 12px -4px #C9D3DC",
      },
    },
    ".left-arrow:disabled, .right-arrow:disabled": {
      boxShadow: "0px 1px 4px #C9D3DC",
      img: {
        filter:
          "invert(94%) sepia(7%) saturate(451%) hue-rotate(190deg) brightness(92%) contrast(91%)",
      },
    },
    ".left-arrow": {
      marginRight: "10px",
      marginLeft: "1px", // to leave room for the boxShadow
    },
    ".right-arrow": {
      marginRight: "1px", // to leave room for the boxShadow
      marginLeft: "10px",
    },
  })
);

const CarouselTabWrapper = styled("div")(({ theme }) =>
  mq({
    "&.active .active-bar": {
      width: "140px",
      margin: "0 auto",
      height: "4px",
      background: theme.colors.accessibilityGreen,
      borderRadius: "4px 4px 0px 0px",
      position: "relative",
      top: "-5px",
    },
    "&.active .tab": {
      color: theme.colors.accessibilityGreenDark,
    },
    "&.active .tab .pill ": {
      backgroundColor: theme.colors.accessibilityGreen100,
      color: theme.colors.accessibilityGreenDark,
    },
  })
);

const CarouselTab = styled("div")(({ theme }) =>
  mq({
    transition: "all .25s ease",
    margin: "6px auto",
    textAlign: "center",
    color: theme.colors.gray600,
    fontSize: "16px",
    fontWeight: "600",
    borderRadius: "10px",
    padding: "4px 6px",
    minWidth: "max-content",
    width: "140px",
    ".skeleton": {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      height: "100%",
      width: "100%",
      gap: "10px",
    },
    ".pill": {
      fontSize: "12px",
      fontWeight: "400",
      padding: "1px 8px",
      background: theme.colors.gray100,
      borderRadius: "30px",
      maxWidth: "max-content",
      color: theme.colors.gray800,
      margin: "0 auto",
      marginTop: "8px",
    },
    "&:hover": {
      backgroundColor: theme.colors.gray50,
      cursor: "pointer",
    },
  })
);

const SkeletonTab = styled(CarouselTab)(() =>
  mq({
    "&:hover": {
      backgroundColor: "transparent",
      cursor: "not-allowed",
    },
  })
);

function PlansCarousel({
  plans,
  show,
  handlePlanSelection,
  activePlan,
  isLoading,
}) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [length, setLength] = useState(plans?.length || 4);
  const [touchPosition, setTouchPosition] = useState(null);

  const theme = useTheme();

  useEffect(() => {
    setLength(plans?.length);
  }, [plans]);

  useEffect(() => {
    if (activePlan) {
      // If the active plan is not in the current carousel, move the carousel to the active plan
      let index = plans.findIndex(
        (plan) => plan.themeTitle === activePlan.themeTitle
      );
      const maxIndex = length - show;
      if (index > maxIndex) {
        index = maxIndex;
      }
      setCurrentIndex(index);
    }
  }, [activePlan, plans, length, show]);

  const handleKeyPress = useCallback((e) => {
    if (e.key === "Enter" && e.target.className.includes("tab")) {
      e.target.click();
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", handleKeyPress);
  }, [handleKeyPress]);

  const next = () => {
    if (currentIndex < length - show) {
      setCurrentIndex((prevState) => prevState + 1);
    }
  };

  const prev = () => {
    if (currentIndex > 0) {
      setCurrentIndex((prevState) => prevState - 1);
    }
  };

  const handleTouchStart = (e) => {
    const touchDown = e.touches[0].clientX;
    setTouchPosition(touchDown);
  };

  const handleTouchMove = (e) => {
    const touchDown = touchPosition;

    if (touchDown === null) {
      return;
    }

    const currentTouch = e.touches[0].clientX;
    const diff = touchDown - currentTouch;

    if (diff > 5) {
      next();
    }
    if (diff < -5) {
      prev();
    }

    setTouchPosition(null);
  };

  return (
    <CarouselContainer>
      <div className="carousel-container">
        <div className="carousel-wrapper">
          <IconButton
            className="left-arrow"
            type="button"
            onClick={prev}
            disabled={!(currentIndex > 0) || isLoading}
          >
            <img src={back} alt="Previous" />
          </IconButton>
          <div
            className="carousel-content-wrapper"
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
          >
            <div
              className={`carousel-content show-${show}`}
              style={{
                transform: `translateX(-${currentIndex * (100 / show)}%)`,
              }}
            >
              {plans
                ? plans.map((plan) => (
                    <CarouselTabWrapper
                      key={Math.random()}
                      className={
                        activePlan.themeTitle === plan.themeTitle
                          ? "active"
                          : ""
                      }
                    >
                      <CarouselTab
                        key={plan.themeTitle}
                        className="tab"
                        onClick={() => handlePlanSelection(plan)}
                        disabled={isLoading}
                        tabIndex={0}
                      >
                        <div>
                          {getMonthName(plan.month)} {plan.year}
                        </div>
                        <div className="pill">{plan.themeTitle}</div>
                      </CarouselTab>
                      {/* div filled with an empty space to give content => width */}
                      {/* the downward curving bottom border bar per designs */}
                      <div className="active-bar">&nbsp;</div>
                    </CarouselTabWrapper>
                  ))
                : [1, 2, 3, 4].map(() => (
                    // Make 4 skeleton tabs if no plans
                    <SkeletonTab key={Math.random()}>
                      <div className="skeleton">
                        <Skeleton
                          sx={{
                            bgcolor: theme.palette.colors.gray200,
                            borderRadius: "5px",
                          }}
                          variant="rounded"
                          height={20}
                          width={95}
                        />
                        <Skeleton
                          sx={{
                            bgcolor: theme.palette.colors.gray100,
                            borderRadius: "30px",
                          }}
                          variant="rounded"
                          height={20}
                          width={67}
                        />
                      </div>
                    </SkeletonTab>
                  ))}
            </div>
          </div>
          <IconButton
            className="right-arrow"
            type="button"
            onClick={next}
            disabled={!(currentIndex < length - show) || isLoading}
          >
            <img src={forward} alt="Next" />
          </IconButton>
        </div>
      </div>
    </CarouselContainer>
  );
}

PlansCarousel.propTypes = {
  plans: PropTypes.arrayOf(
    PropTypes.shape({
      themeTitle: PropTypes.string.isRequired,
      month: PropTypes.number.isRequired,
      year: PropTypes.number.isRequired,
    })
  ),
  activePlan: PropTypes.shape({
    themeTitle: PropTypes.string.isRequired,
    month: PropTypes.number.isRequired,
    year: PropTypes.number.isRequired,
  }),
  handlePlanSelection: PropTypes.func.isRequired,
  show: PropTypes.number,
  isLoading: PropTypes.bool.isRequired,
};

PlansCarousel.defaultProps = {
  show: 1,
  plans: [],
  activePlan: {},
};

export default PlansCarousel;

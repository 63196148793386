/* eslint-disable react/jsx-props-no-spreading */
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";

function TSAppBar({ children, ...props }) {
  return <AppBar {...props}>{children}</AppBar>;
}

TSAppBar.propTypes = {
  children: PropTypes.node.isRequired,
};

export default TSAppBar;

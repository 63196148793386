/* eslint-disable react/jsx-props-no-spreading */
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import { useCallback, useEffect, useRef } from "react";
import PropTypes from "prop-types";

const withAccessibleOnClick = (Component) => {
  function AccessibleOnClickComponent({ onClick, ...props }) {
    const ref = useRef(null);

    const onKeyDown = useCallback(
      (event) => {
        if (
          onClick &&
          (event.key === "Enter" || event.key === "Space") &&
          document.activeElement === ref?.current
        ) {
          onClick(event);
        }
      },
      [onClick]
    );

    useEffect(() => {
      document.addEventListener("keydown", onKeyDown);
      return () => {
        document.removeEventListener("keydown", onKeyDown);
      };
    }, [onKeyDown]);

    return <Component ref={ref} onClick={onClick} {...props} />;
  }

  AccessibleOnClickComponent.displayName = "AccessibleOnClickComponent";
  AccessibleOnClickComponent.propTypes = {
    onClick: PropTypes.func,
  };
  AccessibleOnClickComponent.defaultProps = {
    onClick: null,
  };

  return AccessibleOnClickComponent;
};

export default withAccessibleOnClick;

/* eslint-disable react/jsx-props-no-spreading */
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import styled from "@emotion/styled";
import PropTypes from "prop-types";
import classNames from "classnames";

import Chip from "./Chip";
import Typography from "./Typography";
import mq from "../Util/mediaQueries";

const PDF_CATEGORY = "pdf";

const CATEGORY_MAP = {
  [PDF_CATEGORY]: {
    name: "PDF",
  },
};

const StyledChip = styled(Chip)(({ theme }) =>
  mq({
    width: "auto",
    height: 20,
    backgroundColor: theme.palette.colors.violetLight,
    color: theme.palette.colors.violetDard,
  })
);

const getCategory = (categoryName) => {
  if (categoryName.match(/pdf/gi)) return PDF_CATEGORY;
  return null;
};

function ResourceCategoryChip({ name, className }) {
  const category = getCategory(name);
  if (category)
    return (
      <StyledChip
        className={classNames("resource-category-chip", className)}
        size="small"
        label={
          <Typography variant="caption" component="span">
            {CATEGORY_MAP[category].name}
          </Typography>
        }
      />
    );
  return null;
}

ResourceCategoryChip.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
};

ResourceCategoryChip.defaultProps = {
  className: null,
};

export default ResourceCategoryChip;

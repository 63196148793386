/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import styled from "@emotion/styled";
// eslint-disable-next-line no-unused-vars
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import LockIcon from "@mui/icons-material/Lock";

import Typography from "./Typography";
import InfoTooltip from "./InfoTooltip";
import Card, { cardClasses } from "./Card";
import mq from "../Util/mediaQueries";
import withHideOnScreenshot from "./withHideOnScreenshot";

const Tooltip = withHideOnScreenshot(InfoTooltip);

const StyledCard = styled(Card)(({ theme }) =>
  mq({
    width: "100%",
    padding: [
      `${theme.spacing(3)} ${theme.spacing(4)} 18px`,
      null,
      null,
      `${theme.spacing(4)} ${theme.spacing(4)} 18px`,
    ],
    position: "relative",
    "&.privacy-card": {
      minHeight: 352,
      ".icon-wrapper": {
        width: 52,
        height: 52,
        borderRadius: 52,
        backgroundColor: theme.palette.colors.accessibilityGreen50,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: theme.spacing(5),
      },
      ".icon": {
        color: theme.palette.colors.accessibilityGreen,
      },
      p: {
        marginTop: theme.spacing(2),
        maxWidth: 382,
        textAlign: "center",
        color: theme.palette.colors.gray800,
      },
    },
    [`&.${cardClasses.loading}`]: {
      justifyContent: "flex-start",
      minHeight: [380, null, 545],
      [`.${cardClasses.progress}`]: {
        flex: 1,
        justifyContent: "center",
        marginTop: -80,
      },
    },
    ".tooltip-wrapper": {
      position: "absolute",
      top: 6,
      right: 6,
    },
    ".tooltip": {
      color: theme.palette.colors.tsBlack,
    },
    ".tooltip-icon": {
      color: [
        theme.palette.colors.tsBlack,
        null,
        null,
        theme.palette.colors.gray800,
      ],
    },
    ".graph-title": {
      paddingBottom: [28, null, 35],
      textAlign: "center",
      alignSelf: "center",
    },
  })
);

function Header({ title, tooltip }) {
  return (
    <>
      <div className="tooltip-wrapper">
        <Tooltip
          className="tooltip"
          title={title}
          body={tooltip}
          iconProps={{ className: "tooltip-icon", fontSize: "small" }}
        />
      </div>
      <Typography variant="heading" className="graph-title">
        {title}
      </Typography>
    </>
  );
}

Header.propTypes = {
  title: PropTypes.string.isRequired,
  tooltip: PropTypes.node.isRequired,
};

function GraphCard({
  loading = false,
  showPrivacyWarning,
  title,
  tooltip,
  children,
  className,
}) {
  const header = <Header title={title} tooltip={tooltip} />;
  if (loading)
    return (
      <StyledCard
        className={classNames(className, "loading-card")}
        loading
        loadingTitle={header}
      />
    );

  if (!showPrivacyWarning) {
    return (
      <StyledCard className={classNames(className, "graph")}>
        {header}
        {children}
      </StyledCard>
    );
  }

  return (
    <StyledCard className={classNames(className, "privacy-card")}>
      {header}
      <div className="icon-wrapper">
        <LockIcon className="icon" />
      </div>
      <Typography variant="body">
        To maintain the privacy of your employees, we do not show this data
        until at least 10 employees sign up.
      </Typography>
    </StyledCard>
  );
}

GraphCard.propTypes = {
  loading: PropTypes.bool,
  showPrivacyWarning: PropTypes.bool,
  title: PropTypes.string.isRequired,
  tooltip: PropTypes.string.isRequired,
  children: PropTypes.node,
  className: PropTypes.string,
};

GraphCard.defaultProps = {
  loading: false,
  showPrivacyWarning: false,
  children: PropTypes.node,
  className: PropTypes.string,
};

export default GraphCard;

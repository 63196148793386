/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
// eslint-disable-next-line no-unused-vars
import React, {
  useState,
  useContext,
  useRef,
  useEffect,
  useCallback,
} from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { styled, useTheme, alpha } from "@mui/material/styles";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import PersonIcon from "@mui/icons-material/Person";

import AppBar from "./AppBar";
import Avatar from "./Avatar";
import ClickAwayListener from "./ClickAwayListener";
import Divider from "./Divider";
import Drawer from "./Drawer";
import IconButton from "./IconButton";
import Popper from "./Popper";
import List from "./List";
import LogOutDialog from "./LogOutDialog";
import ProfileDialog from "./ProfileDialog";

import logo from "../assets/talkspace-logo.svg";
import profile from "../assets/profile.svg";
import logout from "../assets/logout.svg";
import resource from "../assets/resources.svg";
import plans from "../assets/plans.svg";
import insights from "../assets/insights.svg";
import classes from "../assets/classes.svg";
import dropArrow from "../assets/drop-arrow.svg";

import { DRAWER_WIDTH } from "../constants";
import mq from "../Util/mediaQueries";

import { AuthContext } from "../providers/AuthProvider";

const APP_BAR_HEIGHT = 56;

const navLinks = [
  { name: "Engagement Plans", path: "/plans", img: plans },
  { name: "Class Schedule", path: "/classes", img: classes },
  { name: "Resource Library", path: "/resources", img: resource },
  { name: "Insights", path: "/insights", img: insights },
];

const StyledAppBar = styled(AppBar)(({ theme }) =>
  mq({
    position: "fixed",
    display: ["flex", null, null, null, "none"],
    backgroundColor: theme.palette.colors.indigo,
    boxShadow: "none",
    zIndex: theme.zIndex.drawer + 1,
    flexDirection: "row",
    justifyContent: "space-between",
    height: APP_BAR_HEIGHT,
    padding: `${theme.spacing(0)} ${theme.spacing(2)}`,
  })
);

const StyledDrawer = styled(Drawer)(({ theme }) =>
  mq({
    zIndex: theme.zIndex.drawer,
  })
);

const StyledLogo = styled("img")(() =>
  mq({
    width: "100px",
  })
);

const StyledAvatar = styled(Avatar)(({ theme }) =>
  mq({
    width: 32,
    height: 32,
    backgroundColor: alpha(theme.palette.colors.indigo, 0.1),
    color: theme.palette.colors.indigo,
  })
);

const StyledAvatarDropdown = styled(IconButton)(({ theme }) =>
  mq({
    position: "fixed",
    top: 16,
    right: 36,
    zIndex: theme.zIndex.drawer + 1,
    padding: theme.spacing(1),
    backgroundColor: theme.palette.colors.white,
    width: "auto",
    height: "auto",
    ".arrow": {
      marginLeft: 8,
    },
  })
);

const StyledAvatarPopup = styled(Popper)(({ theme }) =>
  mq({
    justifyContent: "center",
    width: 137,
    borderRadius: "10px",
    padding: "8px",
    zIndex: theme.zIndex.drawer,
    boxShadow: `0px 0px 24px ${theme.palette.colors.gray200}`,
    backgroundColor: theme.palette.colors.white,
  })
);

const StyledMenuButton = styled(IconButton)(({ theme }) =>
  mq({
    color: theme.palette.colors.accessibilityGreen50,
    marginTop: theme.spacing(1),
    "&:hover": {
      backgroundColor: alpha(theme.palette.colors.accessibilityGreen100, 0.1),
    },
    "&:active": {
      backgroundColor: alpha(theme.palette.colors.accessibilityGreen100, 0.2),
    },
  })
);

const StyledAvatarMenuList = styled(List)(() =>
  mq({
    paddingTop: 0,
    paddingBottom: 0,
  })
);

const DrawerNavLink = styled(NavLink)(({ theme }) =>
  mq({
    textDecoration: "none",
    display: "flex",
    fontSize: 16,
    lineHeight: "24px",
    fontWeight: 600,
    width: "100%",
    borderRadius: "10px",
    padding: "10px 16px",
    marginTop: theme.spacing(1),
    color: theme.palette.colors.accessibilityGreen50,
    "&:hover": {
      backgroundColor: alpha(theme.palette.colors.accessibilityGreen100, 0.1),
    },
    img: {
      // SVGs do not inheriting color, so we apply a filter
      // https://stackoverflow.com/questions/22252472/how-to-change-the-color-of-an-svg-element
      filter:
        "invert(91%) sepia(100%) saturate(26%) hue-rotate(257deg) brightness(107%) contrast(100%)",
      marginRight: "11px",
    },
    "&.active:not(.avatar-navlink)": {
      backgroundColor: alpha(theme.palette.colors.accessibilityGreen100, 0.2),
    },
  })
);

const AvatarNavLink = styled(NavLink)(({ theme }) =>
  mq({
    textDecoration: "none",
    display: "flex",
    fontWeight: 700,
    width: "100%",
    borderRadius: "10px",
    padding: "8px 16px",
    color: theme.palette.colors.indigo,
    img: {
      marginRight: "8px",
    },
    "&:hover,&:active": {
      backgroundColor: theme.palette.colors.gray50,
    },
  })
);

function DrawerNavigation() {
  const [mobileDrawerOpen, setMobileDrawerOpen] = useState(false);
  const [avatarMenuOpen, setAvatarMenuOpen] = useState(false);
  const [logOutDialogOpen, setLogOutDialogOpen] = useState(false);
  const [profileDialogOpen, setProfileDialogOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const avatarRef = useRef();
  const theme = useTheme();

  const handleDrawerToggle = useCallback(() => {
    setMobileDrawerOpen(!mobileDrawerOpen);
  }, [mobileDrawerOpen, setMobileDrawerOpen]);

  const handleDrawerIconClick = (e) => {
    e.stopPropagation();
    handleDrawerToggle();
  };

  const handleDrawerClickAway = (e) => {
    if (mobileDrawerOpen) {
      e.stopPropagation();
      e.preventDefault();
      handleDrawerToggle();
    }
  };

  const onKeyDown = useCallback(
    (event) => {
      if (event.key === "Escape" && mobileDrawerOpen) {
        handleDrawerToggle();
      }
    },
    [mobileDrawerOpen, handleDrawerToggle]
  );

  const handleAvatarToggle = () => {
    if (!avatarMenuOpen) setAnchorEl(avatarRef.current);
    setAvatarMenuOpen(!avatarMenuOpen);
  };

  const auth = useContext(AuthContext);
  const [logOut, { loading: logOutLoading }] = auth.logOut;

  const navigate = useNavigate();

  const closeLogOutDialog = () => {
    setLogOutDialogOpen(false);
  };

  const logOutConfirmed = () => {
    setLogOutDialogOpen(false);
    logOut().then(() => {
      navigate("/login?logout=true");
    });
  };

  const openLogOutDialog = (e) => {
    if (e) e.preventDefault();
    setLogOutDialogOpen(true);
  };

  const openProfileDialog = (e) => {
    if (e) e.preventDefault();
    setProfileDialogOpen(true);
  };

  const closeProfileDialog = () => {
    setProfileDialogOpen(false);
  };

  useEffect(() => {
    if (logOutDialogOpen || profileDialogOpen) {
      setMobileDrawerOpen(false);
      setAvatarMenuOpen(false);
    }
  }, [logOutDialogOpen, profileDialogOpen]);

  useEffect(() => {
    if (mobileDrawerOpen)
      document.addEventListener("keydown", onKeyDown, false);
    if (!mobileDrawerOpen)
      document.removeEventListener("keydown", onKeyDown, false);

    return () => {
      document.removeEventListener("keydown", onKeyDown, false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mobileDrawerOpen]);

  const profileMenuLinks = [
    {
      name: "Profile",
      onClick: openProfileDialog,
      img: profile,
    },
    {
      name: "Log Out",
      onClick: openLogOutDialog,
      img: logout,
    },
  ];

  const drawer = (
    <>
      <List>
        {navLinks.map((obj) => (
          <DrawerNavLink key={obj.name} to={obj.path}>
            <img src={obj.img} alt={obj.name} width="24" height="24" />
            {obj.name}
          </DrawerNavLink>
        ))}
      </List>
      <Divider
        sx={{
          display: { xs: "block", lg: "none" },
          backgroundColor: "#3F626C",
          marginLeft: "-16px",
          marginTop: "16px",
          marginRight: "52px",
        }}
      />
      <List sx={{ display: { xs: "block", lg: "none" }, marginTop: "8px" }}>
        {profileMenuLinks.map(({ name, img, onClick }) => (
          <DrawerNavLink
            key={name}
            className="avatar-navlink"
            to="#"
            onClick={onClick}
          >
            <img src={img} alt={name} width="24" height="24" />
            {name}
          </DrawerNavLink>
        ))}
      </List>
    </>
  );

  return (
    <>
      {/* Avatar Drop Down */}
      <StyledAvatarDropdown
        onClick={handleAvatarToggle}
        disableRipple
        sx={{ display: { xs: "none", lg: "flex" } }} // Pulled this style here because IconButton ignores the styled component
        ref={avatarRef}
      >
        <StyledAvatar>
          <PersonIcon fontSize="small" />
        </StyledAvatar>
        <img
          className="arrow"
          src={dropArrow}
          alt="Avatar Drop Arrow"
          width="16"
          height="16"
        />
      </StyledAvatarDropdown>
      <StyledAvatarPopup
        disablePortal
        role="menu"
        open={avatarMenuOpen}
        anchorEl={anchorEl}
        placement="bottom-end"
        sx={{ display: { xs: "none", lg: "block" } }} // Styled here becasue avatarMenuOpen variable was needed
        popperOptions={{
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, 8],
              },
            },
          ],
        }}
      >
        <ClickAwayListener onClickAway={handleAvatarToggle}>
          <StyledAvatarMenuList>
            {profileMenuLinks.map(({ name, img, onClick }) => (
              <AvatarNavLink key={name} onClick={onClick} to="#">
                <img src={img} width="24" height="24" alt={name} />
                {name}
              </AvatarNavLink>
            ))}
          </StyledAvatarMenuList>
        </ClickAwayListener>
      </StyledAvatarPopup>

      {/* Header Bar Drawer Nav */}
      <ClickAwayListener onClickAway={handleDrawerClickAway}>
        <StyledDrawer
          variant="persistent"
          open={mobileDrawerOpen}
          onClose={handleDrawerToggle}
          anchor="top"
          sx={{
            display: { xs: "block", lg: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: "100%",
              backgroundColor: theme.palette.colors.indigo,
              padding: "0 16px 16px",
              marginTop: "56px",
            },
          }}
        >
          {drawer}
        </StyledDrawer>
      </ClickAwayListener>

      {/* Header */}
      <StyledAppBar>
        <StyledLogo src={logo} alt="Talkspace logo" />
        {/* TODO: Replace with  https://codepen.io/designcouch/pen/ExvwPY 3rd option */}
        <StyledMenuButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerIconClick}
        >
          {mobileDrawerOpen ? <CloseIcon /> : <MenuIcon />}
        </StyledMenuButton>
      </StyledAppBar>

      {/* Sidebar Nav */}
      <StyledDrawer
        variant="permanent"
        sx={{
          display: { xs: "none", lg: "block" },
          "& .MuiDrawer-paper": {
            width: DRAWER_WIDTH,
            backgroundColor: theme.palette.colors.indigo,
            padding: "16px",
          },
        }}
        open
      >
        <StyledLogo
          src={logo}
          alt="Talkspace logo"
          sx={{
            width: "115px",
            margin: "16px 24px 16px 16px",
          }}
        />
        {drawer}
      </StyledDrawer>
      <LogOutDialog
        open={logOutDialogOpen}
        onConfirm={logOutConfirmed}
        onClose={closeLogOutDialog}
        loading={logOutLoading}
      />
      <ProfileDialog open={profileDialogOpen} onClose={closeProfileDialog} />
    </>
  );
}

export default DrawerNavigation;

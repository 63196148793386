/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import { createContext, useMemo } from "react";
import { loader } from "graphql.macro";
import PropTypes from "prop-types";
import { useLazyQuery, useMutation } from "@apollo/client";

const GetProfile = loader("../gql/user/queries/getProfile.gql");
const RequestMagicLink = loader("../gql/user/mutations/requestMagicLink.gql");
const SignIn = loader("../gql/user/mutations/signIn.gql");
const LogOut = loader("../gql/user/mutations/logOut.gql");
const UpdateProfile = loader("../gql/user/mutations/updateProfile.gql");

const AuthContext = createContext({
  user: null,
  isLoggedIn: false,
  requestMagicLink: null,
  signIn: null,
  getProfile: null,
  logOut: null,
  readProfile: null,
  updateProfile: null,
});

export default function AuthProvider({ client, children }) {
  const user = client.readQuery({ query: GetProfile });
  const requestMagicLinkMutation = useMutation(RequestMagicLink);
  const signInMutation = useMutation(SignIn);
  const getProfileQuery = useLazyQuery(GetProfile);
  const logOutMutation = useMutation(LogOut, {
    onCompleted: () => {
      client.resetStore();
    },
  });
  const updateProfileMutation = useMutation(UpdateProfile, {
    onCompleted: (data) => {
      client.cache.writeQuery({
        query: GetProfile,
        data: {
          profile: { ...user.profile, ...data.updateProfile.profile },
        },
      });
    },
  });

  const value = useMemo(
    () => ({
      user,
      isLoggedIn: !!user,
      requestMagicLink: requestMagicLinkMutation,
      signIn: signInMutation,
      getProfile: getProfileQuery,
      logOut: logOutMutation,
      updateProfile: updateProfileMutation,
    }),
    [
      getProfileQuery,
      logOutMutation,
      requestMagicLinkMutation,
      signInMutation,
      updateProfileMutation,
      user,
    ]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
  client: PropTypes.shape({
    readQuery: PropTypes.func,
    resetStore: PropTypes.func,
    cache: PropTypes.shape({
      writeQuery: PropTypes.func,
    }),
  }).isRequired,
};

export { AuthContext };

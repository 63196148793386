/* eslint-disable react/jsx-props-no-spreading */
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";

function TSBox({ children, ...props }) {
  return <Box {...props}>{children}</Box>;
}

TSBox.propTypes = {
  children: PropTypes.node.isRequired,
};

export default TSBox;

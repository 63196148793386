/* eslint-disable react/jsx-props-no-spreading */
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import styled from "@emotion/styled";
import React from "react";
import PropTypes from "prop-types";
import IconButton, { iconButtonClasses } from "@mui/material/IconButton";

import mq from "../Util/mediaQueries";

const StyledIconButton = styled(IconButton)(({ theme }) =>
  mq({
    width: 32,
    height: 32,
    padding: 0,
    borderRadius: 10,
    border: "2px solid transparent",
    backgroundColor: "transparent",
    color: theme.palette.colors.black100,
    transition: "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1)",
    "&:hover": {
      backgroundColor: theme.palette.colors.gray50,
    },
    "&:active": {
      backgroundColor: theme.palette.colors.gray100,
    },
    [`&.${iconButtonClasses.disabled}`]: {
      color: theme.palette.colors.gray200,
      img: {
        filter:
          "invert(95%) sepia(2%) saturate(1910%) hue-rotate(187deg) brightness(92%) contrast(91%)",
      },
    },
    "&.Mui-focusVisible": {
      border: "2px solid #076DD1",
    },
  })
);

const TSIconButton = React.forwardRef(({ children, ...props }, ref) => (
  <StyledIconButton disableRipple ref={ref} {...props}>
    {children}
  </StyledIconButton>
));

TSIconButton.propTypes = {
  children: PropTypes.node.isRequired,
};

export default TSIconButton;
export { iconButtonClasses };

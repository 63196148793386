/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import styled from "@emotion/styled";
import PropTypes from "prop-types";
import Footer from "./Footer";
import mq from "../Util/mediaQueries";
import logo from "../assets/talkspace-logo.svg";
import headGraphic from "../assets/head-with-heart.svg";

const StyledMain = styled("main")(({ theme }) =>
  mq({
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    width: "100%",
    backgroundColor: theme.palette.primary.dark,
    ".head-graphic": {
      marginTop: theme.spacing(8),
    },
    ".logo": {
      marginTop: 19,
    },
    ".container": {
      width: "100%",
      alignSelf: "center",
      flexGrow: 1,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      margin: "auto",
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
    ".footer": {
      marginTop: 40,
    },
  })
);

export default function LoggedOutLayout({ children }) {
  return (
    <StyledMain>
      <section className="container">
        <img
          className="head-graphic"
          src={headGraphic}
          alt="logo"
          width="50"
          height="54"
        />
        <img
          className="logo"
          src={logo}
          alt="Talkspace logo"
          width="100"
          height="28"
        />
        {children}
      </section>
      <Footer className="footer" />
    </StyledMain>
  );
}

LoggedOutLayout.propTypes = {
  children: PropTypes.node,
};

LoggedOutLayout.defaultProps = {
  children: null,
};

export default function copyToClipboard(text) {
  if (navigator && navigator.clipboard) {
    navigator.clipboard.writeText(text);
    return true;
  }
  if (window.clipboradData) {
    window.clipboardData.setData("Text", text);
    return true;
  }
  return false;
}

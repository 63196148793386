/* eslint-disable react/jsx-props-no-spreading */
/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import styled from "@emotion/styled";
import PropTypes from "prop-types";
import Dialog, { dialogClasses } from "@mui/material/Dialog";
import DialogActions, {
  dialogActionsClasses,
} from "@mui/material/DialogActions";
import DialogContent, {
  dialogContentClasses,
} from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import IconButton from "./IconButton";
import closeIcon from "../assets/close.svg";
import mq from "../Util/mediaQueries";

const closeButtonClass = "close-button";

const StyledDialog = styled(Dialog)(({ theme }) =>
  mq({
    textAlign: "center",
    [`.${dialogClasses.paper}`]: {
      borderRadius: 10,
    },
    [`.${dialogClasses.container}`]: {
      position: "relative",
    },
    [`.${closeButtonClass}`]: {
      alignSelf: "flex-end",
      top: theme.spacing(2),
      right: theme.spacing(2),
      position: "absolute",
      color: theme.palette.text.primary,
      zIndex: 1,
    },
    [`.${dialogClasses.paperFullScreen}`]: {
      borderRadius: 0,
    },
  })
);

function TSDialog({
  title,
  text,
  content,
  actions,
  open,
  onClose,
  showClose = true,
  icon,
  ...props
}) {
  return (
    <StyledDialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      {...props}
    >
      {showClose && (
        <IconButton className={closeButtonClass} onClick={onClose}>
          <img src={closeIcon} width="24" height="24" alt="Close" />
        </IconButton>
      )}
      {icon && (
        <div
          css={css({
            alignSelf: "center",
          })}
        >
          {icon}
        </div>
      )}
      {title && <DialogTitle id="alert-dialog-title">{title}</DialogTitle>}
      <DialogContent>
        {content}
        {text && (
          <DialogContentText id="alert-dialog-description">
            {text}
          </DialogContentText>
        )}
      </DialogContent>
      <DialogActions sx={{ justifyContent: "space-between", padding: 0 }}>
        {actions}
      </DialogActions>
    </StyledDialog>
  );
}

TSDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  showClose: PropTypes.bool,
  icon: PropTypes.node,
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  text: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  content: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  actions: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  onClose: PropTypes.func,
};

TSDialog.defaultProps = {
  showClose: true,
  icon: null,
  title: null,
  text: null,
  content: null,
  actions: null,
  onClose: () => {},
};

const classes = { closeButton: closeButtonClass, ...dialogClasses };

export default TSDialog;
export { classes as dialogClasses, dialogContentClasses, dialogActionsClasses };

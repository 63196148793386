/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import Typography from "./Typography";
import Link from "./Link";
import Box from "./Box";
import mq from "../Util/mediaQueries";

const StyledFooter = styled(Box)(({ theme }) =>
  mq({
    width: "100%",
    height: ["auto", null, 64],
    paddingTop: [4, null, 0],
    paddingBottom: [theme.spacing(3), null, 0],
    alignSelf: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    backgroundColor: theme.palette.colors.white,
    ".container": {
      display: "flex",
      flexDirection: ["column", null, "row"],
      alignItems: "center",
      justifyContent: "space-between",
      width: "100%",
      maxWidth: 565,
      fontSize: 12,
      alignSelf: "center",
      paddingLeft: [0, null, theme.spacing(3), 0],
      paddingRight: [0, null, theme.spacing(3), 0],
    },
  })
);

const StyledLink = styled(Link)(({ theme }) =>
  mq({
    color: theme.palette.colors.tsBlack,
    marginTop: [20, null, 0],
  })
);

function FooterLink({ href, children }) {
  return (
    <StyledLink underline="hover" target="_blank" href={href}>
      {children}
    </StyledLink>
  );
}

FooterLink.propTypes = {
  href: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default function Footer({ className }) {
  return (
    <StyledFooter component="footer" className={className}>
      <Typography variant="caption" component={Box} className="container">
        <FooterLink href="https://www.talkspace.com">
          &copy; 2022 Talkspace
        </FooterLink>
        <FooterLink href="https://www.talkspace.com/public/terms">
          Terms of Service
        </FooterLink>
        <FooterLink href="https://www.talkspace.com/public/privacy-policy">
          Privacy Policy
        </FooterLink>
        <FooterLink href="mailto:lmh@getlasting.zendesk.com">
          Contact Support
        </FooterLink>
      </Typography>
    </StyledFooter>
  );
}

Footer.propTypes = {
  className: PropTypes.string,
};

Footer.defaultProps = {
  className: null,
};

function formatFileSize(bytes) {
  // eslint-disable-next-line no-bitwise
  const exp = (Math.log(bytes) / Math.log(1024)) | 0;
  const result = (bytes / 1024 ** exp).toFixed(2);
  const units = exp === 0 ? "bytes" : `${"KMGTPEZY"[exp - 1]}B`;

  return `${result} ${units}`;
}

export default formatFileSize;

/* eslint-disable react/jsx-props-no-spreading */
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import Popper from "@mui/material/Popper";

function TSPopper({ ...props }) {
  return <Popper {...props} />;
}

export default TSPopper;

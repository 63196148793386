/* eslint-disable react/jsx-props-no-spreading */
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import PropTypes from "prop-types";
import Snackbar, { snackbarClasses } from "@mui/material/Snackbar";

export default function TSSnackbar({ children, ...props }) {
  return (
    <Snackbar
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      autoHideDuration={3000}
      {...props}
    >
      {children}
    </Snackbar>
  );
}

TSSnackbar.propTypes = {
  children: PropTypes.node,
};

TSSnackbar.defaultProps = {
  children: null,
};

export { snackbarClasses };

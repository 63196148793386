/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
// eslint-disable-next-line no-unused-vars
import React, { useState } from "react";
import styled from "@emotion/styled";
import useMediaQuery from "@mui/material/useMediaQuery";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

import LoggedInLayout, { Header, Body } from "../Components/LoggedInLayout";
import PageHeader from "../Components/PageHeader";
import Grid from "../Components/Grid";
import ResourceAbbreviatedCard from "../Components/ResourceAbbreviatedCard";
import ResourcesRecentSection from "../Components/ResourcesRecentSection";
import ResourceDetailDialog from "../Components/ResourceDetailDialog";
import Typography from "../Components/Typography";
import Box from "../Components/Box";
import mq from "../Util/mediaQueries";
import useResources from "../hooks/useResources";

dayjs.extend(customParseFormat);

const StyledContainer = styled.section(({ theme }) =>
  mq({
    ".all-resources": {
      marginTop: theme.spacing(5),
      ".card": {
        height: [100, null, 158, "100%"],
      },
    },
  })
);

const StyledSectionTitle = styled(Typography)(({ theme }) =>
  mq({
    marginBottom: theme.spacing(3),
  })
);

function ResourcesPage() {
  const lessThanMd = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const { data, loading } = useResources();
  const [currentResource, setCurrentResource] = useState(null);

  let loadedResources =
    (data && data.fileResources && [...data.fileResources]) || [];
  let recentResources = [...loadedResources];

  recentResources.sort((a, b) => {
    const aDay = dayjs(a.createdAt, "YYYY-MM-DD H:mm:ss Z");
    const bDay = dayjs(b.createdAt, "YYYY-MM-DD H:mm:ss Z");
    const aBeforeB = aDay.isBefore(bDay);
    const aAfterB = aDay.isAfter(bDay);
    if (aBeforeB) return 1;
    if (aAfterB) return -1;
    return 0;
  });

  if (!loading) {
    loadedResources.sort((a, b) =>
      a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1
    );
    recentResources.sort((a, b) => a.createdAt > b.createdAt);
    recentResources = recentResources.slice(0, 3);
  } else {
    loadedResources = Array.from(Array(24).keys()).map((index) => ({
      title: `placeholder ${index}`,
      fileUrl: `url: ${Math.floor(Math.random() * 1000)}`,
    }));
    recentResources = Array.from(Array(3).keys()).map((index) => ({
      title: `placeholder ${index}`,
      fileUrl: `url: ${Math.floor(Math.random() * 1000)}`,
    }));
  }

  const onModalOpen = (resource) => {
    if (resource) {
      setCurrentResource(resource);
    }
  };

  const onModalClose = () => {
    setCurrentResource(null);
  };

  const allResourcesContent =
    loading || loadedResources.length ? (
      <Grid
        className="grid"
        container
        rowSpacing={{ xs: 4, lg: 0 }}
        columnSpacing={4}
      >
        {loadedResources.map((resource) => (
          <Grid item xs={12} md={3} key={`${resource.title} ${resource.url}`}>
            <ResourceAbbreviatedCard
              orientation={lessThanMd ? "row" : "column"}
              className="card"
              loading={loading}
              resource={resource}
              onClick={onModalOpen}
            />
          </Grid>
        ))}
      </Grid>
    ) : (
      <Typography variant="subheader">No available resources</Typography>
    );
  return (
    <LoggedInLayout>
      <Header>
        <PageHeader
          title="Resource Library"
          subtitle="Browse our library of therapist-written resources, then send them to your people."
        />
      </Header>
      <Body>
        <StyledContainer>
          <ResourcesRecentSection
            className="recently-added"
            loading={loading}
            resources={recentResources}
            onCardClick={onModalOpen}
          />
          <Box className="all-resources">
            <StyledSectionTitle variant="small">
              Browse all resources
            </StyledSectionTitle>
            {allResourcesContent}
          </Box>
        </StyledContainer>
        {currentResource && (
          <ResourceDetailDialog
            open
            onClose={onModalClose}
            resource={currentResource}
          />
        )}
      </Body>
    </LoggedInLayout>
  );
}

export default ResourcesPage;

/* eslint-disable react/jsx-props-no-spreading */
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from "@emotion/react";
import PropTypes from "prop-types";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "./IconButton";

function TSInputAdornment({ icon, onClick, alt = "", children, ...props }) {
  return (
    <InputAdornment position="end" {...props}>
      {children}
      {!children && (
        <IconButton onClick={onClick}>
          <img
            width="24"
            height="24"
            css={css({
              cursor: "pointer",
            })}
            src={icon}
            alt={alt}
          />
        </IconButton>
      )}
    </InputAdornment>
  );
}

TSInputAdornment.propTypes = {
  icon: PropTypes.string,
  onClick: PropTypes.func,
  alt: PropTypes.string,
  children: PropTypes.node,
};

TSInputAdornment.defaultProps = {
  icon: null,
  onClick: () => {},
  alt: "",
  children: null,
};

export default TSInputAdornment;

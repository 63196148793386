/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import styled from "@emotion/styled";
import PropTypes from "prop-types";

import Typography from "./Typography";
import InfoTooltip from "./InfoTooltip";
import TSCard from "./Card";
import TSSkeleton from "./Skeleton";
import mq from "../Util/mediaQueries";
import withHideOnScreenshot from "./withHideOnScreenshot";

const Tooltip = withHideOnScreenshot(InfoTooltip);

const StyledCard = styled(TSCard)(({ theme }) =>
  mq({
    position: "relative",
    padding: `50px ${theme.spacing(3)} ${theme.spacing(4)}`,
    minHeight: 555,
    height: "100%",
  })
);

const StyledTooltipContainer = styled.div(({ theme }) =>
  mq({
    position: "absolute",
    right: 8,
    top: 8,
    ".icon": {
      color: theme.palette.colors.gray800,
    },
  })
);

const StyledFeaturedValue = styled(Typography)(({ theme }) =>
  mq({
    marginTop: theme.spacing(2),
  })
);

const StyledChart = styled.div(({ theme }) =>
  mq({
    marginTop: theme.spacing(4),
    width: "100%",
  })
);

const StyledChartData = styled.div(() =>
  mq({
    width: "100%",
  })
);

const StyledChartHeaderRow = styled.div(({ theme }) =>
  mq({
    display: "flex",
    justifyContent: "space-between",
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    marginBottom: theme.spacing(2),
  })
);

const StyledChartRow = styled(StyledChartHeaderRow)(({ theme }) =>
  mq({
    backgroundColor: theme.palette.colors.accessibilityGreen50,
    marginTop: theme.spacing(0.5),
    height: 51,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: `0 ${theme.spacing(2)}`,
    borderRadius: 5,
    marginBottom: theme.spacing(0.5),
  })
);

const StyledChartHeaderCell = styled(Typography)(({ theme }) =>
  mq({
    color: theme.palette.colors.gray600,
    textTransform: "uppercase",
  })
);

const StyledChartCell = styled(Typography)(({ theme }) =>
  mq({
    color: theme.palette.colors.tsBlack,
  })
);

const StyledHeaderContainer = styled.div(() =>
  mq({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  })
);

const StyledIconWrapper = styled.div(() =>
  mq({
    height: 48,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  })
);

const MetricSkeleton = styled(TSSkeleton)(({ theme }) =>
  mq({
    width: 71,
    borderRadius: 5,
    backgroundColor: theme.palette.colors.gray200,
  })
);

const ChartLabelSkeleton = styled(TSSkeleton)(({ theme }) =>
  mq({
    height: 12,
    width: 149,
    borderRadius: 5,
    backgroundColor: theme.palette.colors.gray100,
  })
);

const ChartValueSkeleton = styled(ChartLabelSkeleton)(() =>
  mq({
    width: 27,
  })
);

const StyledBlockedContentText = styled(Typography)(({ theme }) =>
  mq({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    backgroundColor: theme.palette.colors.accessibilityGreen50,
    height: 269,
    padding: "0 53px 25px",
    borderRadius: 5,
    color: theme.palette.colors.gray800,
    textAlign: "center",
  })
);

const StyledBlockedContentContainer = styled.div(() => mq({}));

function DashboardChartContainer({
  chartTitle,
  metricKeyName,
  metricValueName,
  metricIcon,
  count,
  rows,
  loading,
  blockedMessage,
  tooltip,
}) {
  const hasData = count > 0 && rows && rows.length;
  const displayedCount = hasData ? count : 0;

  return (
    <StyledCard>
      <StyledTooltipContainer>
        <Tooltip
          body={tooltip}
          title={metricValueName}
          iconProps={{ className: "icon", fontSize: "small" }}
        />
      </StyledTooltipContainer>
      <StyledHeaderContainer>
        {!loading ? <StyledIconWrapper>{metricIcon}</StyledIconWrapper> : null}
        <Typography variant="heading">{chartTitle}</Typography>
        <StyledFeaturedValue variant="xlarge">
          {loading ? <MetricSkeleton /> : displayedCount}
        </StyledFeaturedValue>
      </StyledHeaderContainer>
      <Chart
        rows={rows}
        metricKeyName={metricKeyName}
        metricValueName={metricValueName}
        loading={loading}
        blockedMessage={blockedMessage}
      />
    </StyledCard>
  );
}

function Chart({
  rows,
  metricValueName,
  metricKeyName,
  loading,
  blockedMessage,
}) {
  return (
    <StyledChart>
      <ChartData
        rows={rows}
        metricValueName={metricValueName}
        metricKeyName={metricKeyName}
        loading={loading}
        blockedMessage={blockedMessage}
      />
    </StyledChart>
  );
}

function ChartData({
  rows,
  metricValueName,
  metricKeyName,
  loading,
  blockedMessage,
}) {
  if (loading) {
    return (
      <LoadingChartData
        metricValueName={metricValueName}
        metricKeyName={metricKeyName}
      />
    );
  }
  if (!rows || !rows.length) {
    return (
      <BlockedChartData
        metricValueName={metricValueName}
        metricKeyName={metricKeyName}
        message={blockedMessage}
      />
    );
  }
  return (
    <PopulatedChartData
      metricValueName={metricValueName}
      metricKeyName={metricKeyName}
      rows={rows}
    />
  );
}

function PopulatedChartData({ rows, metricValueName, metricKeyName }) {
  return (
    <StyledChartData>
      <StyledChartHeaderRow>
        <StyledChartHeaderCell variant="subheading">
          {metricKeyName}
        </StyledChartHeaderCell>
        <StyledChartHeaderCell variant="subheading">
          {metricValueName}
        </StyledChartHeaderCell>
      </StyledChartHeaderRow>
      {rows !== null && rows !== undefined ? (
        rows.map((row) => (
          <StyledChartRow key={`class_${row.title}`}>
            <StyledChartCell variant="body">{row.title}</StyledChartCell>
            <StyledChartCell variant="body">{row.count}</StyledChartCell>
          </StyledChartRow>
        ))
      ) : (
        <div>Nope</div>
      )}
    </StyledChartData>
  );
}

function LoadingChartData({ metricValueName, metricKeyName }) {
  return (
    <StyledChartData>
      <StyledChartHeaderRow>
        <StyledChartHeaderCell variant="subheading">
          {metricKeyName}
        </StyledChartHeaderCell>
        <StyledChartHeaderCell variant="subheading">
          {metricValueName}
        </StyledChartHeaderCell>
      </StyledChartHeaderRow>
      {Array(5)
        .fill()
        .map((_, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <StyledChartRow key={`class_${i}`}>
            <StyledChartCell variant="body">
              <ChartLabelSkeleton
                css={mq({
                  margin: "0px",
                })}
              />
            </StyledChartCell>
            <StyledChartCell variant="body">
              <ChartValueSkeleton
                css={mq({
                  margin: "0px",
                })}
              />
            </StyledChartCell>
          </StyledChartRow>
        ))}
    </StyledChartData>
  );
}

function BlockedChartData({ metricKeyName, metricValueName, message }) {
  return (
    <StyledBlockedContentContainer>
      <StyledChartHeaderRow>
        <StyledChartHeaderCell variant="subheading">
          {metricKeyName}
        </StyledChartHeaderCell>
        <StyledChartHeaderCell variant="subheading">
          {metricValueName}
        </StyledChartHeaderCell>
      </StyledChartHeaderRow>
      <StyledBlockedContentText variant="body">
        {message}
      </StyledBlockedContentText>
    </StyledBlockedContentContainer>
  );
}

DashboardChartContainer.propTypes = {
  chartTitle: PropTypes.string.isRequired,
  metricKeyName: PropTypes.string.isRequired,
  metricValueName: PropTypes.string.isRequired,
  metricIcon: PropTypes.element.isRequired,
  count: PropTypes.number,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      count: PropTypes.number,
    })
  ),
  loading: PropTypes.bool.isRequired,
  blockedMessage: PropTypes.string.isRequired,
  tooltip: PropTypes.string.isRequired,
};

DashboardChartContainer.defaultProps = {
  rows: null,
  count: null,
};

Chart.propTypes = {
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      count: PropTypes.number,
    })
  ),
  metricKeyName: PropTypes.string.isRequired,
  metricValueName: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  blockedMessage: PropTypes.string.isRequired,
};

Chart.defaultProps = {
  rows: null,
};

ChartData.propTypes = {
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      count: PropTypes.number,
    })
  ),
  metricKeyName: PropTypes.string.isRequired,
  metricValueName: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  blockedMessage: PropTypes.string.isRequired,
};

ChartData.defaultProps = {
  rows: null,
};

PopulatedChartData.propTypes = {
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      count: PropTypes.number,
    })
  ).isRequired,
  metricKeyName: PropTypes.string.isRequired,
  metricValueName: PropTypes.string.isRequired,
};

LoadingChartData.propTypes = {
  metricKeyName: PropTypes.string.isRequired,
  metricValueName: PropTypes.string.isRequired,
};

BlockedChartData.propTypes = {
  message: PropTypes.string.isRequired,
  metricKeyName: PropTypes.string.isRequired,
  metricValueName: PropTypes.string.isRequired,
};

export default DashboardChartContainer;

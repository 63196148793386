/* eslint-disable react/jsx-props-no-spreading */
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
// import React from "react";
import PropTypes from "prop-types";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

function TSTooltip({ children, ...props }) {
  return <Tooltip {...props}>{children}</Tooltip>;
}

TSTooltip.propTypes = {
  children: PropTypes.node.isRequired,
};

export default TSTooltip;
export { tooltipClasses };

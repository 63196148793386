/* eslint-disable react/jsx-props-no-spreading */
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import PropTypes from "prop-types";
import classNames from "classnames";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import Tooltip from "./ClickableTooltip";

export default function InfoTooltip({
  className,
  title,
  body,
  iconProps,
  tooltipProps,
}) {
  return (
    <div className={classNames("info-tooltip", className)}>
      <Tooltip body={body} title={title} {...tooltipProps}>
        <InfoIcon {...iconProps} />
      </Tooltip>
    </div>
  );
}

InfoTooltip.propTypes = {
  className: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  body: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  // eslint-disable-next-line react/forbid-prop-types
  iconProps: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  tooltipProps: PropTypes.object,
};

InfoTooltip.defaultProps = {
  className: null,
  title: null,
  body: null,
  iconProps: null,
  tooltipProps: null,
};

/* eslint-disable react/jsx-props-no-spreading */
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import React from "react";
import PropTypes from "prop-types";
import tracking from "../services/tracking";

const withClickTracking = (Component) => {
  const ClickTrackedComponent = React.forwardRef(
    ({ onClick, eventName, eventProps, ...props }, ref) => {
      const clickHandler = !eventName
        ? onClick
        : (e) => {
            tracking.track(eventName, eventProps);
            if (onClick) onClick(e);
          };
      return <Component ref={ref} onClick={clickHandler} {...props} />;
    }
  );

  ClickTrackedComponent.displayName = "ClickTrackedComponent";

  ClickTrackedComponent.propTypes = {
    onClick: PropTypes.func,
    eventName: PropTypes.string.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    eventProps: PropTypes.object,
  };

  ClickTrackedComponent.defaultProps = {
    onClick: null,
    eventProps: null,
  };

  return ClickTrackedComponent;
};

export default withClickTracking;

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import styled from "@emotion/styled";
import PropTypes from "prop-types";
import classNames from "classnames";
import useMediaQuery from "@mui/material/useMediaQuery";

import Grid from "./Grid";
import ResourceAbbreviatedCard, {
  resourceAbbreviatedCardClasses,
} from "./ResourceAbbreviatedCard";
import ResourceCard from "./ResourceCard";
import ResourceCarousel from "./ResourceCarousel";
import Typography from "./Typography";
import Box from "./Box";
import mq from "../Util/mediaQueries";

const StyledContainer = styled(Box)(({ theme }) =>
  mq({
    "&.resources-recently-added": {
      ".grid": {
        ".card": {
          // width: "100%",
          width: [null, null, null, 772],
          maxWidth: [null, null, "100%"],
        },
      },
      ".carousel": {
        marginRight: [theme.spacing(-2), null, theme.spacing(-5)],
        ".card": {
          height: "100%",
          width: 236,
          marginRight: theme.spacing(4),
          [`.${resourceAbbreviatedCardClasses.actionArea}`]: {
            flexDirection: "column",
          },
        },
      },
    },
  })
);

const StyledSectionTitle = styled(Typography)(({ theme }) =>
  mq({
    marginBottom: theme.spacing(3),
  })
);

function ResourcesRecentSection({
  resources,
  className,
  loading,
  onCardClick,
}) {
  const showSlideshow = useMediaQuery((theme) => theme.breakpoints.down("md"));

  let content = null;

  if (!loading && !resources.length)
    content = <Typography variant="subheader">No recent resources</Typography>;
  else if (showSlideshow)
    content = (
      <section className="carousel">
        <ResourceCarousel itemWidth="268" disabled={loading}>
          {resources.map((resource) => (
            <ResourceAbbreviatedCard
              key={`${resource.title} ${resource.fileUrl}`}
              orientation="column"
              className="card"
              loading={loading}
              resource={resource}
              onClick={onCardClick}
            />
          ))}
        </ResourceCarousel>
      </section>
    );
  else
    content = (
      <Grid className="grid" container rowSpacing={{ xs: 3 }}>
        {resources.map((resource) => (
          <Grid
            item
            xs={4}
            md={12}
            key={`full-${resource.title} ${resource.fileUrl}`}
          >
            <Box>
              <ResourceCard
                className="card"
                loading={loading}
                resource={resource}
                onClick={onCardClick}
              />
            </Box>
          </Grid>
        ))}
      </Grid>
    );

  return (
    <StyledContainer
      className={classNames("resources-recently-added", className)}
    >
      <section className="container">
        <StyledSectionTitle variant="medium">Recently added</StyledSectionTitle>
        {content}
      </section>
    </StyledContainer>
  );
}

ResourcesRecentSection.propTypes = {
  className: PropTypes.string,
  onCardClick: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  resources: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      fileUrl: PropTypes.string.isRequired,
    }).isRequired
  ),
};

ResourcesRecentSection.defaultProps = {
  className: null,
  resources: [],
};

export default ResourcesRecentSection;

/* eslint-disable react/jsx-props-no-spreading */
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import styled from "@emotion/styled";
import React from "react";
import PropTypes from "prop-types";
import TextField, { textFieldClasses } from "@mui/material/TextField";

import mq from "../Util/mediaQueries";

const StyledTextField = styled(TextField)(({ theme }) =>
  mq({
    ".Mui-disabled": {
      color: theme.palette.colors.tsBlack,
      WebkitTextFillColor: theme.palette.colors.tsBlack,
    },
    [`&.${textFieldClasses.root}`]: {
      "& .MuiOutlinedInput-root": {
        input: {
          fontSize: 14,
        },
        "&.Mui-focused fieldset": {
          borderWidth: 1,
        },
        "&:not(.Mui-error):not(.Mui-focused) fieldset": {
          borderColor: theme.palette.colors.gray100,
        },
      },
      ".MuiInputAdornment-root": {
        color: theme.palette.colors.gray800,
        svg: {
          fill: theme.palette.colors.gray800,
        },
      },
    },
  })
);

const TSTextField = React.forwardRef(
  (
    {
      autoFocus,
      value,
      label,
      error,
      helperText,
      onChange,
      onFocus,
      onBlur,
      endAdornment,
      ...props
    },
    ref
  ) => (
    <StyledTextField
      inputRef={ref}
      autoFocus={autoFocus}
      label={label}
      error={error}
      helperText={helperText}
      variant="outlined"
      fullWidth
      onChange={onChange}
      onFocus={onFocus}
      onBlur={onBlur}
      value={value}
      InputProps={endAdornment ? { endAdornment } : null}
      {...props}
    />
  )
);

TSTextField.propTypes = {
  autoFocus: PropTypes.bool,
  value: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  endAdornment: PropTypes.node,
};
export default TSTextField;
export { textFieldClasses };

import { v4 as uuidv4 } from "uuid";

const formatDate = (date) =>
  date ? new Date(date).toISOString().replace(/-|:|\.\d+/g, "") : null;

const generateICSBlob = ({
  startTime,
  endTime,
  location,
  summary,
  description,
  product,
}) =>
  `BEGIN:VCALENDAR\nVERSION:2.0\nBEGIN:VEVENT` +
  `\nUID:${uuidv4()}` +
  `\nDTSTART:${formatDate(startTime)}` +
  `\nDTEND:${formatDate(endTime)}` +
  `\nLOCATION:${location}` +
  `\nDTSTAMP:${formatDate(new Date().getTime())}` +
  `\nPRODID:${product}` +
  `\nDESCRIPTION:${description}` +
  `\nSUMMARY:${summary}\nEND:VEVENT\nEND:VCALENDAR`;

export default generateICSBlob;

import { loader } from "graphql.macro";
import { useQuery } from "@apollo/client";

const GetOrganization = loader(
  "../gql/organization/queries/getOrganization.gql"
);

function useOrganization() {
  const { data, error, loading, refetch } = useQuery(GetOrganization);

  return {
    ...data?.organization,
    loading,
    error,
    refetch,
  };
}

export default useOrganization;

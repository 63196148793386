/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import styled from "@emotion/styled";
// eslint-disable-next-line no-unused-vars
import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import classNames from "classnames";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import IosShareIcon from "@mui/icons-material/IosShare";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";

import Card from "./Card";
import Button from "./Button";
import DecoratedIconButton from "./DecoratedIconButton";
import Skeleton from "./Skeleton";
import ClassCategoryChip from "./ClassCategoryChip";
import ClassDetailDialog from "./ClassDetailDialog";
import Typography from "./Typography";
import withAccessibleOnClick from "./withAccessibleOnClick";
import mq from "../Util/mediaQueries";
import { generateClassShareLink } from "../Util/liveClasses";
import withClickTracking from "./withClickTracking";

const AccessiblyClickableCard = withAccessibleOnClick(Card);

const classes = {
  hostImg: "host-img",
  title: "card-title",
  content: "card-content",
};

const StyledCard = styled(AccessiblyClickableCard)(({ theme }) =>
  mq({
    display: "flex",
    flexDirection: "row",
    alignContent: "stretch",
    border: "none",
    position: "relative",
    transition: "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1)",
    "&.loaded": {
      cursor: "pointer",
      "&:hover": {
        backgroundColor: theme.palette.colors.gray50,
        ".icons": {
          display: ["none", null, null, "block"],
        },
      },
    },
    "&.show-icons": {
      [`.${classes.title}`]: {
        paddingRight: [0, null, null, 120],
      },
    },
    [`.${classes.hostImg}`]: {
      alignSelf: "stretch",
      ".wrapper": {
        display: "block",
        backgroundSize: "auto 100%",
        backgroundPosition: "center",
        width: "100%",
        height: "100%",
        borderRadius: 10,
        backgroundColor: theme.palette.colors.gray800,
      },
      img: {
        maxWidth: "100%",
        maxHeight: "100%",
        display: "block",
      },
      ".skeleton": {
        marginTop: 0,
      },
    },
    [`.${classes.title}`]: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      WebkitLineClamp: "2" /* number of lines to show */,
      lineClamp: "2",
      WebkitBoxOrient: "vertical",
    },
    ".icons": {
      position: "absolute",
      right: 24,
      top: 24,
      display: "none",
      ".icon-btn": {
        "&.share": {
          marginLeft: theme.spacing(1),
        },
        ".icon.share": {
          marginBottom: 3,
        },
      },
    },
    [`.${classes.content}`]: {
      height: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      flex: 1,
      maxWidth: 680,
      position: "relative",
      ".host": {
        color: theme.palette.colors.gray800,
        marginTop: 2,
      },
      ".start-time": {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginTop: theme.spacing(1),
        ".icon": {
          marginRight: 6,
          alignSelf: "flex-start",
          marginTop: 2,
          width: 14,
          height: 15,
          color: theme.palette.colors.gray600,
        },
        "&.today, &.today .icon": {
          color: theme.palette.colors.successText,
        },
      },
      ".category-chip": {
        cursor: "pointer",
        marginRight: theme.spacing(1),
        marginTop: theme.spacing(1),
      },
      ".description": {
        textAlign: "left",
        fontSize: 14,
        lineHeight: "20px",
        marginTop: theme.spacing(1),
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: ["none", null, "-webkit-box"],
        WebkitLineClamp: "2" /* number of lines to show */,
        lineClamp: "2",
        WebkitBoxOrient: "vertical",
        "&.truncated": {
          maskImage:
            "linear-gradient(to top, black 0%, black 0%), linear-gradient(to left, black 70%, transparent 100%)",
          maskPosition: "100% 100%, 100% 100%",
          maskSize: "100% 100%, 100px 18px",
          maskRepeat: "no-repeat",
          maskComposite: "xor",
        },
      },
      ".read-more-link": {
        fontSize: 14,
        fontWeight: 400,
        lineHeight: "14px",
        display: "inline-block",
        position: "absolute",
        height: "auto",
        paddingTop: 0,
        paddingBottom: 0,
        top: "calc(100% - 19px)",
        right: 0,
      },
      ".skeleton": {
        marginLeft: theme.spacing(2),
        marginTop: theme.spacing(1),
        borderRadius: 5,
        "&.title-skeleton": {
          height: 19,
          width: "25%",
        },
        "&.host-skeleton, &.start-time-skeleton": {
          height: 13,
          width: "20%",
        },
        "&.description-skeleton": {
          height: 13,
          width: "100%",
        },
        "&.categories-skeleton": {
          height: 26,
          width: 100,
        },
      },
    },
    "&.loading": {
      ".body": {
        justifyContent: "space-between",
      },
    },
  })
);

const TrackedIconButton = withClickTracking(DecoratedIconButton);

const eventIsToday = (startTime) => {
  const eventDate = dayjs(startTime);
  const now = dayjs();
  if (eventDate.format("MM/DD/YYYY") === now.format("MM/DD/YYYY")) return true;
  return false;
};

const formatDate = (startTime) => {
  const eventDate = dayjs(startTime);
  const now = dayjs();
  if (eventIsToday(startTime)) {
    const diffSeconds = eventDate.diff(now, "seconds");
    const hoursUntil = Math.floor(diffSeconds / 3600);
    const hoursUntilText = hoursUntil > 0 ? `${hoursUntil}hr ` : "";
    const minutesUntil = Math.floor((diffSeconds - hoursUntil * 3600) / 60);
    const minutesUntilText =
      hoursUntil > 0 || minutesUntil > 0 ? `${minutesUntil}min ` : "";
    const secondsUntil = Math.floor(
      diffSeconds - hoursUntil * 3600 - minutesUntil * 60
    );
    const secondsUntilText =
      hoursUntil > 0 || minutesUntil > 0 ? `${secondsUntil}s` : "";

    return `Today in ${hoursUntilText}${minutesUntilText}${secondsUntilText}`;
  }
  return `${eventDate.format("ddd, MMM D")} at ${eventDate.format("h:mm A")}`;
};

const isTextClamped = (elm) => elm.scrollHeight > elm.clientHeight;
function ClassCard({
  className,
  loading,
  classInfo,
  showCategories,
  showIcons,
  showDescription,
}) {
  const {
    title,
    description,
    hostName,
    thumbnailUrl,
    startTime,
    endTime,
    categories,
    liveLink,
    recordingLink,
    workshopId,
  } = classInfo;
  const [formattedDate, setFormattedDate] = useState(formatDate(startTime));
  const [isToday, setIsToday] = useState(eventIsToday(startTime));
  const [isDescriptionClamped, setIsDescriptionClamped] = useState();
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [shareLink, setShareLink] = useState(
    loading
      ? null
      : generateClassShareLink({
          title,
          description,
          startTime,
          endTime,
          liveLink,
          recordingLink,
        })
  );
  const descriptionRef = useRef();

  const openDetailModal = () => {
    setDialogOpen(true);
  };

  const onShare = (e) => {
    e.stopPropagation();
    window.open(shareLink);
  };

  const checkDescriptionTruncation = () => {
    if (description && descriptionRef.current) {
      setIsDescriptionClamped(isTextClamped(descriptionRef.current));
    } else {
      setIsDescriptionClamped(false);
    }
  };

  useEffect(() => {
    const updateStartDate = () => {
      setIsToday(eventIsToday(startTime));
      setFormattedDate(formatDate(startTime));
    };
    const interval = window.setInterval(updateStartDate, 1000);
    return () => window.clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(checkDescriptionTruncation);

  useEffect(() => {
    window.addEventListener("resize", checkDescriptionTruncation);
    return () => {
      window.removeEventListener("resize", checkDescriptionTruncation);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!loading && !shareLink) {
      setShareLink(
        generateClassShareLink({
          title,
          description,
          startTime,
          endTime,
          liveLink,
          recordingLink,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  return (
    <>
      <StyledCard
        className={classNames(
          "class-card",
          { loaded: !loading },
          { "show-icons": showIcons },
          className
        )}
        onClick={openDetailModal}
        tabIndex={loading ? -1 : 0}
      >
        {!loading && showIcons && (
          <div className="icons">
            <DecoratedIconButton
              tooltipText="More info"
              variant="outlined"
              className="icon-btn"
            >
              <InfoIcon className="icon" />
            </DecoratedIconButton>
            <TrackedIconButton
              tooltipText="Share"
              variant="outlined"
              className="icon-btn share"
              onClick={onShare}
              eventName="Resource Text Copied"
              eventProps={{
                Name: title,
                "Resource type": "workshop",
                "Resource id": workshopId,
              }}
            >
              <IosShareIcon className="icon share" />
            </TrackedIconButton>
          </div>
        )}
        {(loading || thumbnailUrl) && (
          <div className={classes.hostImg}>
            {loading && (
              <Skeleton
                className="skeleton"
                variant="rectangular"
                width="100%"
                height="100%"
              />
            )}
            {!loading && thumbnailUrl && (
              <div
                className="wrapper"
                style={{
                  backgroundImage: `url(${thumbnailUrl})`,
                }}
              />
            )}
          </div>
        )}
        <div className={classes.content}>
          {loading && (
            <Skeleton className="title-skeleton skeleton" variant="text" />
          )}
          {!loading && (
            <Typography variant="heading" className={classes.title}>
              {title}
            </Typography>
          )}

          {loading && (
            <Skeleton className="skeleton host-skeleton" variant="text" />
          )}
          {!loading && (
            <Typography
              variant="caption"
              align="left"
              component="div"
              className="host"
            >
              with {hostName}
            </Typography>
          )}
          {loading && (
            <Skeleton className="skeleton start-time-skeleton" variant="text" />
          )}
          {!loading && (
            <Typography
              variant="body"
              className={classNames("start-time", { today: isToday })}
            >
              <CalendarTodayOutlinedIcon className="icon" />
              {formattedDate}
            </Typography>
          )}
          {loading && showCategories && (
            <Skeleton className="skeleton categories-skeleton" variant="text" />
          )}
          {!loading && showCategories && categories && categories.length && (
            <div className="categories">
              {categories.map((category) => (
                <ClassCategoryChip
                  key={category.title}
                  className="category-chip"
                  name={category.title}
                />
              ))}
            </div>
          )}
          {loading && showDescription && (
            <>
              <Skeleton
                className="skeleton description-skeleton"
                variant="text"
              />
              <Skeleton
                className="skeleton description-skeleton"
                variant="text"
              />
            </>
          )}
          {!loading && showDescription && description && (
            <p
              className={classNames("description", {
                truncated: isDescriptionClamped,
              })}
              ref={descriptionRef}
            >
              {description}
            </p>
          )}
          {description && isDescriptionClamped && (
            <Button className="read-more-link" variant="link">
              read more
            </Button>
          )}
        </div>
      </StyledCard>
      {!loading && (
        <ClassDetailDialog
          classInfo={classInfo}
          open={isDialogOpen}
          onClose={() => setDialogOpen(false)}
        />
      )}
    </>
  );
}

ClassCard.propTypes = {
  className: PropTypes.string,
  loading: PropTypes.bool,
  classInfo: PropTypes.shape({
    title: PropTypes.string,
    startTime: PropTypes.string,
    endTime: PropTypes.string,
    hostName: PropTypes.string,
    categories: PropTypes.arrayOf(PropTypes.shape({ title: PropTypes.string })),
    description: PropTypes.string,
    thumbnailUrl: PropTypes.string,
    recordingLink: PropTypes.string,
    liveLink: PropTypes.string,
    workshopId: PropTypes.number,
  }),
  showCategories: PropTypes.bool,
  showIcons: PropTypes.bool,
  showDescription: PropTypes.bool,
};

ClassCard.defaultProps = {
  className: null,
  loading: false,
  classInfo: PropTypes.shape({
    title: null,
    startTime: null,
    endTime: null,
    hostName: null,
    categories: null,
    description: null,
    thumbnailUrl: null,
    recordingLink: null,
    liveLink: null,
    workshopId: null,
  }),
  showCategories: false,
  showIcons: false,
  showDescription: false,
};

export { classes };

export default ClassCard;

/* eslint-disable react/jsx-props-no-spreading */
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import styled from "@emotion/styled";
import PropTypes from "prop-types";
import { useFormContext, Controller } from "react-hook-form";
import TextField, { textFieldClasses } from "./TextField";
import InputAdornment from "./InputAdornment";
import clearIcon from "../assets/clear-icon.svg";
import mq from "../Util/mediaQueries";

const StyledTextField = styled(TextField)(() =>
  mq({
    ".clear-adornment": {
      opacity: 0,
      img: {
        width: 20,
        height: 20,
      },
    },
    [`&.${textFieldClasses.root}`]: {
      ".Mui-focused": {
        ".clear-adornment": {
          opacity: 1,
        },
      },
    },
  })
);

function FormTextField({
  name,
  rules,
  helperText,
  label,
  disabled = false,
  clearable = true,
  endAdornment,
  ...props
}) {
  const formContext = useFormContext();
  const { errors, loading } = formContext.methods.formState;
  const { control, setValue, trigger } = formContext.methods;
  const error = errors[name];
  let errorMessage = null;

  const clearValue = () => {
    setValue(name, "");
    trigger(name);
  };

  if (error) {
    const rule = rules[error.type];
    const value = typeof rule === "object" ? rule.value : rule;
    const message = typeof rule === "object" ? rule.message : null;
    if (message) errorMessage = message;
    else {
      switch (error.type) {
        case "required":
          errorMessage = `Enter a ${label.toLowerCase()}.`;
          break;
        case "minLength":
          errorMessage = `Enter a ${label.toLowerCase()} that has at least ${value} characters`;
          break;
        case "maxLength":
          errorMessage = `Enter a ${label.toLowerCase()} no longer than ${value} characters`;
          break;
        case "email":
          errorMessage = `Enter a valid email`;
          break;
        default:
          errorMessage = `Enter a valid ${label.toLowerCase()}`;
      }
    }
  }

  return (
    <Controller
      control={control}
      rules={rules}
      name={name}
      render={({ field: { onChange, onFocus, value } }) => (
        <StyledTextField
          onChange={onChange}
          onFocus={onFocus}
          value={value}
          error={!!error}
          label={label}
          disabled={disabled || loading}
          helperText={errorMessage || helperText}
          endAdornment={
            clearable && value ? (
              <InputAdornment
                className="clear-adornment"
                onClick={clearValue}
                icon={clearIcon}
                alt="clear"
              />
            ) : (
              endAdornment
            )
          }
          {...props}
        />
      )}
    />
  );
}

FormTextField.propTypes = {
  name: PropTypes.string.isRequired,
  helperText: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  clearable: PropTypes.bool,
  rules: PropTypes.shape({
    value: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
      PropTypes.bool,
    ]),
    message: PropTypes.string,
  }),
  endAdornment: PropTypes.node,
};

FormTextField.defaultProps = {
  helperText: null,
  label: null,
  disabled: false,
  clearable: true,
  rules: null,
  endAdornment: null,
};

export default FormTextField;

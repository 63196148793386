/* eslint-disable react/jsx-props-no-spreading */
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import React from "react";
import PropTypes from "prop-types";
import Drawer, { drawerClasses } from "@mui/material/Drawer";

const TSDrawer = React.forwardRef(({ children, ...props }, ref) => (
  <Drawer ref={ref} {...props}>
    {children}
  </Drawer>
));

TSDrawer.propTypes = {
  children: PropTypes.node.isRequired,
};

export default TSDrawer;
export { drawerClasses };

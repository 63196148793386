/* eslint-disable react/jsx-props-no-spreading */
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import CardMedia, { cardMediaClasses } from "@mui/material/CardMedia";

function TSCardMedia({ ...props }) {
  return <CardMedia {...props} />;
}

export default TSCardMedia;
export { cardMediaClasses };

/* eslint-disable react/jsx-props-no-spreading */
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import styled from "@emotion/styled";
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Button, { buttonClasses } from "@mui/material/Button";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import mq from "../Util/mediaQueries";
import CircularProgress from "./CircularProgress";

const classes = {
  ...buttonClasses,
  loading: "loading",
  complete: "complete",
  slim: "slim",
  link: "link",
};

const StyledButton = styled(Button)(({ theme }) =>
  mq({
    "&.Mui-focused": {
      outline: `transparent`,
    },
    height: [48, null, null, null, 40],
    textTransform: "none",
    fontWeight: 700,
    borderRadius: 10,
    fontSize: [16, null, null, null, 14],
    minWidth: 80,
    [`&.${classes.slim}`]: {
      height: [48, null, null, null, 32],
    },
    [`&.${classes.outlined}`]: {
      border: `1px solid ${theme.palette.colors.gray400}`,
      color: theme.palette.primary.main,
      backgroundColor: theme.colors.white,
      "&:hover": {
        backgroundColor: theme.palette.colors.accessibilityGreen100,
        borderColor: theme.palette.colors.accessibilityGreenDark,
        color: theme.palette.colors.accessibilityGreenDark,
      },
      "&:active": {
        backgroundColor: theme.palette.colors.accessibilityGreen100,
        borderColor: theme.palette.colors.accessibilityGreen900,
        color: theme.palette.colors.accessibilityGreen900,
      },
      "&.Mui-focusVisible": {
        boxShadow: `0 0 0 2px #076DD1`,
        borderColor: "transparent",
        "&:before": {
          content: "''",
          borderRadius: 10,
          display: "inline-block",
          position: "absolute",
          zIndex: 0,
          top: 1,
          left: 1,
          right: 1,
          bottom: 1,
          border: `1px solid ${theme.palette.colors.gray400}`,
        },
      },
      [`&.${classes.disabled}`]: {
        color: theme.palette.primary.main,
        [`&:not(.${classes.loading}):not(.${classes.complete})`]: {
          color: theme.palette.input.disabled,
          borderColor: theme.palette.input.disabled,
        },
      },
    },
    [`&.${classes.text}`]: {
      color: theme.palette.primary.main,
      border: "2px solid transparent",
      "&:hover": {
        backgroundColor: theme.palette.colors.accessibilityGreen100,
        color: theme.palette.colors.accessibilityGreenDark,
      },
      "&:active": {
        backgroundColor: theme.palette.colors.accessibilityGreen100,
        color: theme.palette.colors.accessibilityGreen900,
      },
      "&.Mui-focusVisible": {
        border: "2px solid #076DD1",
      },
      [`&.${classes.disabled}:not(.loading):not(.complete)`]: {
        color: theme.palette.input.disabled,
      },
    },
    [`&.${classes.contained}`]: {
      border: `2px solid transparent`,
      "&:hover": {
        backgroundColor: theme.palette.colors.accessibilityGreenDark,
        border: `2px solid ${theme.palette.colors.accessibilityGreenDark}`,
      },
      "&:active": {
        backgroundColor: theme.palette.colors.accessibilityGreen900,
        border: `2px solid ${theme.palette.colors.accessibilityGreen900}`,
      },
      "&.Mui-focusVisible": {
        backgroundColor: theme.palette.colors.accessibilityGreen900,
        borderColor: "#076DD1",
        "&:before": {
          content: "''",
          borderRadius: 10,
          display: "inline-block",
          position: "absolute",
          zIndex: 0,
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          border: `1px solid ${theme.palette.colors.white}`,
        },
      },
      [`&.${classes.disabled}`]: {
        [`&.${classes.loading}, &.${classes.complete}`]: {
          backgroundColor: theme.palette.primary.main,
        },
        [`&:not(.${classes.loading}):not(.${classes.complete})`]: {
          color: theme.colors.white,
        },
      },
      ".progress, .checkmark": {
        color: theme.colors.white,
      },
    },
    [`&.${classes.link}`]: {
      color: theme.palette.primary.main,
      border: "2px solid transparent",
      borderRadius: 0,
      padding: 0,
      height: "auto",
      width: "auto",
      minWidth: 0,
      "&:hover, &:active": {
        textDecoration: "underline",
        backgroundColor: "transparent",
      },
      "&.Mui-focusVisible": {
        border: "2px solid #076DD1",
      },
      [`&.${classes.disabled}:not(.loading):not(.complete)`]: {
        color: theme.palette.input.disabled,
      },
    },
    ".checkmark": {
      width: 30,
      height: 30,
    },
  })
);

const TSButton = React.forwardRef(
  (
    {
      className,
      children,
      variant,
      disabled,
      loading,
      complete,
      slim,
      onCompleteClear,
      completeDuration,
      ...props
    },
    ref
  ) => {
    useEffect(() => {
      let ret;
      if (complete && onCompleteClear) {
        const timeout = window.setTimeout(onCompleteClear, completeDuration);

        ret = () => {
          window.clearTimeout(timeout);
        };
      }
      return ret;
    }, [complete, completeDuration, onCompleteClear]);

    return (
      <StyledButton
        ref={ref}
        variant={variant}
        disableElevation
        disableRipple
        disabled={disabled || loading || complete}
        className={classNames(className, {
          [classes.slim]: slim,
          [classes.loading]: loading,
          [classes.complete]: complete,
          [classes.link]: variant === "link",
        })}
        {...props}
      >
        {loading && <CircularProgress className="progress" size={24} />}
        {complete && <CheckCircleOutlineIcon className="checkmark" />}
        {!loading && !complete && children}
      </StyledButton>
    );
  }
);

TSButton.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  variant: PropTypes.oneOf(["contained", "outlined", "text", "link"]),
  slim: PropTypes.bool,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  complete: PropTypes.bool,
  completeDuration: PropTypes.number,
  onCompleteClear: PropTypes.func,
};

TSButton.defaultProps = {
  className: null,
  variant: "contained",
  disabled: false,
  loading: false,
  complete: false,
  slim: false,
  completeDuration: 3000,
  onCompleteClear: () => {},
};

export default TSButton;
export { classes as buttonClasses };

/* eslint-disable react/jsx-props-no-spreading */
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import styled from "@emotion/styled";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

import Typography from "./Typography";
import IconButton from "./IconButton";
import ClickAwayListener from "./ClickAwayListener";

import mq from "../Util/mediaQueries";

const StyledTooltip = styled(
  React.forwardRef(({ className, children, ...props }, ref) => (
    <Tooltip ref={ref} classes={{ popper: className }} {...props}>
      {children}
    </Tooltip>
  ))
)(({ theme }) =>
  mq({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.colors.white,
      width: 185,
      color: theme.palette.text.primary,
      padding: theme.spacing(2),
      fontSize: 14,
      borderRadius: 10,
      boxShadow: "1px 3px 24px rgba(0, 0, 0, 0.1)",
      a: {
        color: theme.palette.colors.accessibilityGreen,
        textDecoration: "none",
        "&:hover": {
          textDecoration: "underline",
        },
      },
      ".title": {
        textTransform: "uppercase",
        margin: `0 0 ${theme.spacing(1)}`,
        color: theme.palette.colors.gray600,
        fontWeight: 600,
      },
      [`&.${tooltipClasses.tooltipPlacementBottom}`]: {
        marginTop: `0 !important`,
      },
    },
  })
);

function ClickableToolTip({
  children,
  buttonComponent: ButtonComponent,
  ...props
}) {
  const [open, setOpen] = useState(false);
  let onWindowChange = null;
  let removeWindowChangeListeners = null;

  const onClose = () => {
    setOpen(false);
  };

  const onOpen = () => {
    setOpen(true);
  };

  removeWindowChangeListeners = () => {
    window.removeEventListener("resize", onWindowChange);
    window.removeEventListener("scroll", onWindowChange);
  };

  onWindowChange = () => {
    onClose();
    removeWindowChangeListeners();
  };

  useEffect(() => {
    let ret;
    if (open) {
      window.addEventListener("resize", onWindowChange);
      window.addEventListener("scroll", onWindowChange);

      ret = () => {
        removeWindowChangeListeners();
      };
    }
    return ret;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <ClickAwayListener onClickAway={onClose}>
      <StyledTooltip open={open} onOpen={onOpen} onClose={onClose} {...props}>
        <ButtonComponent onClick={onOpen}>{children}</ButtonComponent>
      </StyledTooltip>
    </ClickAwayListener>
  );
}

ClickableToolTip.propTypes = {
  children: PropTypes.node.isRequired,
  buttonComponent: PropTypes.elementType.isRequired,
};

export default function TSTooltip({
  children,
  title,
  buttonComponent,
  body,
  ...props
}) {
  const content = (
    <>
      {title && (
        <Typography variant="subheading" className="title">
          {title}
        </Typography>
      )}
      {body && <Typography variant="body">{body}</Typography>}
    </>
  );

  return (
    <>
      <ClickableToolTip
        sx={{ display: { xs: "inline-flex", md: "none" } }}
        title={content}
        placement="bottom-end"
        buttonComponent={buttonComponent}
        disableHoverListener
        disableFocusListener
        disableTouchListener
        {...props}
      >
        {children}
      </ClickableToolTip>
      <ClickableToolTip
        sx={{ display: { xs: "none", md: "inline-flex" } }}
        title={content}
        placement="bottom"
        buttonComponent={buttonComponent}
        {...props}
      >
        {children}
      </ClickableToolTip>
    </>
  );
}

TSTooltip.propTypes = {
  children: PropTypes.node.isRequired,
  buttonComponent: PropTypes.elementType,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  body: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

TSTooltip.defaultProps = {
  buttonComponent: IconButton,
  title: null,
  body: null,
};

export { tooltipClasses };

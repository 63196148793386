/** @jsxRuntime classic */
/** @jsx jsx */
import { useRef, useContext } from "react";
import { jsx } from "@emotion/react";
import styled from "@emotion/styled";
import classNames from "classnames";
import Grid from "@mui/material/Grid";
import CalendarToday from "@mui/icons-material/CalendarToday";
import dayjs from "dayjs";

import bookshelfIcon from "../assets/bookshelf-icon.svg";
import videoCameraIcon from "../assets/video-camera-icon.svg";
import LoggedInLayout, { Header, Body } from "../Components/LoggedInLayout";
import Typography from "../Components/Typography";
import PageHeader from "../Components/PageHeader";
import DownloadScreenshotButton from "../Components/DownloadScreenshotButton";
import { ScreenshotContext } from "../providers/ScreenshotProvider";
import { AuthContext } from "../providers/AuthProvider";
import mq from "../Util/mediaQueries";
import InsightsDashboardChart from "../Components/InsightsDashboardChart";
import InsightsDashboardCard from "../Components/InsightsDashboardCard";
import useOrganization from "../hooks/useOrganization";
import UserTrendsCard from "../Components/UserTrendsCard";
import FocusAreasCard from "../Components/FocusAreasCard";

const customParseFormat = require("dayjs/plugin/customParseFormat");

dayjs.extend(customParseFormat);

const StyledGrid = styled(Grid)(({ theme }) =>
  mq({
    "&.taking-screenshot": {
      width: `${theme.breakpoints.values.lg}px`,
      overflow: "visible",
      padding: theme.spacing(2),
    },
    ".download-row": {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    ".date-range": {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      ".icon": {
        marginRight: 10,
        color: theme.palette.colors.gray600,
        width: 14,
        height: 15,
      },
    },
  })
);

function InsightsDashboard() {
  const { user } = useContext(AuthContext);
  const { orgName } = user.profile;

  // const { firstName } = user.profile;
  const context = useContext(ScreenshotContext);
  const screenshotTargetRef = useRef(null);
  const {
    loading: orgLoading,
    signupCount,
    utilization,
    monthlyActiveUsers,
    totalClassesCount,
    mostPopularClasses,
    totalSessionsCount,
    mostPopularSeries,
    employeeCount,
    userTrends,
    focusAreas,
  } = useOrganization();

  const dateOneYearAgo = dayjs().subtract(1, "year");
  const lastYearOfUserTrends =
    userTrends && userTrends.length
      ? userTrends.filter(
          (trend) => dayjs(trend.week, "YYYY-MM-DD", true) >= dateOneYearAgo
        )
      : null;

  return (
    <LoggedInLayout>
      <Header>
        <PageHeader
          title="Insights"
          subtitle={
            <span>
              Track your people&apos;s utilization of the{" "}
              <b>Talkspace: Self-Guided</b> app
            </span>
          }
        />
      </Header>
      <Body>
        <StyledGrid
          container
          className={classNames({
            "taking-screenshot": context.takingScreenshot,
          })}
          rowSpacing={{ xs: 3, md: 5 }}
          columnSpacing={{ xs: 2, md: 4 }}
          ref={screenshotTargetRef}
        >
          <Grid item xs={12} className="download-row">
            <div className="date-range">
              <CalendarToday className="icon" fontSize="small" />
              <Typography variant="body">Date Range: All Time</Typography>
            </div>
            <DownloadScreenshotButton
              className="button"
              disabled={orgLoading}
              targetElementRef={screenshotTargetRef}
            />
          </Grid>
          <Grid item xs={context.takingScreenshot ? 4 : 12} md={4}>
            <InsightsDashboardCard
              className="sign-ups"
              title="Total Sign Ups"
              value={signupCount}
              loading={orgLoading}
              tooltip="Total number of your employees who have redeemed their code."
            />
          </Grid>
          <Grid item xs={context.takingScreenshot ? 4 : 12} md={4}>
            <InsightsDashboardCard
              className="utilization"
              title="Utilization"
              value={utilization}
              isPercentage
              loading={orgLoading}
              tooltip={`% of your employees who have redeemed their code. Based on ${employeeCount} employees.`}
            />
          </Grid>
          <Grid item xs={context.takingScreenshot ? 4 : 12} md={4}>
            <InsightsDashboardCard
              className="active-users"
              title="Monthly Active Users"
              value={monthlyActiveUsers}
              loading={orgLoading}
              tooltip="Number of your employees who have opened the app within the last 30 days."
            />
          </Grid>
          <Grid item xs={12}>
            <UserTrendsCard
              loading={orgLoading}
              userTrends={lastYearOfUserTrends}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <InsightsDashboardChart
              chartTitle="Self-Guided Sessions Taken"
              metricKeyName="Most Popular"
              metricValueName="Sessions Taken"
              metricIcon={
                <img
                  src={bookshelfIcon}
                  alt="Series Icon"
                  width="36"
                  height="32"
                />
              }
              count={totalSessionsCount}
              tooltip="Total number of sessions that have been completed by your employees."
              rows={mostPopularSeries?.map((row) => ({
                title: row.title,
                count: row.sessionCompletionCount,
              }))}
              loading={orgLoading}
              blockedMessage="Once your employees have completed enough series, you'll see the most popular ones here."
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <InsightsDashboardChart
              chartTitle="Classes Booked"
              metricKeyName="Most Popular"
              metricValueName="Classes Booked"
              metricIcon={
                <img
                  src={videoCameraIcon}
                  alt="Video camera icon"
                  width="38"
                  height="25"
                />
              }
              count={totalClassesCount}
              tooltip="Total number of classes booked by your employees."
              rows={mostPopularClasses?.map((row) => ({
                title: row.title,
                count: row.registrationCount,
              }))}
              loading={orgLoading}
              blockedMessage="Once your employees have booked enough classes, you'll see the most popular ones here."
            />
          </Grid>
          <Grid item xs={12}>
            <FocusAreasCard
              loading={orgLoading}
              focusAreas={focusAreas}
              orgName={orgName}
            />
          </Grid>
        </StyledGrid>
      </Body>
    </LoggedInLayout>
  );
}

export default InsightsDashboard;

/* eslint-disable react/jsx-props-no-spreading */
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import styled from "@emotion/styled";
// eslint-disable-next-line no-unused-vars
import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import DownloadIcon from "@mui/icons-material/Download";

import Card, { cardClasses } from "./Card";
import CardMedia from "./CardMedia";
import CardContent from "./CardContent";
import ResourceCategoryChip from "./ResourceCategoryChip";
import CopyLinkButton from "./CopyLinkButton";
import DecoratedIconButton, {
  decoratedIconButtonClasses,
} from "./DecoratedIconButton";
import Skeleton, { skeletonClasses } from "./Skeleton";
import withAccessibleOnClick from "./withAccessibleOnClick";
import withClickTracking from "./withClickTracking";
import mq from "../Util/mediaQueries";

const classes = { media: "media", title: "title" };

const AccessiblyClickableCard = withAccessibleOnClick(Card);

const StyledCard = styled(AccessiblyClickableCard)(({ theme }) =>
  mq({
    "&.resource-abbreviated-card": {
      display: "flex",
      width: "100%",
      height: "100%",
      border: "none",
      transition: "background-color .3s ease-out",
      "&:not(.loading)": {
        cursor: "pointer",
        "&:hover": {
          backgroundColor: theme.palette.colors.gray50,
        },
      },
      "&.row": {
        flexDirection: "row",
        [`.${classes.title}`]: {
          overflow: "hidden",
          textOverflow: "ellipsis",
          display: "-webkit-box",
          WebkitLineClamp: "2",
          lineClamp: "2",
          WebkitBoxOrient: "vertical",
        },
        [`.${classes.media}`]: {
          height: "100%",
        },
        ".content": {
          paddingTop: [theme.spacing(0.5), null, theme.spacing(1)],
        },
      },
      "&.column": {
        flexDirection: "column",
        [`.${classes.title}`]: {
          overflow: "hidden",
          textOverflow: "ellipsis",
          display: "-webkit-box",
          WebkitLineClamp: "2",
          lineClamp: "2",
          WebkitBoxOrient: "vertical",
        },
        [`.${classes.media}`]: {
          width: "100%",
          // height: [120, null, 158, 236],
        },
        ".content": {
          padding: theme.spacing(2),
        },
      },
      [`.${classes.media}`]: {
        position: "relative",
        backgroundColor: theme.palette.colors.indigo,
        borderRadius: 10,
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        overflow: "hidden",
        flexShrink: 0,
        aspectRatio: "1 / 1",
        padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
        ".wrapper": {
          display: "block",
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          width: "100%",
          height: "100%",
          imageRendering: "-moz-crisp-edges",
        },
      },
      ".content": {
        flex: 1,
        alignSelf: "flex-start",
      },
      ".categories": {
        marginTop: theme.spacing(1),
      },
      ".actions": {
        position: "absolute",
        top: [theme.spacing(1), null, null, theme.spacing(2)],
        right: [theme.spacing(1), null, null, theme.spacing(2)],
        zIndex: 1,
      },
      [`.${decoratedIconButtonClasses.root}`]: {
        margin: 0,
      },
      ".button": {
        boxShadow: "0px 1px 4px 0px #22292F33",
        "&.download": {
          marginLeft: theme.spacing(1),
        },
      },
      [`.${skeletonClasses.root}`]: {
        width: 209,
        height: 18,
        marginTop: 5,
        "&:nth-of-type(3)": {
          width: 74,
          height: 28,
          marginTop: 10,
        },
      },
      "&:not(:hover), &.loading": {
        [`&.${cardClasses.root}`]: {
          backgroundColor: "transparent",
          boxShadow: "none",
        },
        ".actions": {
          display: "none",
        },
      },
    },
  })
);

const TrackedDownloadButton = withClickTracking(DecoratedIconButton);
const TrackedCopyLinkButton = withClickTracking(CopyLinkButton);

function ResourceAbbreviatedCard({
  resource,
  loading,
  className,
  orientation,
  onClick,
}) {
  const { id, thumbnailImageUrl, title, fileUrl } = resource;
  let content = null;

  if (loading)
    content = (
      <>
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </>
    );
  else
    content = (
      <>
        <h4 className={classes.title}>{title}</h4>
        <div className="categories">
          <ResourceCategoryChip name="pdf" />
        </div>
      </>
    );

  return (
    <StyledCard
      className={classNames(
        "resource-abbreviated-card",
        className,
        orientation,
        { loading }
      )}
      disabled={loading}
      onClick={() => {
        if (!loading) onClick(resource);
      }}
      tabIndex={loading ? -1 : 0}
    >
      <CardMedia className={classes.media} component="div">
        {!loading && fileUrl && (
          <div className="actions">
            <TrackedCopyLinkButton
              className="button"
              component={DecoratedIconButton}
              componentProps={{
                variant: "outlined",
                className: "button",
                tooltipText: "Copy link",
              }}
              showHoverTooltip
              link={fileUrl}
              eventName="Resource Text Copied"
              eventProps={{
                Name: title,
                "Resource type": "PDF",
                "Resource id": id,
              }}
            />
            <TrackedDownloadButton
              className="button download"
              tooltipText="Download"
              variant="outlined"
              component="a"
              download
              target="_blank"
              href={fileUrl}
              onClick={(e) => {
                e.stopPropagation();
              }}
              eventName="Resource Opened"
              eventProps={{
                Name: title,
                "Resource type": "PDF",
                "Resource id": id,
              }}
            >
              <DownloadIcon className="icon" />
            </TrackedDownloadButton>
          </div>
        )}
        {!loading && thumbnailImageUrl && (
          <div
            className="wrapper"
            style={{
              backgroundImage: `url(${thumbnailImageUrl})`,
            }}
          />
        )}
      </CardMedia>
      <CardContent className="content">{content}</CardContent>
    </StyledCard>
  );
}

ResourceAbbreviatedCard.propTypes = {
  className: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  orientation: PropTypes.oneOf(["column", "row"]),
  onClick: PropTypes.func.isRequired,
  resource: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    description: PropTypes.string,
    thumbnailImageUrl: PropTypes.string,
    fileUrl: PropTypes.string,
  }),
};

ResourceAbbreviatedCard.defaultProps = {
  className: null,
  resource: PropTypes.shape({
    id: null,
    title: null,
    description: null,
    thumbnailImageUrl: null,
    fileUrl: null,
  }),
  orientation: "column",
};

export default ResourceAbbreviatedCard;
export { classes as resourceAbbreviatedCardClasses };

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import styled from "@emotion/styled";
// eslint-disable-next-line no-unused-vars
import React, { useEffect, useState, useContext } from "react";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import classNames from "classnames";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
} from "chart.js";
import { Bar } from "react-chartjs-2";

import Typography from "./Typography";
import GraphCard from "./GraphCard";
import Button from "./Button";
import mq from "../Util/mediaQueries";
import { ScreenshotContext } from "../providers/ScreenshotProvider";

ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip);

const StyledCard = styled(GraphCard)(({ theme }) =>
  mq({
    "&.graph": {
      padding: [
        `${theme.spacing(3)} ${theme.spacing(2)} ${theme.spacing(3)}`,
        null,
        null,
        `${theme.spacing(4)} 43px ${theme.spacing(4)} ${theme.spacing(4)}`,
      ],
    },
  })
);

const COLLAPSED_ROW_COUNT = 5;

function FocusAreasGraph({ focusAreas }) {
  const context = useContext(ScreenshotContext);
  const expandable = focusAreas.length > COLLAPSED_ROW_COUNT;
  let filteredFocusAreas = [...focusAreas].sort((a, b) => {
    if (a.percent < b.percent) return 1;
    return a.percent === b.percent ? 0 : -1;
  });
  const [isCollapsed, setIsCollapsed] = useState(
    expandable && !context.takingScreenshot
  );
  const toggleIsCollapsed = () => setIsCollapsed(!isCollapsed);
  const forceExpand = expandable && context.takingScreenshot;
  const theme = useTheme();

  if (isCollapsed && !forceExpand) {
    filteredFocusAreas = filteredFocusAreas.slice(0, COLLAPSED_ROW_COUNT);
  }

  const StyledContainer = styled("div")(() =>
    mq({
      display: "flex",
      flexDirection: "column",
      width: "100%",
      ".view-toggle": {
        position: "absolute",
        bottom: 2,
        left: 59,
        color: theme.palette.colors.accessibilityGreen,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
      },
      ".chart-container": {
        position: "relative",
        maxWidth: "100%",
        width: "100%",
        maxHeight: "100%",
        height: "100%",
      },
      ".chart-wrapper": {
        width: "100%",
        height: `calc(54px * ${filteredFocusAreas.length} + 25px)`,
      },
      ".legend": {
        alignSelf: "center",
        display: "flex",
        flexDirection: "row",
        marginTop: theme.spacing(3),
      },
    })
  );

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    indexAxis: "y",
    animation: {
      duration: context.takingScreenshot ? 0 : 1000,
    },
    scales: {
      xAxis: {
        beginAtZero: true,
        stepSize: 10,
        suggestedMax: 100,
        ticks: {
          count: 11,
          font: {
            family: theme.typography.fontFamily,
            size: 12,
          },
        },
        grid: {
          borderDash: [5, 5],
        },
      },
      yAxis: {
        grid: {
          display: false,
        },
        ticks: {
          font: {
            family: theme.typography.fontFamily,
            size: 12,
          },
        },
        afterFit(scaleInstance) {
          // eslint-disable-next-line no-param-reassign
          scaleInstance.width = 170;
        },
      },
    },
    elements: {
      bar: {
        borderWidth: 0,
      },
    },
    plugins: {
      tooltip: {
        backgroundColor: "white",
        padding: 16,
        usePointStyle: true,
        cornerRadius: 10,
        caretSize: 0,
        borderColor: theme.palette.colors.gray200,
        borderWidth: 1,
        titleColor: theme.palette.colors.gray800,
        titleAlign: "center",
        titleSpacing: 16,
        titleMarginBottom: 8,
        boxPadding: 8,
        titleFont: {
          family: theme.typography.fontFamily,
          size: 12,
          color: theme.palette.colors.gray600,
          textTransform: "uppercase",
        },
        bodyColor: theme.palette.colors.tsBlack,
        bodyFont: {
          family: theme.typography.fontFamily,
          size: 14,
          color: theme.palette.colors.gray800,
        },
        callbacks: {
          title: (data) => {
            const item = data.length ? data[0] : data;
            if (item) return item.label.toUpperCase();
            return "";
          },
        },
      },
    },
  };

  const data = {
    labels: filteredFocusAreas.map((area) => area.label),
    datasets: [
      {
        data: filteredFocusAreas.map((area) => area.percent),
        borderColor: theme.palette.colors.purpleBase,
        backgroundColor: theme.palette.colors.purpleBase,
        borderSkipped: false,
        barThickness: 21,
        borderRadius: 5,
      },
    ],
  };

  return (
    <StyledContainer
      className={classNames({ expanded: !isCollapsed || forceExpand })}
    >
      <div className="chart-container">
        <div className="chart-wrapper">
          <Bar updateMode="none" options={options} data={data} />
        </div>
        {expandable && !forceExpand && (
          <Button
            variant="text"
            className="view-toggle"
            size="small"
            onClick={() => toggleIsCollapsed(false)}
          >
            {isCollapsed && (
              <>
                <AddIcon />
                <span>View More</span>
              </>
            )}
            {!isCollapsed && (
              <>
                <RemoveIcon />
                <span>View Less</span>
              </>
            )}
          </Button>
        )}
      </div>
      <Typography variant="body" className="legend">
        % of users that took assessment
      </Typography>
    </StyledContainer>
  );
}

FocusAreasGraph.propTypes = {
  focusAreas: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      percent: PropTypes.number,
    })
  ).isRequired,
};

function FocusAreasCard({ loading, orgName, focusAreas }) {
  const title = orgName ? `Focus Areas for ${orgName}` : "Focus Areas";

  return (
    <StyledCard
      loading={loading}
      showPrivacyWarning={!focusAreas}
      title={title}
      tooltip="These are the areas that are affecting your employees, based on an assessment that they take when they first sign up."
    >
      {focusAreas && <FocusAreasGraph focusAreas={focusAreas} />}
    </StyledCard>
  );
}

FocusAreasCard.propTypes = {
  loading: PropTypes.bool,
  orgName: PropTypes.string.isRequired,
  focusAreas: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      percent: PropTypes.number,
    })
  ),
};

FocusAreasCard.defaultProps = {
  loading: false,
  focusAreas: null,
};

export default FocusAreasCard;

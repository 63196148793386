/* eslint-disable react/jsx-props-no-spreading */
// eslint-disable-next-line no-unused-vars
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { styled, useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import AccordionDetails from "@mui/material/AccordionDetails";

import mq from "../Util/mediaQueries";
import Button from "./Button";
import Snackbar from "./Snackbar";

import copy from "../assets/copy-green.svg";
import check from "../assets/check.svg";
import eye from "../assets/eye.svg";

const Details = styled(AccordionDetails)(({ theme }) =>
  mq({
    transition: "all 1s ease",
    marginLeft: "104px",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "inherit",
    },
    ".offering-title": {
      fontWeight: 600,
      fontSize: "14px",
      color: theme.colors.tsBlack,
    },
    ".offering-title:nth-of-type(3)": {
      marginTop: "24px",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        border: `1px solid ${theme.colors.gray200}`,
        borderRadius: "14px",
      },
    },
    ".offering-copy": {
      padding: "8px",
      marginTop: "8px",
      border: `1px solid ${theme.colors.gray200}`,
      borderRadius: "10px",
    },
    ".offering-copy-link": {
      display: "flex",
      justifyContent: "space-between",
      flexWrap: "nowrap",
      gap: "8px",
      padding: "8px",
      marginTop: "8px",
      border: `1px solid ${theme.colors.gray200}`,
      borderRadius: "10px",
      p: {
        display: "block",
        textOverflow: "ellipsis !important",
        overflow: "hidden",
        whiteSpace: "nowrap",
        margin: ["13px 0", null, null, null, "5px 0"],
      },
    },
    ".offering-btn": {
      minWidth: "fit-content",
      img: {
        marginRight: "8px",
        [theme.breakpoints.down("sm")]: {
          marginRight: "inherit",
        },
      },
      [theme.breakpoints.down("sm")]: {
        padding: "12px",
      },
    },
    ".copy-btn": {},
    ".subject": {},
  })
);

const ButtonContainer = styled("span")(() =>
  mq({
    gap: "8px",
    display: "flex",
    flexDirection: "row-reverse",
  })
);

const getTitle = (classType) => {
  switch (classType) {
    case "LiveClass":
      return "Link to Zoom:";
    case "WorksheetResource":
      return "Link to worksheet:";
    case "QuickTipResource":
      return "Link to quick tips:";
    case "OnDemandClass":
      return "Vimeo link:";
    default:
      return "";
  }
};

const getDetails = (classType) => {
  switch (classType) {
    case "LiveClass":
    case "OnDemandClass":
      return "Class summary:";
    case "WorksheetResource":
      return "Worksheet summary:";
    case "QuickTipResource":
      return "Quick tips summary:";
    default:
      return "";
  }
};

export default function PlanAccordionItem({ record, handlePDFPreview }) {
  const {
    liveLink,
    recordingLink,
    fileUrl,
    description,
    __typename: type,
  } = record;
  const [copySuccess, setCopySuccess] = useState({
    link: false,
    description: false,
  });
  const [open, setOpen] = useState(false);

  const link = liveLink || recordingLink || fileUrl;
  const classDetails = description;

  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("sm"));

  const handleCopy = async (text, prop) => {
    await navigator.clipboard.writeText(text);
    setCopySuccess({ ...copySuccess, [prop]: true });
    if (sm) setOpen(true);
    setTimeout(() => {
      setCopySuccess({ ...copySuccess, [prop]: false });
    }, 3000);
  };

  return (
    <Details>
      <div className="offering-title">{getTitle(type)}</div>
      <div className="offering-copy-link">
        <p>{link}</p>
        {(type === "WorksheetResource" || type === "QuickTipResource") && (
          <Button
            className="offering-btn"
            onClick={() => handlePDFPreview(record)}
            variant="outlined"
            slim
          >
            <img src={eye} alt="Eye" />
            {sm ? "" : "Preview"}
          </Button>
        )}
        <Button
          className={`offering-btn copy-btn ${
            copySuccess.link ? "copied" : ""
          }`}
          onClick={() => handleCopy(link, "link")}
          variant="outlined"
          slim
        >
          <img src={copySuccess.link ? check : copy} alt="Copy" />
          {sm ? "" : <span>{copySuccess.link ? "Copied!" : "Copy"}</span>}
        </Button>
      </div>

      <div className="offering-title">{getDetails(type)}</div>
      <div className="subject offering-copy">
        <p>{classDetails}</p>
        <ButtonContainer>
          <Button
            className={`offering-btn copy-btn ${
              copySuccess.description ? "copied" : ""
            }`}
            onClick={() => handleCopy(classDetails, "description")}
            variant="outlined"
            slim
          >
            <img src={copySuccess.description ? check : copy} alt="Copy" />
            {sm ? "" : <p>{copySuccess.description ? "Copied!" : "Copy"}</p>}
          </Button>
        </ButtonContainer>
      </div>
      <Snackbar
        open={open}
        onClose={() => setOpen(false)}
        message="Link copied to clipboard"
        sx={{ width: "max-content", margin: "auto" }}
      />
    </Details>
  );
}

PlanAccordionItem.propTypes = {
  record: PropTypes.shape({
    fileUrl: PropTypes.string,
    liveLink: PropTypes.string,
    recordingLink: PropTypes.string,
    description: PropTypes.string,
    __typename: PropTypes.string,
  }).isRequired,
  handlePDFPreview: PropTypes.func.isRequired,
};

PlanAccordionItem.defaultProps = {};

export { PlanAccordionItem };

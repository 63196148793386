/* eslint-disable react/jsx-props-no-spreading */
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import CircularProgress from "@mui/material/CircularProgress";

function TSCircularProgress({ ...props }) {
  return <CircularProgress {...props} />;
}

export default TSCircularProgress;

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import styled from "@emotion/styled";
// eslint-disable-next-line no-unused-vars
import React, { lazy, Suspense, useEffect } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import DownloadIcon from "@mui/icons-material/Download";
import useMediaQuery from "@mui/material/useMediaQuery";

import Dialog, { dialogClasses, dialogContentClasses } from "./Dialog";
import ResourceCategoryChip from "./ResourceCategoryChip";
import Avatar from "./Avatar";
import Button from "./Button";
import Typography from "./Typography";
import CopyLinkButton from "./CopyLinkButton";
import CircularProgress from "./CircularProgress";
import withClickTracking from "./withClickTracking";
import mq from "../Util/mediaQueries";
import humanReadableFileSize from "../Util/humanReableFileSize";
import tracking from "../services/tracking";

dayjs.extend(customParseFormat);

const PdfPreview = lazy(() => import("./PdfPreview"));

const StyledDialog = styled(Dialog)(({ theme }) =>
  mq({
    [`.${dialogClasses.paper}`]: {
      minWidth: [null, null, null, null, "90vw"],
      height: ["100%", null, null, null, "90vh"],
      maxHeight: ["100%", null, null, null, "none"],
      overflowY: ["hidden", null, null, null, "visible"],
    },
    [`.${dialogContentClasses.root}`]: {
      padding: 0,
      width: "100%",
      height: "100%",
      maxHeight: ["100%", null, null, null, "none"],
      overflowY: ["hidden", null, null, null, "visible"],
    },
    [`&.mobile .${dialogClasses.closeButton}`]: {
      padding: theme.spacing(0.5),
      right: 28,
      boxShadow: "0px 4px 14px -4px rgba(52, 43, 80, 0.15)",
      "&:not(:hover):not(:active)": {
        backgroundColor: theme.palette.colors.white,
      },
    },
    ".resource-content": {
      display: "flex",
      flexDirection: ["column", null, null, null, "row"],
      width: "100%",
      height: "100%",
      maxHeight: ["100%", null, null, null, "none"],
      overflowY: ["hidden", null, null, null, "visible"],
    },
    ".pdf": {
      overflowX: "hidden",
      overflowY: "auto",
      flex: ["1 1 auto", null, null, null, "0 0 64%"],
      ".loading": {
        width: "100%",
      },
    },
    ".details": {
      display: "flex",
      flexDirection: "column",
      justifyContent: ["space-between", null, null, null, "flex-start"],
      textAlign: "left",
      maxHeight: ["65%", null, null, null, "none"],
      height: "auto",
      flex: ["0 0 auto", null, null, null, "1 1 auto"],
      borderTop: [
        `1px solid ${theme.palette.colors.gray100}`,
        null,
        null,
        null,
        "none",
      ],
      padding: [
        theme.spacing(2),
        null,
        null,
        null,
        `${theme.spacing(7)} ${theme.spacing(4)} ${theme.spacing(5)}`,
      ],
      ".non-actions": {
        flex: "1 1 auto",
        overflowY: ["auto", null, null, null, "none"],
      },
      ".category, .description, .file-meta": {
        paddingTop: theme.spacing(2),
      },
      ".file-meta": {
        display: "flex",
        flexDirection: "row",
        color: theme.palette.colors.gray800,
        ".text": {
          marginLeft: theme.spacing(2),
        },
      },
      ".description": {
        overflowX: "auto",
      },
      ".actions": {
        flex: "0 0 auto",
        marginTop: theme.spacing(2),
        display: "flex",
        justifySelf: "flex-end",
        flexDirection: ["column", null, "row", null, "column"],
        ".text": {
          marginLeft: theme.spacing(1),
        },
        ".download": {
          marginTop: [theme.spacing(1), null, 0, null, theme.spacing(1)],
          marginLeft: [0, null, theme.spacing(1), null, 0],
        },
      },
    },
  })
);

const TrackedDownloadButton = withClickTracking(Button);
const TrackedCopyLinkButton = withClickTracking(CopyLinkButton);

function ResourceDetailDialog({ open, onClose, className, resource }) {
  const {
    id,
    title,
    description,
    readingDuration,
    fileUrl,
    createdAt,
    fileSize,
    authorName,
    authorCredentials,
    authorImageUrl,
  } = resource;
  const lessThanLg = useMediaQuery((theme) => theme.breakpoints.down("lg"));

  useEffect(() => {
    if (open)
      tracking.track("Resource Details Viewed", {
        Name: title,
        "Resource type": "PDF",
        "Resource id": id,
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <StyledDialog
      className={classNames("resource-detail-dialog", className, {
        mobile: lessThanLg,
      })}
      scroll={lessThanLg ? "paper" : "body"}
      fullScreen={lessThanLg}
      open={open}
      onClose={onClose}
      content={
        <section className="resource-content">
          <div className="pdf">
            <Suspense
              fallback={
                <div className="loading">
                  <CircularProgress />
                </div>
              }
            >
              <PdfPreview url={fileUrl} />
            </Suspense>
          </div>
          <section className="details">
            <section className="non-actions">
              <Typography variant="small">{title}</Typography>
              <div className="category">
                <ResourceCategoryChip name="pdf" />
              </div>
              <div className="file-meta">
                <Avatar alt={authorName} src={authorImageUrl} />
                <Typography variant="caption" component="div" className="text">
                  <div>
                    Written by {authorName}{" "}
                    {authorCredentials && <span>, {authorCredentials}</span>}
                  </div>
                  <div>
                    Published on{" "}
                    {dayjs(createdAt, "YYYY-MM-DD H:mm:ss Z").format(
                      "MMMM D, YYYY"
                    )}
                  </div>
                  {readingDuration && <div>{readingDuration} read</div>}
                  {fileSize && <div>{humanReadableFileSize(fileSize)}</div>}
                </Typography>
              </div>
              <Typography variant="body" className="description">
                {description}
              </Typography>
            </section>
            <section className="actions">
              <TrackedCopyLinkButton
                component={Button}
                componentProps={{ variant: "outlined", fullWidth: true }}
                link={fileUrl}
                showHoverTooltip={false}
                eventName="Resource Text Copied"
                eventProps={{
                  Name: title,
                  "Resource type": "PDF",
                  "Resource id": id,
                }}
              >
                <span className="text">Copy Link</span>
              </TrackedCopyLinkButton>
              <TrackedDownloadButton
                className="download"
                component="a"
                download
                href={fileUrl}
                target="_blank"
                startIcon={<DownloadIcon />}
                fullWidth
                variant="contained"
                onMouseDown={(e) => {
                  e.stopPropagation();
                }}
                eventName="Resource Opened"
                eventProps={{
                  Name: title,
                  "Resource type": "PDF",
                  "Resource id": id,
                }}
              >
                Download PDF
              </TrackedDownloadButton>
            </section>
          </section>
        </section>
      }
    />
  );
}

ResourceDetailDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  className: PropTypes.string,
  resource: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    // type: PropTypes.oneOf(["pdf"]).isRequired,
    readingDuration: PropTypes.string.isRequired,
    fileUrl: PropTypes.string.isRequired,
    createdAt: PropTypes.string.isRequired,
    fileSize: PropTypes.number,
    authorName: PropTypes.string.isRequired,
    authorCredentials: PropTypes.string,
    authorImageUrl: PropTypes.string.isRequired,
  }).isRequired,
};

ResourceDetailDialog.defaultProps = {
  className: null,
};

export default ResourceDetailDialog;

import dayjs from "dayjs";
import generateMailtoLink from "./generateMailtoLink";

const getClassTime = (startDay, endDay) => {
  const startMeridiem = startDay.format("A");
  const endMeridiem = endDay.format("A");
  if (startMeridiem === endMeridiem)
    return `${startDay.format("h:mm")}-${endDay.format("h:mm A")}`;
  return `${startDay.format("h:mm A")}-${endDay.format("h:mm A")}`;
};

const generateClassShareLink = ({
  title,
  description,
  startTime,
  endTime,
  liveLink,
  recordingLink,
}) => {
  const startDay = dayjs(startTime);
  const formattedStartDay = startDay.format("dddd, MMMM D YYYY");
  const endDay = dayjs(endTime);
  const timezoneAbbrevation = new Date()
    .toLocaleString("en", { timeZoneName: "short" })
    .split(" ")
    .pop();
  const formattedClassTime = `${getClassTime(
    startDay,
    endDay
  )} ${timezoneAbbrevation}`;
  const recordingBlurb = recordingLink
    ? `%0D%0A%0D%0ACan't make it to the class? You can view a replay the class here: ${recordingLink}`
    : "";

  return generateMailtoLink({
    subject: `Join Talkspace Self-Guided Class: ${title}`,
    body: `Hi!%0D%0AReaching out to let you know about an upcoming Talkspace: Self-Guided class that you might find insightful. If you're interested, here are the details: %0D%0A%0D%0A${title}%0D%0A%0D%0A${formattedStartDay}%0D%0A%0D%0A${formattedClassTime}%0D%0A%0D%0A${description}%0D%0A%0D%0AYou can access the class via this link: ${liveLink}${recordingBlurb}%0D%0A%0D%0AHope to see you there or at another upcoming class soon!`,
  });
};

export default { getClassTime, generateClassShareLink };
export { getClassTime, generateClassShareLink };

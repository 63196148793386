/* eslint-disable react/jsx-props-no-spreading */
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import RequireAuth from "./RequireAuth";

const withAuthRequired = (Component) => {
  function AuthRequiredPage({ ...props }) {
    return (
      <RequireAuth>
        <Component {...props} />
      </RequireAuth>
    );
  }

  AuthRequiredPage.displayName = "AuthRequiredLayout";

  return AuthRequiredPage;
};

export default withAuthRequired;

/* eslint-disable react/jsx-props-no-spreading */
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import React from "react";
import PropTypes from "prop-types";
import Alert, { alertClasses } from "@mui/material/Alert";

const TSAlert = React.forwardRef(({ children, ...props }, ref) => (
  <Alert ref={ref} {...props}>
    {children}
  </Alert>
));

TSAlert.propTypes = {
  children: PropTypes.node.isRequired,
};

export default TSAlert;
export { alertClasses };

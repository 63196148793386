/* eslint-disable react/jsx-props-no-spreading */
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import styled from "@emotion/styled";
import React from "react";
import PropTypes from "prop-types";
import Typography, { typographyClasses } from "@mui/material/Typography";
import mq from "../Util/mediaQueries";

const DISPLAY_X_LARGE = "xlarge";
const DISPLAY_LARGE = "large";
const DISPLAY_MEDIUM = "medium";
const DISPLAY_SMALL = "small";
const HEADING = "heading";
const SUBHEADING = "subheading";
const BUTTON = "button";
const BODY = "body";
const CAPTION = "caption";

const classPrefix = "MuiTypography-";

const StyledTypography = styled(Typography)(() =>
  mq({
    [`&.${classPrefix}${DISPLAY_X_LARGE}`]: {
      fontSize: [27, null, null, 42],
      fontWeight: 600,
      lineHeight: ["36px", null, null, "44px"],
    },
    [`&.${classPrefix}${DISPLAY_LARGE}`]: {
      fontSize: [24, null, null, 28],
      fontWeight: 600,
      lineHeight: ["28px", null, null, "32px"],
    },
    [`&.${classPrefix}${DISPLAY_MEDIUM}`]: {
      fontSize: [21, null, null, 26],
      fontWeight: 400,
      lineHeight: ["28px", null, null, "32px"],
    },
    [`&.${classPrefix}${DISPLAY_SMALL}`]: {
      fontSize: [19, null, null, 20],
      fontWeight: 400,
      lineHeight: ["24px", null, null, "28px"],
    },
    [`&.${classPrefix}${HEADING}`]: {
      fontSize: [17, null, null, 16],
      fontWeight: 600,
      lineHeight: ["24px", null, null, "24px"],
    },
    [`&.${classPrefix}${SUBHEADING}`]: {
      fontSize: [13, null, null, 12],
      fontWeight: 600,
      lineHeight: "16px",
      textTransform: "uppercase",
    },
    [`&.${classPrefix}${BUTTON}`]: {
      fontSize: [16, null, null, 14],
      fontWeight: 600,
      lineHeight: "20px",
    },
    [`&.${classPrefix}${BODY}`]: {
      fontSize: [15, null, null, 14],
      fontWeight: 400,
      lineHeight: ["20px", null, null, "20px"],
    },
    [`&.${classPrefix}${CAPTION}`]: {
      fontSize: [13, null, null, 12],
      fontWeight: 400,
      lineHeight: ["20px", null, null, "16px"],
    },
  })
);

const variantMapping = {
  [DISPLAY_X_LARGE]: "h1",
  h1: undefined,
  [DISPLAY_LARGE]: "h2",
  h2: undefined,
  [DISPLAY_MEDIUM]: "h3",
  h3: undefined,
  [DISPLAY_SMALL]: "h4",
  h4: undefined,
  [HEADING]: "h5",
  h5: undefined,
  h6: undefined,
  [SUBHEADING]: "h6",
  subtitle1: undefined,
  subtitle2: undefined,
  [BUTTON]: "button",
  [CAPTION]: "caption",
  [BODY]: "p",
  body1: undefined,
  body2: undefined,
  inherit: "p",
};

const TSTypography = React.forwardRef(({ variant, ...props }, ref) => (
  <StyledTypography
    ref={ref}
    variantMapping={variantMapping}
    variant={variant}
    {...props}
  />
));

TSTypography.propTypes = {
  variant: PropTypes.oneOf([
    DISPLAY_X_LARGE,
    DISPLAY_LARGE,
    DISPLAY_MEDIUM,
    DISPLAY_SMALL,
    HEADING,
    SUBHEADING,
    BUTTON,
    BODY,
    CAPTION,
  ]).isRequired,
};

export default TSTypography;
export { typographyClasses };

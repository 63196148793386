/* eslint-disable no-underscore-dangle */
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
// eslint-disable-next-line no-unused-vars
import React, { useState, useEffect } from "react";

import { styled, useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Skeleton } from "@mui/material";
import { NavLink } from "react-router-dom";

import LoggedInLayout, { Header, Body } from "../Components/LoggedInLayout";
import AllPlansAside from "../Components/AllPlansAside";
import Grid from "../Components/Grid";
import Button from "../Components/Button";
import ResourceDetailDialog from "../Components/ResourceDetailDialog";
import SharePlan from "../Components/SharePlanWidget";
import PageHeader from "../Components/PageHeader";
import PlansLexicon from "../Components/Lexicon";
import PlansCarousel from "../Components/PlansCarousel";
import Accordion from "../Components/PlanAccordion";
import TSTypography from "../Components/Typography";
import mq from "../Util/mediaQueries";
import usePlans from "../hooks/usePlans";

import errorPopup from "../assets/exclaim_box.svg";
import greenList from "../assets/green-list.svg";
import list from "../assets/list.svg";
import emailPopup from "../assets/email-popup.svg";
import redo from "../assets/redo.svg";
import getMonthName from "../Util/dateUtils";

const HeaderContainer = styled("div")(() =>
  mq({
    // backgroundColor: theme.colors.white,
    // top: 0,
    // position: "sticky",
    // zIndex: 100,
    // width: "100%",
    // borderBottom: `1px solid ${theme.colors.lightGray}`,
  })
);

const HeaderBorder = styled("hr")(({ theme }) =>
  mq({
    marginLeft: `-${theme.spacing(5)}`,
    marginRight: `-${theme.spacing(5)}`,
    marginTop: theme.spacing(4),
    border: "none",
    borderTop: `1px solid ${theme.palette.colors.gray100}`,
    [theme.breakpoints.down("sm")]: {
      border: "none",
      marginTop: theme.spacing(2),
    },
  })
);

const PlanSelection = styled("div")(() =>
  mq({
    display: "flex",
    gap: "3vw",
    justifyContent: "space-between",
  })
);

const AllPlansBtn = styled(Button)(({ theme }) =>
  mq({
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
    flex: "1 1 auto",
    position: "relative",
    top: "23px",
    [theme.breakpoints.down("lg")]: {
      top: "12px",
    },
    minWidth: "fit-content",
    img: {
      marginRight: 8,
    },
    ".active": {
      background: `${theme.palette.colors.accessibilityGreen100} !important`,
    },
  })
);

const PlanBody = styled(Grid)(({ theme }) =>
  mq({
    width: "100%",
    flex: "2 3 auto",
    ".plan-title": {
      color: theme.colors.black100,
      fontSize: "26px",
      fontWeight: 400,
      lineHeight: "32px",
    },
    ".plan-subtitle-container": {
      display: "flex",
      gap: theme.spacing(2),
      width: "100%",
      justifyContent: "space-between",
      flexWrap: "nowrap",
      [theme.breakpoints.down("xl")]: {
        flexWrap: "wrap",
      },
      marginTop: "32px",
      [theme.breakpoints.down("sm")]: {
        marginTop: "16px",
      },
    },
    "a:link, a:visited": {
      color: theme.colors.accessibilityGreenDark,
    },
  })
);

const PlanDescription = styled("p")(({ theme }) =>
  mq({
    color: theme.colors.gray800,
    fontSize: "14px",
    lineHeight: "20px",
    fontWeight: 400,
  })
);

const NextStepWidget = styled("div")(({ theme }) =>
  mq({
    minWidth: "250px",
    marginTop: "32px",
    marginLeft: "2vw",
    marginBottom: "32px",
    display: "flex",
    position: "sticky",
    top: 235,
    flex: "1 1 auto",
    border: `1px solid ${theme.colors.gray100}`,
    borderRadius: "10px",
    justifyContent: "center",
    flexDirection: "column",
    alignSelf: "flex-start",
    alignItems: "center",
    textAlign: "center",
    padding: theme.spacing(3),
    img: {
      width: "122px",
    },
    ".hide": {
      opacity: 0,
    },
    '.welp': {
      position: 'static !important',
    }
  })
);

const ShareButton = styled(Button)(({ theme }) =>
  mq({
    marginTop: theme.spacing(2),
    width: "100%",
    minWidth: "max-content",
  })
);

const LoadError = styled("div")(() =>
  mq({
    marginTop: "128px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    width: "100%",
    ".reload-btn": {
      marginTop: "16px",
      img: {
        marginRight: "8px",
      },
    },
  })
);

function PlansDashboard() {
  const [plans, setPlans] = useState(null);
  const [activePlan, setActivePlan] = useState(null);
  const [tabNumber, setTabNumber] = useState(0);
  const [showAllPlans, setShowAllPlans] = useState(false);
  const [open, setOpen] = useState(false);
  const [planRecord, setPlanRecord] = useState();

  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.up("xs"));
  const sm = useMediaQuery(theme.breakpoints.up("sm"));
  const md = useMediaQuery(theme.breakpoints.up("md"));
  const lg = useMediaQuery(theme.breakpoints.up("lg"));
  const xl = useMediaQuery(theme.breakpoints.up("xl"));

  const { data, loading, error, refetch } = usePlans();

  useEffect(() => {
    if (xl) {
      setTabNumber(4);
    } else if (lg || md) {
      setTabNumber(3);
    } else if (sm) {
      setTabNumber(2);
    } else if (xs) {
      setTabNumber(1);
    }
  }, [xs, sm, md, lg, xl]);

  useEffect(() => {
    if (data?.plans) {
      setPlans(data.plans);
      const d = new Date();
      const plan = data.plans.find((e) => (e.month === d.getMonth()+1) && (e.year === d.getFullYear()));
      setActivePlan(plan || data.plans[0]);
    }
  }, [data, loading, plans]);

  const handlePlanSelection = (plan) => {
    setActivePlan(plan);
  };

  const handleShowAllPlans = () => {
    setShowAllPlans(!showAllPlans);
  };

  const handlePDFPreview = (record) => {
    setPlanRecord(record);
    setOpen(!open);
  };

  const scrollToShare = () => {
    const id = "share";
    const yOffset = -225;
    const element = document.getElementById(id);
    const y =
      element.getBoundingClientRect().top + window.pageYOffset + yOffset;
    window.scrollTo({ top: y, behavior: "smooth" });
  };

  return (
    <LoggedInLayout sx={{ pt: "0 !important" }}>
      <Header
        sx={{
          pb: "0 !important",
          pt: { xs: "16px", sm: "40px" },
          position: "sticky",
          top: { md: "56px", lg: 0 },
          backgroundColor: "#fff",
          zIndex: 999,
          [theme.breakpoints.down("sm")]: {
            border: "none",
          },
        }}
      >
        <HeaderContainer>
          <PageHeader
            title="Engagement Plans"
            subtitle="Each month, we curate a set of resources from our extensive Class
              Schedule and Resource Library for you to share with your employees"
          />
          <HeaderBorder />
          <PlanSelection sx={{ display: error ? "none" : "" }}>
            <PlansCarousel
              plans={plans}
              show={tabNumber}
              handlePlanSelection={handlePlanSelection}
              activePlan={activePlan}
              isLoading={loading}
            />
            <AllPlansBtn
              xs={{ display: { xs: "none", lg: "inline" } }}
              onClick={handleShowAllPlans}
              className={showAllPlans ? "active" : ""}
              variant="outlined"
              disabled={loading}
              slim
            >
              <img src={loading ? list : greenList} alt="All plans" />
              All plans
            </AllPlansBtn>
          </PlanSelection>
        </HeaderContainer>
      </Header>
      <Body
        sx={{
          display: "flex",
          justifyContent: "spacebetween",
          gap: "15px",
          [theme.breakpoints.down("sm")]: {
            mt: "24px",
          },
        }}
      >
        {error ? (
          <LoadError>
            <img src={errorPopup} alt="Error" />
            <TSTypography sx={{ marginBottom: "8px" }} variant="small">
              Something went wrong
            </TSTypography>
            <TSTypography variant="body">
              We couldn&apos;t load this page for you
            </TSTypography>
            <Button
              className="reload-btn"
              variant="text"
              onClick={() => refetch()}
            >
              <img src={redo} alt="Reload" />
              Try again
            </Button>
          </LoadError>
        ) : (
          <>
            {planRecord && (
              <ResourceDetailDialog
                open={open}
                onClose={handlePDFPreview}
                resource={{ ...planRecord, authorCredentials: "" }}
              />
            )}

            <PlanBody container columnSpacing={1}>
              <Grid item xs={12}>
                {activePlan ? (
                  <h3 className="plan-title">
                    The {getMonthName(activePlan.month)} {activePlan.year} focus
                    is <strong>{activePlan.themeTitle?.toLowerCase()}.</strong>
                  </h3>
                ) : (
                  <Skeleton
                    sx={{
                      backgroundColor: theme.palette.colors.gray50,
                      borderRadius: "5px",
                    }}
                    variant="rounded"
                    height={32}
                    width={450}
                  />
                )}
              </Grid>

              <Grid item xs={12} lg={8}>
                <div className="plan-subtitle-container">
                  <PlanDescription>
                    {activePlan
                      ? activePlan.themeDescription
                      : Array.from(Array(5)).map(() => (
                          <Skeleton
                            key={Math.random()}
                            sx={{
                              bgcolor: theme.palette.colors.gray50,
                            }}
                            height={18}
                            width={350}
                            mb={8}
                          />
                        ))}
                  </PlanDescription>
                  <PlansLexicon
                    records={activePlan?.records?.map(
                      (record) => record.__typename
                    )}
                  />
                </div>
                <Accordion
                  handlePDFPreview={handlePDFPreview}
                  plan={activePlan}
                />
                <TSTypography variant="body" sx={{ mt: theme.spacing(2) }}>
                  Want to see more content? View our{" "}
                  <NavLink to="/resources">Resource Library</NavLink> and{" "}
                  <NavLink to="/classes">Class Schedule</NavLink>.
                  {/* <a href="/resources">Resource Library</a> and{" "}
                  <a href="/classes">Class Schedule</a>. */}
                </TSTypography>
              </Grid>

              <Grid item xs={0} lg={4}>
                <NextStepWidget sx={{ display: { xs: "none", lg: "flex" } }}>
                  <img src={emailPopup} alt="Next Steps" />
                  <TSTypography variant="small" sx={{ mt: theme.spacing(2) }}>
                    Your next step
                  </TSTypography>
                  <TSTypography variant="body" sx={{ mt: theme.spacing(2) }}>
                    View the email template for sharing this plan with your
                    people.
                  </TSTypography>
                  <ShareButton
                    variant="contained"
                    onClick={scrollToShare}
                    sx={{
                      mt: theme.spacing(2),
                      width: "100%",
                      minWidth: "max-content",
                    }}
                    disabled={loading}
                  >
                    Share this plan
                  </ShareButton>
                </NextStepWidget>
              </Grid>
              <SharePlan plan={activePlan} loading={loading}/>
            </PlanBody>

            {plans && activePlan && (
              <AllPlansAside
                activePlan={activePlan}
                handlePlanSelection={handlePlanSelection}
                showAllPlans={showAllPlans}
                setShowAllPlans={setShowAllPlans}
                plans={plans}
              />
            )}
          </>
        )}
      </Body>
    </LoggedInLayout>
  );
}

export default PlansDashboard;

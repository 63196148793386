/** @jsxRuntime classic */
import { useEffect } from "react";
import useAuth from "../hooks/useAuth";
import tracking from "../services/tracking";

function SegmentIdentifier() {
  const { user } = useAuth();

  useEffect(() => {
    if (user && user.profile) {
      const { id, firstName, lastName, email, orgName, orgId } = user.profile;
      tracking.identify(id, { firstName, lastName, email });
      tracking.group(orgId, orgName);
    }
  }, [user]);
  return null;
}

export default SegmentIdentifier;

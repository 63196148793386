import { loader } from "graphql.macro";
import { useQuery } from "@apollo/client";

const getPlans = loader(
  "../gql/plans/queries/getPlans.gql"
);

function usePlans() {
  const { data, error, loading, refetch } = useQuery(getPlans);
  // console.log("usePlans plans:", data?.plans);
  return {
    data,
    loading,
    error,
    refetch,
  };
}

export default usePlans;
/* eslint-disable react/jsx-props-no-spreading */
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import { useContext } from "react";
import { ScreenshotContext } from "../providers/ScreenshotProvider";

const withHideOnScreenshot = (Component) => {
  function HideOnScreenshot({ ...props }) {
    const context = useContext(ScreenshotContext);
    if (context.takingScreenshot) return null;
    return <Component {...props} style={{ visibility: "hidden" }} />;
  }

  HideOnScreenshot.displayName = "HiddenOnScreenshot";

  return HideOnScreenshot;
};

export default withHideOnScreenshot;

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import styled from "@emotion/styled";
import { useApolloClient } from "@apollo/client";
import { Route, Routes, Navigate } from "react-router-dom";

import AuthProvider from "./providers/AuthProvider";
import ScrollToTop from "./Components/ScrollToTop";
import withAuthRequired from "./Components/withAuthRequired";
import withLoggedOutLayout from "./Components/withLoggedOutLayout";
import InsightsDashboard from "./pages/InsightsDashboard";
import Resources from "./pages/Resources";
import Classes from "./pages/Classes";
import Login from "./pages/Login";
import Plans from "./pages/PlansDashboard";
import SegmentIdentifier from "./Components/SegmentIdentifer";
import SigninWithMagicToken from "./pages/SigninWithMagicToken";
import withTrackedPageView from "./Components/withTrackedPageView";

import "./App.css";

const loggedOutRoutes = {
  Login: { component: Login, trackingName: "Login" },
  SigninWithMagicToken: {
    component: SigninWithMagicToken,
    trackingName: "Magic Token Login",
  },
};

Object.keys(loggedOutRoutes).forEach((key) => {
  loggedOutRoutes[key].component = withLoggedOutLayout(
    withTrackedPageView(
      loggedOutRoutes[key].trackingName,
      loggedOutRoutes[key].component
    )
  );
});

const loggedInRoutes = {
  InsightsDashboard: { component: InsightsDashboard, trackingName: "Insights" },
  Resources: { component: Resources, trackingName: "Resources" },
  Classes: { component: Classes, trackingName: "Classes" },
  Plans: { component: Plans, trackingName: "Plans" },
};

Object.keys(loggedInRoutes).forEach((key) => {
  loggedInRoutes[key].component = withAuthRequired(
    withTrackedPageView(
      loggedInRoutes[key].trackingName,
      loggedInRoutes[key].component
    )
  );
});

const StyledApp = styled("div")(() => ({
  height: "100%",
}));

function App() {
  const apolloClient = useApolloClient();

  return (
    <StyledApp className="App">
      <AuthProvider client={apolloClient}>
        <ScrollToTop />
        <SegmentIdentifier />
        <Routes>
          <Route exact path="/" element={<Navigate to="/login" />} />
          <Route
            exact
            id="Login"
            name="Login"
            path="/login"
            element={<loggedOutRoutes.Login.component />}
          />
          <Route
            exact
            id="Login Token"
            name="Login_token"
            path="/login_with_token"
            element={<loggedOutRoutes.SigninWithMagicToken.component />}
          />
          <Route
            exact
            id="File Resources"
            name="resources"
            path="/resources"
            element={<loggedInRoutes.Resources.component />}
          />
          <Route
            exact
            id="Insights Dashboard"
            name="insights"
            path="/insights"
            element={<loggedInRoutes.InsightsDashboard.component />}
          />
          <Route
            exact
            id="Classes"
            name="classes"
            path="/classes"
            element={<loggedInRoutes.Classes.component />}
          />
          <Route
            exact
            id="Plans"
            name="plans"
            path="/plans"
            element={<loggedInRoutes.Plans.component />}
          />
          <Route path="*" element={<Navigate to="/login" />} />
        </Routes>
      </AuthProvider>
    </StyledApp>
  );
}

export default App;

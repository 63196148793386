/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { useLocation, useNavigate } from "react-router-dom";
import { useForm, FormProvider } from "react-hook-form";
import classNames from "classnames";

import { PLANS_PAGE_SLUG } from "../constants";
import Typography from "../Components/Typography";
import Divider from "../Components/Divider";
import TSCard from "../Components/Card";
import TSButton from "../Components/Button";
import TSLink from "../Components/Link";
import FormTextField from "../Components/FormTextField";
import Alert from "../Components/Alert";
import isValidEmail from "../Util/email";
import mq from "../Util/mediaQueries";
import useAuth from "../hooks/useAuth";

const StyledMain = styled("div")(({ theme }) =>
  mq({
    width: "100%",
    ".confirmation-header": {
      marginTop: theme.spacing(4),
    },
    ".send-button": {
      marginTop: 30,
    },
    ".resend-button": {
      marginTop: 37,
    },
    form: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
    },
    ".card": {
      height: 408,
      maxWidth: 565,
      marginTop: [36, null, 51],
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3),
      width: "100%",
      paddingTop: theme.spacing(8),
      paddingBottom: theme.spacing(8),
      paddingLeft: [theme.spacing(3), null, theme.spacing(8)],
      paddingRight: [theme.spacing(3), null, theme.spacing(8)],
      alignSelf: "center",
    },
    ".terms": {
      marginTop: 58,
      textAlign: "center",
      color: theme.palette.colors.gray800,
    },
    ".confirmation": {
      marginTop: theme.spacing(3),
      textAlign: "center",
      maxWidth: 352,
    },
    hr: {
      width: theme.spacing(6),
      marginTop: 45,
      color: theme.palette.colors.gray200,
    },
    ".email-input": {
      marginTop: 46,
    },
    "&.submission-error": {
      ".email-input, .resend-button": {
        marginTop: theme.spacing(3),
      },
      ".card": {
        height: "auto",
      },
    },
    ".alert": {
      marginTop: theme.spacing(3),
    },
    ".email": {
      fontWeight: 700,
    },
  })
);

function Login() {
  const auth = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [requestMagicLink, { loading: requestingMagicLink, error }] =
    auth.requestMagicLink;
  const [getProfile, { loading: loadingProfile, called: getProfileCalled }] =
    auth.getProfile;
  const [email, setEmail] = useState("");
  const [magicLinkSent, setMagicLinkSent] = useState(false);
  const [submissionError, setSubmissionError] = useState(null);
  const [complete, setComplete] = useState(false);

  const methods = useForm({
    mode: "onSubmit",
    reValidateMode: "onChange",
    defaultValues: {
      email: "",
    },
  });

  const {
    handleSubmit,
    getValues,
    reset,
    formState: { isSubmitSuccessful },
  } = methods;

  const onSubmit = (data) => {
    setSubmissionError(false);
    setComplete(false);
    return requestMagicLink({ variables: { email: data.email } });
  };

  const onErrorClose = () => {
    setSubmissionError(false);
  };

  const onCompleteClear = () => {
    setComplete(false);
  };

  useEffect(() => {
    const logout = new URLSearchParams(location.search).get("logout");
    const options = logout ? { fetchPolicy: "cache-only" } : {};
    getProfile(options).then(({ data }) => {
      if (Object.keys(data).length) {
        const desiredPage =
          location.state?.redirect?.pathname || PLANS_PAGE_SLUG;
        navigate(desiredPage, { replace: true });
      } else {
        navigate("/login", { replace: true });
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const values = getValues();
    if (isSubmitSuccessful) {
      reset({ email: values.email });
      if (magicLinkSent) setComplete(true);
      else {
        setEmail(values.email);
        setMagicLinkSent(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitSuccessful]);

  useEffect(() => {
    if (error) {
      setSubmissionError(true);
    }
  }, [error]);

  return (
    <StyledMain
      className={classNames({
        "submission-error": submissionError,
      })}
    >
      <FormProvider methods={methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          {magicLinkSent ? (
            <TSCard className="card">
              <Typography variant="medium" align="center">
                Please check your email
              </Typography>
              <Typography variant="body" className="confirmation">
                We sent an email to <span className="email">{email}</span>.
                Click on the link in the email to sign in.
              </Typography>
              <Divider />
              <Typography variant="heading" className="confirmation-header">
                Didn&apos;t get an email?
              </Typography>
              {submissionError && (
                <Alert
                  className="alert"
                  onClose={onErrorClose}
                  severity="error"
                >
                  There was a problem resending your login link. Please try
                  again.
                </Alert>
              )}
              <TSButton
                className="resend-button"
                type="submit"
                fullWidth
                loading={requestingMagicLink}
                complete={complete}
                onCompleteClear={onCompleteClear}
              >
                Resend Link
              </TSButton>
            </TSCard>
          ) : (
            <TSCard
              className="card"
              loading={loadingProfile || !getProfileCalled}
            >
              <Typography variant="medium" align="center">
                Sign in to your admin portal
              </Typography>
              {submissionError && (
                <Alert
                  className="alert"
                  onClose={onErrorClose}
                  severity="error"
                >
                  There was a problem sending your login link. Please try again.
                </Alert>
              )}
              <FormTextField
                autoFocus
                label="Email"
                name="email"
                className="email-input"
                rules={{
                  required: {
                    value: true,
                    message: "Enter your email address",
                  },
                  validate: { email: isValidEmail },
                }}
              />
              <Typography variant="caption" component="div" className="terms">
                By continuing, you agree to our{" "}
                <TSLink href="https://www.talkspace.com/public/terms">
                  Terms
                </TSLink>{" "}
                and{" "}
                <TSLink href="https://www.talkspace.com/public/privacy-policy">
                  Privacy Policy
                </TSLink>
                .
              </Typography>
              <TSButton
                className="send-button"
                type="submit"
                fullWidth
                loading={requestingMagicLink}
              >
                Continue
              </TSButton>
            </TSCard>
          )}
        </form>
      </FormProvider>
    </StyledMain>
  );
}

export default Login;

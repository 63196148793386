/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from "prop-types";
import Tooltip from "@mui/material/Tooltip";
import { styled, useTheme } from "@mui/material/styles";
import { Skeleton } from "@mui/material";

import { variables } from "../themes";
import mq from "../Util/mediaQueries";

import info from "../assets/info.svg";
import liveClassLg from "../assets/live-class-lg.svg";
import demandClassLg from "../assets/on-demand-class-lg.svg";
import worksheetLg from "../assets/worksheet-lg.svg";
import quickTipsLg from "../assets/quick-tips-lg.svg";

const LexiconContainer = styled("div")(({ theme }) =>
  mq({
    display: "flex",
    gap: "8px",
    [theme.breakpoints.down("xl")]: {
      overflowX: 'auto',
    },
    ".lexicon": {
      flex: "1 1 0",
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      padding: "12px",
      gap: "12px",
      fontSize: "12px",
      lineHeight: "16px",
      fontWeight: 600,
      maxWidth: "109px",
      minWidth: "109px",
      maxHeight: "108px",
      borderRadius: "10px",
      ".lexicon-header": {
        width: "100%",
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "space-between",
      },
    },
    ".lexicon-definition": {},
    ".live-class-lexicon": {
      backgroundColor: theme.colors.cobaltLight,
      color: theme.colors.cobaltDark,
      ".lexicon-title": {
        maxWidth: "min-content",
      },
    },
    ".demand-class-lexicon": {
      backgroundColor: theme.colors.limeLight,
      color: theme.colors.limeDark,
      ".lexicon-title": {
        maxWidth: "fit-content",
      },
    },
    ".worksheet-lexicon": {
      backgroundColor: theme.colors.purpleLight,
      color: theme.colors.purpleDark,
      ".lexicon-title": {
        maxWidth: "fit-content",
      },
    },
    ".tips-lexicon": {
      backgroundColor: theme.colors.fuchsiaLight,
      color: theme.colors.fuchsiaDark,
      ".lexicon-title": {
        maxWidth: "min-content",
      },
    },
  })
);

const LexiconDefinition = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .MuiTooltip-tooltip`]: {
    backgroundColor: "inherit",
    color: "inherit",
    margin: "0 !important",
    padding: "12px",
    fontSize: "12px",
    lineHeight: "16px",
    fontWeight: "400",
    borderRadius: "inherit",
    maxWidth: "109px",
    minWidth: "109px",
  },
}));

export default function Lexicon({ records }) {
  const theme = useTheme();
  return (
    <LexiconContainer>
      {records.length > 0 ? (
        <>
          {records.includes("LiveClass") && (
            <LexiconDefinition
              enterTouchDelay={0}
              sx={{
                backgroundColor: variables.colors.cobaltLight,
                color: variables.colors.cobaltDark,
                top: "-64px !important",
                borderRadius: "10px",
              }}
              title={
                <div className="lexicon-definition">
                  <p>An anonymous therapist-led class hosted on Zoom</p>
                </div>
              }
            >
              <div className="lexicon live-class-lexicon">
                <span className="lexicon-header">
                  <span className="lexicon-title">Live Class</span>
                  <img
                    className="lexicon-info"
                    src={info}
                    alt="Info"
                    style={{
                      filter:
                        "invert(33%) sepia(12%) saturate(4782%) hue-rotate(197deg) brightness(93%) contrast(89%)",
                    }}
                  />
                </span>
                <img src={liveClassLg} alt="Live Class" />
              </div>
            </LexiconDefinition>
          )}{" "}
          {records.includes("OnDemandClass") && (
            <LexiconDefinition
              sx={{
                backgroundColor: variables.colors.limeLight,
                color: variables.colors.limeDark,
                top: "-64px !important",
                borderRadius: "10px",
              }}
              title={
                <div className="lexicon-definition">
                  <p>A pre-recorded therapist-led class</p>
                </div>
              }
            >
              <div className="lexicon demand-class-lexicon">
                <span className="lexicon-header">
                  <span className="lexicon-title">On-Demand Class</span>
                  <img
                    className="lexicon-info"
                    src={info}
                    alt="Info"
                    style={{
                      filter:
                        "invert(21%) sepia(57%) saturate(4129%) hue-rotate(73deg) brightness(99%) contrast(98%)",
                    }}
                  />
                </span>
                <img src={demandClassLg} alt="On-Demand Class" />
              </div>
            </LexiconDefinition>
          )}
          {records.includes("WorksheetResource") && (
            <LexiconDefinition
              sx={{
                backgroundColor: variables.colors.purpleLight,
                color: variables.colors.purpleDark,
                top: "-64px !important",
                borderRadius: "10px",
              }}
              title={
                <div className="lexicon-definition">
                  A PDF guide designed by a therapist for
                  <p>self-exploration</p>
                </div>
              }
            >
              <div className="lexicon worksheet-lexicon">
                <span className="lexicon-header">
                  <span className="lexicon-title">Therapy Worksheet</span>
                  <img
                    className="lexicon-info"
                    src={info}
                    alt="Info"
                    style={{
                      filter:
                        "invert(17%) sepia(90%) saturate(2417%) hue-rotate(269deg) brightness(87%) contrast(85%)",
                    }}
                  />
                </span>
                <img src={worksheetLg} alt="Therapy Worksheet" />
              </div>
            </LexiconDefinition>
          )}
          {records.includes("QuickTipResource") && (
            <LexiconDefinition
              sx={{
                backgroundColor: variables.colors.fuchsiaLight,
                color: variables.colors.fuchsiaDark,
                top: "-64px !important",
                borderRadius: "10px",
              }}
              title={
                <div className="lexicon-definition">
                  <p>
                    A PDF created by a therapist with tips about a specific
                    topic
                  </p>
                </div>
              }
            >
              <div className="lexicon tips-lexicon">
                <span className="lexicon-header">
                  <span className="lexicon-title">Quick Tips</span>
                  <img
                    className="lexicon-info"
                    src={info}
                    alt="Info"
                    style={{
                      filter:
                        "invert(12%) sepia(69%) saturate(3591%) hue-rotate(316deg) brightness(92%) contrast(115%)",
                    }}
                  />
                </span>
                <img src={quickTipsLg} alt="Quick Tips" />
              </div>
            </LexiconDefinition>
          )}
        </>
      ) : (
        Array.from(Array(3)).map(() => (
          <Skeleton
            variant="rounded"
            key={Math.random()}
            sx={{
              bgcolor: theme.palette.colors.gray50,
            }}
            height={108}
            width={116}
          />
        ))
      )}
    </LexiconContainer>
  );
}

Lexicon.propTypes = {
  records: PropTypes.arrayOf(PropTypes.string),
};

Lexicon.defaultProps = {
  records: [],
};

export { Lexicon };
